import React from 'react';
import './styles.css';

interface DashboardTileProps {
  label: string;
  value: string | number;
}

export const DashboardTile: React.FC<DashboardTileProps> = ({
  label,
  value,
}: DashboardTileProps) => {
  return (
    <div className="rounded-4px border-grey-300 p-20px dashboardtile-container relative border border-solid">
      <h6 className="text-2xs font-sans uppercase tracking-wider">{label}</h6>
      <span className="dashboardtile-content absolute inline-block font-serif text-5xl font-semibold">
        {value}
      </span>
    </div>
  );
};
