import { QueryConstraint, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { UserData } from 'utils/firebase/collection/firebase-collection-types';
import { Collection, useCollection } from 'utils/firebase/hooks/useCollection';

export const useGlobalUsers = (): Collection<UserData> | undefined => {
  const isGlobalUser = useMemo<QueryConstraint[]>(() => {
    return [where('global_user.role', '!=', null)];
  }, []);

  const collection = useCollection('users', isGlobalUser);

  return collection;
};
