import React from 'react';
import { LoadingBounce } from './LoadingBounce/LoadingBounce';
import { LoadingDots } from './LoadingDots/LoadingDots';

export const enum LoadingType {
  BOUNCE = 'BOUNCE',
  DOTS = 'DOTS',
}

const getLoadingType = (loadingType: LoadingType = LoadingType.BOUNCE) =>
  ({
    [LoadingType.BOUNCE]: LoadingBounce,
    [LoadingType.DOTS]: LoadingDots,
  }[loadingType]);

type LoadingWrapperProps = {
  loading: boolean;
  loadingType?: LoadingType;
  className?: string;
  children?: React.ReactNode;
};

export const Loading: React.FC<LoadingWrapperProps> = ({
  loading,
  loadingType,
  children,
  ...props
}) => {
  const Animation = getLoadingType(loadingType);
  return (
    <>
      {loading ? (
        <div data-testid="loading-animation" {...props}>
          <Animation />
        </div>
      ) : (
        children
      )}
    </>
  );
};
