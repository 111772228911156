import { RadioButton } from 'components/RadioButton/RadioButton';
import { ChangeEvent } from 'react';

export type RadioButtonGroupArrangement = 'inline' | 'row' | 'column';

export type RadioButtonGroupProps = {
  items: {
    label: string;
    value: string | number;
  }[];
  groupName: string;
  arrangement?: RadioButtonGroupArrangement;
  hasBorder?: boolean;
  doOptionsFillSpace?: boolean;
  onChange: (newOpt: string | number) => void;
};

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  items,
  groupName,
  arrangement = 'row',
  hasBorder,
  doOptionsFillSpace,
  onChange,
}) => {
  const arrangementClasses = (() => {
    switch (arrangement) {
      case 'inline':
        return 'flex-wrap space-x-3 space-y-3';
      case 'row':
        return 'flex flex-row space-x-3';
      case 'column':
        return 'flex flex-col space-y-3';
    }
  })();

  const onRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <fieldset className={arrangementClasses}>
      {items.map(({ label, value }) => (
        <RadioButton
          key={`radio-buttons-${groupName}-${value}`}
          label={label}
          value={value}
          hasBorder={hasBorder}
          groupName={groupName}
          isFullWidth={doOptionsFillSpace}
          onChange={onRadioChange}
        />
      ))}
    </fieldset>
  );
};
