import { routes } from 'controllers/ContentController/Routes';
import { useCurrentPrincipal, useCurrentUser } from 'hooks/queries';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Permission } from 'shared/types/authorization';
import { ExpansionOpportunitiesWithMap } from '../../components/ExpansionOpportunitiesWithMap';
import { Heading } from '../../components/Heading';
import { PermissionDenied } from '../../components/PermissionDenied/PermissionDenied';
import { Tab, Tabs } from '../../components/Tabs';
import { usePermission } from '../../hooks/usePermission';
import { MarketInsightsTab } from './MarketInsightsTab';
import { SocialListeningReportTab } from './SocialListeningReportTab';

export enum MarketIntelligenceTabSlugs {
  MARKET_INSIGHTS = 'market-insights',
  EXPANSION_OPPORTUNITIES = 'expansion-opportunities',
  SOCIAL_LISTENING_REPORT = 'social-listening-report',
}

export const MarketIntelligence: React.FC = () => {
  const { tab: tabFromURL } = useParams<{ tab: string | undefined }>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { userHasPermission } = usePermission();

  const [selectedTab, setSelectedTab] = useState(tabFromURL || '');

  const { user } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(user.id);

  const userHasMarketInsightsViewPermission = userHasPermission(
    Permission.MARKET_INSIGHTS_VIEW,
  );

  const userHasExpansionOpportunitiesViewPermission = userHasPermission(
    Permission.EXPANSION_OPPORTUNITIES_VIEW,
  );

  const portalHasSocialListeningReportViewEnabled =
    currentPrincipal.settings.social_listening_report_view;

  useEffect(() => {
    if (selectedTab) return;
    if (userHasMarketInsightsViewPermission) {
      setSelectedTab(MarketIntelligenceTabSlugs.MARKET_INSIGHTS);
    } else if (userHasExpansionOpportunitiesViewPermission) {
      setSelectedTab(MarketIntelligenceTabSlugs.EXPANSION_OPPORTUNITIES);
    }
  }, [
    selectedTab,
    userHasMarketInsightsViewPermission,
    userHasExpansionOpportunitiesViewPermission,
  ]);

  const userCanViewAtLeastOneTab =
    userHasMarketInsightsViewPermission ||
    userHasExpansionOpportunitiesViewPermission;

  useEffect(() => {
    const { marketIntelligenceRoute } = routes(currentPrincipal.slug);
    navigate(`${marketIntelligenceRoute.url}/${selectedTab}`);
    // Because when we reach /market-intelligence, there should always be a tab active
    // and the URL should reflect that.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <React.Fragment>
      <div className="w-full">
        {userCanViewAtLeastOneTab ? (
          // Currently all users are able to see at least one tab, but we'll include this check for the sake of future-proofing
          <div className="w-full px-4 md:px-6">
            <div className="max-w-xl-content mx-auto">
              <div
                className="mb-7 pt-6 md:mb-9 md:pt-7"
                data-test="page-header"
              >
                <div className="flex flex-col items-baseline justify-between md:flex-row md:space-x-2">
                  <Heading
                    text={`${t('features:market-intelligence:heading')}`}
                    margin={0}
                    level="h1"
                  />
                </div>
              </div>
              <Tabs activeTab={selectedTab} onTabChange={setSelectedTab}>
                {[
                  ...(function* () {
                    if (
                      userHasMarketInsightsViewPermission &&
                      currentPrincipal.id
                    ) {
                      yield (
                        <Tab
                          key={MarketIntelligenceTabSlugs.MARKET_INSIGHTS}
                          tabKey={MarketIntelligenceTabSlugs.MARKET_INSIGHTS}
                          title={t(
                            'features:market-intelligence:marketInsights:tab',
                          )}
                          heading={t(
                            'features:market-intelligence:marketInsights:headline',
                            {
                              name: currentPrincipal.name,
                            },
                          )}
                        >
                          <MarketInsightsTab
                            selectedPrincipal={currentPrincipal.principalPri}
                          />
                        </Tab>
                      );
                    }

                    if (userHasExpansionOpportunitiesViewPermission) {
                      yield (
                        <Tab
                          key={
                            MarketIntelligenceTabSlugs.EXPANSION_OPPORTUNITIES
                          }
                          tabKey={
                            MarketIntelligenceTabSlugs.EXPANSION_OPPORTUNITIES
                          }
                          title={`${t(
                            'features:market-intelligence:expansionOpportunities:tab',
                          )}`}
                          heading={t(
                            'features:market-intelligence:expansionOpportunities:headline',
                          )}
                        >
                          <div className="my-4 md:mb-6">
                            <ExpansionOpportunitiesWithMap />
                          </div>
                        </Tab>
                      );
                    }

                    if (portalHasSocialListeningReportViewEnabled) {
                      yield (
                        <Tab
                          key={
                            MarketIntelligenceTabSlugs.SOCIAL_LISTENING_REPORT
                          }
                          tabKey={
                            MarketIntelligenceTabSlugs.SOCIAL_LISTENING_REPORT
                          }
                          title={`${t(
                            'features:market-intelligence:socialListeningReport:tab',
                          )}`}
                        >
                          <div className="my-4 md:mb-6">
                            <SocialListeningReportTab
                              currentPrincipalId={currentPrincipal.id}
                            />
                          </div>
                        </Tab>
                      );
                    }
                  })(),
                ]}
              </Tabs>
            </div>
          </div>
        ) : (
          <PermissionDenied />
        )}
      </div>
    </React.Fragment>
  );
};
