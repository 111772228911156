import 'flag-icons/css/flag-icons.min.css';
import './i18n';
import './index.css';

import * as pbi from 'powerbi-client';
import * as serviceWorker from './serviceWorker';

import {
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store/store';
import App from './App';
import { MSAL_CONFIG } from './shared/authentication-config';

declare global {
  interface Window {
    dataLayer: Array<unknown>;
  }
}

window.dataLayer = window.dataLayer || [];

const tagManagerArgs = {
  gtmId: 'GTM-5N42VRG',
};
TagManager.initialize(tagManagerArgs);

export const msalInstance = new PublicClientApplication(MSAL_CONFIG);

const powerbi = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory,
);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback(({ payload, eventType }: EventMessage) => {
  if (
    eventType === EventType.LOGIN_SUCCESS &&
    payload &&
    'account' in payload &&
    payload?.account
  ) {
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <div className="flex min-h-screen">
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </div>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
);

powerbi.preload({
  type: 'report',
  embedUrl: 'https://app.powerbi.com/reportEmbed',
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
