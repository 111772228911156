export enum MapName {
  WORLD = 'WORLD',
  USA = 'USA',
  EUROPE = 'EUROPE',
  LATINAMERICA = 'LATINAMERICA',
  CANADA = 'CANADA',
  AFRICA = 'AFRICA',
  ASIA = 'ASIA',
  MIDDLEEAST = 'MIDDLEEAST',
  OCEANIA = 'OCEANIA',
}

type TMapConfigs = {
  [key in MapName]: {
    mapDataFilename: string;
    initialPosition: {
      coordinates: [number, number];
      zoom: number;
    };
  };
};

export const MapConfigs: TMapConfigs = {
  [MapName.WORLD]: {
    mapDataFilename: 'world-110m',
    initialPosition: {
      coordinates: [0, 45],
      zoom: 0.8,
    },
  },

  [MapName.USA]: {
    mapDataFilename: 'us-states',
    initialPosition: { zoom: 4.0, coordinates: [-95, 35] },
  },
  [MapName.CANADA]: {
    mapDataFilename: 'world-110m',
    initialPosition: {
      zoom: 1.5976019121981817,
      coordinates: [-96.09681228112406, 72.00690980438155],
    },
  },
  [MapName.EUROPE]: {
    mapDataFilename: 'world-110m',
    initialPosition: {
      zoom: 2.814663589502009,
      coordinates: [8.293531102018484, 55.23613830114797],
    },
  },
  [MapName.LATINAMERICA]: {
    mapDataFilename: 'world-110m',
    initialPosition: {
      zoom: 1.9099498351537958,
      coordinates: [-72.19392770885004, -16.217107872205617],
    },
  },
  [MapName.AFRICA]: {
    mapDataFilename: 'world-110m',
    initialPosition: {
      zoom: 2.3310218063521497,
      coordinates: [18.30358962333486, -1.2245731079952928],
    },
  },
  [MapName.ASIA]: {
    mapDataFilename: 'world-110m',
    initialPosition: {
      zoom: 1.9655901057875411,
      coordinates: [92.85401827987994, 31.227266467220986],
    },
  },
  [MapName.MIDDLEEAST]: {
    mapDataFilename: 'world-110m',
    initialPosition: {
      zoom: 4.91422018715895,
      coordinates: [46.19735780008609, 27.914713191885387],
    },
  },
  [MapName.OCEANIA]: {
    mapDataFilename: 'world-110m',
    initialPosition: {
      zoom: 3.098424354166278,
      coordinates: [136.8009067339363, -24.64421776362159],
    },
  },
};
