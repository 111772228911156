import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useState } from 'react';

export type SearchInputWithButtonProps = {
  placeholderInput: string;
  labelButton: string;
  minSearchTermLength?: number;
  initialValue?: string;
  onButtonClick: (newSearchTerm: string) => void;
};

export const SearchInputWithButton: React.FC<SearchInputWithButtonProps> = ({
  placeholderInput,
  labelButton,
  initialValue,
  minSearchTermLength,
  onButtonClick,
}) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(
    initialValue,
  );

  return (
    <div className="flex space-x-1">
      <div className="flex-1">
        <Input
          placeholder={placeholderInput}
          initialValue={initialValue}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Button
        label={labelButton}
        onClick={(e) => {
          e.preventDefault();
          searchTerm && onButtonClick(searchTerm);
        }}
        disabled={
          !searchTerm ||
          (!!minSearchTermLength &&
            searchTerm.trim().length < minSearchTermLength)
        }
      />
    </div>
  );
};
