import { Reducer } from '@reduxjs/toolkit';
import { UserAction } from './user.action';
import { UserActionType, UserState } from './user.types';

const INITIAL_STATE: UserState = {
  auth: null,
  data: undefined,
  global: undefined,
};

export const userReducer: Reducer<UserState, UserAction> = (
  state = INITIAL_STATE,
  action,
): UserState => {
  const { type, payload } = action;

  switch (type) {
    case UserActionType.SET_AUTH:
      return {
        ...state,
        auth: payload,
      };
    case UserActionType.SET_USER_DATA:
      return {
        ...state,
        data: payload,
      };
    case UserActionType.SET_GLOBAL_USER:
      return {
        ...state,
        global: payload || null,
      };
    default:
      return state;
  }
};
