import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import {
  getPrincipalFromSlug,
  useCurrentPrincipal,
  useCurrentUser,
  usePrincipals,
} from 'hooks/queries';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { tracking } from '../../lib/tracking';
import { Icon } from '../Icon';
import { Modal } from '../Modal';

export const PrincipalSelectionBar: React.FC = () => {
  const [isModalHidden, setIsModalHidden] = useState<boolean>(true);
  const [
    selectedPrincipalDropdownSelection,
    setSelectedPrincipalDropdownSelection,
  ] = useState<string>();
  const { user, isGlobalUser } = useCurrentUser();

  const { principals } = usePrincipals();
  const currentRoute = useLocation().pathname;

  const { t } = useTranslation();
  const { currentPrincipal, setSelectedPrincipalIdWithRedirect } =
    useCurrentPrincipal(user.id);

  useEffect(() => {
    if (currentPrincipal) {
      setSelectedPrincipalDropdownSelection(currentPrincipal.slug);
    }
  }, [currentPrincipal, setSelectedPrincipalDropdownSelection]);

  const initialPrincipalSelection = useMemo(() => {
    return {
      name: currentPrincipal.name,
      value: currentPrincipal.slug,
    } as DropdownOptionProps;
  }, [currentPrincipal]);

  const showModal = () => setIsModalHidden(false);
  const { role } = useCurrentUser();

  const onModalConfirmHandler = () => {
    if (selectedPrincipalDropdownSelection) {
      const selectedPrincipal = getPrincipalFromSlug(
        selectedPrincipalDropdownSelection,
        principals,
      );

      tracking.push('user', 'User Switch Principal', {
        loginStatus: 'logged in',
        type: role,
        principalID: selectedPrincipal?.id,
      });

      setSelectedPrincipalIdWithRedirect(
        currentPrincipal.slug,
        selectedPrincipalDropdownSelection,
      );
    }
  };

  const onPrincipalChangeHandler = useCallback(
    (selectedOption: DropdownOptionProps | undefined) => {
      const principalId = (selectedOption as DropdownOptionProps)
        ?.value as string;
      if (principalId) setSelectedPrincipalDropdownSelection(principalId);
    },
    [setSelectedPrincipalDropdownSelection],
  );

  const dropdownOptions = useMemo(() => {
    return isGlobalUser && principals
      ? Object.keys(principals)
      : Object.keys(user.roles);
  }, [isGlobalUser, user, principals]);

  const onModalCancelClickHandler = useCallback(() => {
    setIsModalHidden(true);
  }, []);

  return (
    <>
      {Object.keys(user.roles) &&
        principals &&
        Object.keys(principals).length > 0 && (
          <>
            <div className="z-10 flex w-full items-center justify-between bg-blue-400 py-2 pl-5 pr-3 text-white md:px-6">
              {currentRoute === '/global-users' ? (
                <div className="text my-1">{`${t(
                  'components:principalSelectionBar:allPrincipals',
                )}`}</div>
              ) : (
                <div className="flex items-center text-xs">
                  <div className="mr-1 h-6 w-6">
                    {currentPrincipal.logoUrl ? (
                      <img
                        src={currentPrincipal.logoUrl}
                        className="rounded bg-white"
                        alt={`${currentPrincipal.name} - Logo`}
                      />
                    ) : (
                      <Icon name="AccountBehalf" inline={true} />
                    )}
                  </div>
                  <div data-testid="customer-info">
                    <span className="mr-1 inline">
                      {`${t('components:principalSelectionBar:onBehalf')}`}{' '}
                      {currentPrincipal.name}
                    </span>
                  </div>
                </div>
              )}
              {(Object.keys(user.roles).length > 1 || isGlobalUser) &&
                currentRoute !== '/global-users' && (
                  <div
                    className="ml-4 flex shrink-0 cursor-pointer items-baseline text-xs"
                    onClick={showModal}
                    data-testid="customer-change"
                  >
                    <span className="mr-1 w-4">
                      <Icon name="Edit" inline={true}></Icon>
                    </span>
                    <span>{t('components:principalSelectionBar:change')} </span>
                  </div>
                )}
            </div>

            <Modal
              icon="AccountBehalf"
              heading={t('modals:changeCustomerSelection:prompt')}
              isHidden={isModalHidden}
              confirmText={t('modals:changeCustomerSelection:confirm')}
              onConfirm={onModalConfirmHandler}
              onCancel={onModalCancelClickHandler}
            >
              <DropdownSingleSelect
                height="50vh--90"
                initialSelection={initialPrincipalSelection}
                options={dropdownOptions
                  .map((principal: string): DropdownOptionProps => {
                    return {
                      name: principals[principal]?.name || '',
                      value: principals[principal]?.slug,
                    };
                  })
                  .sort((a, b) => a.name.localeCompare(b.name))}
                onChange={onPrincipalChangeHandler}
                widthVariant="full-width"
              />
            </Modal>
          </>
        )}
    </>
  );
};
