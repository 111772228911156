import classNames from 'classnames';
import { Icon } from 'components/Icon';
import { useOnClickOutside } from 'hooks/onClickOutside';
import { useCRMMandates, useMarketSegmentDefinitions } from 'hooks/queries';
import { CRMCountryData } from 'hooks/queries/useCRMCountriesData';
import { ExpansionOpportunity } from 'hooks/queries/useExpansionOpportunities';
import React, { useEffect, useRef } from 'react';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';
import { Expansion } from './Expansion';
import { Intro } from './Intro';
import { Mandate } from './Mandate';
import { Marketing } from './Marketing';
import './styles.css';

export interface CountryModalProps {
  selectedPrincipal: Principal;
  crmCountriesData: CRMCountryData[];
  countryISO3Ref: string;
  isHidden: boolean;
  expansionOpportunitiesPerCountry: ExpansionOpportunity[];
  onCancel?: () => void;
}

export const CountryModal: React.FC<CountryModalProps> = ({
  selectedPrincipal,
  crmCountriesData,
  expansionOpportunitiesPerCountry,
  countryISO3Ref,
  isHidden,
  onCancel,
}: CountryModalProps) => {
  const { data: mandatesPerCountry, isLoading: isLoadingMandatesPerCountry } =
    useCRMMandates({
      principalPri: selectedPrincipal.id,
      filter: { iso: [countryISO3Ref] },
    });

  const countryModalRef = useRef(null);

  useOnClickOutside(countryModalRef, () => {
    onCancel?.();
  });

  const selectedCountryData = crmCountriesData.find(
    (i) => i.country.ISO3 === countryISO3Ref,
  );

  // If country is served by another one use the staff count from the serving country
  const servedByCountry = crmCountriesData.find(
    (i) => i.country.ISO3 === selectedCountryData?.servedBy?.aze_isocode,
  );
  const staffCountServedByCountry = servedByCountry?.staffCount;

  const marketSegmentsServedByCountry = servedByCountry?.marketSegments;

  const selctedCountryMarketSegments = selectedCountryData?.marketSegments;
  const marketSegmentsForLabCalculation =
    marketSegmentsServedByCountry || selctedCountryMarketSegments;

  const marketSegmentsWithLabs = marketSegmentsForLabCalculation?.filter(
    (segment) => segment.lab,
  );

  const marketSegmentDefinitions = useMarketSegmentDefinitions();

  const countryModalScrollArea = useRef(null);
  useEffect(() => {
    if (countryModalScrollArea.current && selectedCountryData) {
      // Convert to unknown first to appease Typescript
      (countryModalScrollArea.current as unknown as HTMLElement).scrollTo({
        top: 0,
      });
    }
  }, [selectedCountryData]);

  return (
    <div
      data-testid="country-modal-outer"
      ref={countryModalScrollArea}
      className={classNames(
        `z-modal fixed inset-0 flex items-center justify-end overflow-auto`,
        { 'fade-in-background': !isHidden },
        { 'fade-out-background': isHidden },
      )}
    >
      <div
        data-testid="country-modal"
        className={classNames(
          `w-560px relative max-h-full max-w-full`,
          {
            'open-country-anime': !isHidden,
          },
          {
            'close-country-anime': isHidden,
          },
        )}
        ref={countryModalRef}
      >
        {/* This^ div is necessary for the modal to scroll correctly */}
        <div className="bg-grey-100 text-grey-700 relative items-center">
          <button
            data-testid="country-modal-cancel"
            className="text-grey-600 absolute right-0 top-4 mr-4 mt-4 h-3 w-3 self-end hover:text-blue-100 focus:text-blue-100"
            onClick={onCancel}
          >
            <Icon name="Close" />
          </button>

          <div className="flex flex-col p-6">
            <div className="min-h-screen items-center">
              {selectedCountryData ? (
                <>
                  <Intro
                    iso3={selectedCountryData.country.ISO3}
                    name={selectedCountryData.country.name}
                    region={selectedCountryData.region}
                    cluster={selectedCountryData.cluster}
                    about={selectedCountryData.intro}
                    managingDirector={selectedCountryData.managingDirector}
                    servedBy={selectedCountryData?.servedBy}
                    extraMessage={selectedCountryData?.extraMessage}
                  />
                  {!!expansionOpportunitiesPerCountry.length && (
                    <Expansion
                      expansionOpportunities={expansionOpportunitiesPerCountry}
                      hasMandates={!!mandatesPerCountry?.count.mandates}
                    />
                  )}
                  {!isLoadingMandatesPerCountry && !!mandatesPerCountry && (
                    <Mandate
                      mandates={mandatesPerCountry}
                      marketSegmentDefinitions={marketSegmentDefinitions}
                      selectedPrincipal={selectedPrincipal}
                      selectedCountry={selectedCountryData.country.name}
                    />
                  )}
                  {!!(
                    selectedCountryData.offices.length ||
                    selectedCountryData.marketSegments.length
                  ) && (
                    <Marketing
                      marketSegmentDefinitions={marketSegmentDefinitions}
                      staffCount={
                        selectedCountryData.staffCount ||
                        staffCountServedByCountry
                      }
                      offices={selectedCountryData.offices}
                      marketSegments={selectedCountryData.marketSegments}
                      countryName={selectedCountryData.country.name}
                      marketSegmentsWithLabs={marketSegmentsWithLabs}
                    />
                  )}
                </>
              ) : (
                <span>No country data</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
