import { LoadingBounce } from 'components/Loading';
import { Modal } from 'components/Modal';
import React from 'react';

type SaveUserModalProps = {
  topMessage: string;
  bottomMessage: string;
};

export const SavingModal: React.FC<SaveUserModalProps> = ({
  topMessage,
  bottomMessage,
}) => {
  return (
    <Modal hideAllButtons={true} isCancelButtonHidden={true} isCloseIconHidden>
      <div className="mt-2 flex items-center justify-center">
        <LoadingBounce />
      </div>
      <div className="text-grey-700 mt-4 items-center text-center text-xs font-normal">
        <h5>{topMessage}</h5>
        <h5>{bottomMessage}</h5>
      </div>
    </Modal>
  );
};
