import './LoadingBounce.css';

import React from 'react';

export type LoadingProps = { text?: string; small?: boolean };

export const LoadingBounce: React.FC<LoadingProps> = ({
  text,
  small,
}: LoadingProps) => {
  return (
    <div
      className="flex h-full flex-1 flex-col items-center justify-center"
      data-testid="loading-component"
    >
      <div className={`loading ${small ? 'h-4 w-4' : ''}`}>
        <div className="loading-bounce1 bg-blue-200/50"></div>
        <div className="loading-bounce2 bg-blue-200/50"></div>
      </div>
      {text ? (
        <div className="mt-1 whitespace-nowrap font-semibold text-blue-200">
          {text}
        </div>
      ) : null}
    </div>
  );
};
