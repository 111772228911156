import { Icon } from 'components/Icon';
import { Link } from 'components/Link';
import React from 'react';
import { useTranslation } from 'react-i18next';

type EmptyConfigProps = {
  onLinkClick: () => void;
};

export const EmptyConfig: React.FC<EmptyConfigProps> = ({
  onLinkClick,
}: EmptyConfigProps) => {
  const { t } = useTranslation();
  return (
    <div className="border-grey-300 border p-6 text-center">
      <div>
        <Icon name="EmptyMarket" />
      </div>
      <div className="my-3">{t('features:digital-activities:emptyMarket')}</div>
      <div className="flex justify-center">
        <Link
          label={t('features:digital-activities:selectMarketNow')}
          onClick={onLinkClick}
        />
      </div>
    </div>
  );
};
