import {
  Configuration,
  LogLevel,
  PopupRequest,
  SilentRequest,
} from '@azure/msal-browser';

const AzureActiveDirectoryAppClientId: string = process.env
  .REACT_APP_AD_CLIENT_ID as string;

export const MSAL_CONFIG: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AD_TENANT_ID}/`,
    clientId: AzureActiveDirectoryAppClientId,
    redirectUri: `${window.location.origin}/oauth-handler`,
    postLogoutRedirectUri: `${window.location.origin}/`,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ): void => {
        if (containsPii || process.env.REACT_APP_ENVIRONMENT === 'production') {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest: PopupRequest = {
  scopes: [
    'https://graph.microsoft.com/User.Read',
    'https://graph.microsoft.com/Files.Read',
    'https://graph.microsoft.com/Files.Read.All',
    'https://graph.microsoft.com/Sites.Read.All',
  ],
};

export const biSilentRequest: SilentRequest = {
  scopes: [
    'https://analysis.windows.net/powerbi/api/Report.Read.All',
    'https://analysis.windows.net/powerbi/api/Dataset.Read.All',
    'https://analysis.windows.net/powerbi/api/Workspace.Read.All',
    'https://analysis.windows.net/powerbi/api/App.Read.All',
    'https://analysis.windows.net/powerbi/api/Dashboard.Read.All',
  ],
};

export const sharepointSilentRequest: SilentRequest = {
  scopes: [
    'https://graph.microsoft.com/User.Read',
    'https://graph.microsoft.com/Files.Read',
    'https://graph.microsoft.com/Files.Read.All',
    'https://graph.microsoft.com/Sites.Read.All',
  ],
};
