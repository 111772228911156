import classNames from 'classnames';
import React, { useCallback } from 'react';

export type TabTitleProps = {
  title: string;
  tabKey: string;
  selectedTab: string;
  setSelectedTab: (index: string) => void;
  isDisabled?: boolean;
};

export const TabTitle: React.FC<TabTitleProps> = ({
  title,
  setSelectedTab,
  tabKey,
  selectedTab,
  isDisabled,
}: TabTitleProps) => {
  const onClick = useCallback(() => {
    setSelectedTab(tabKey);
  }, [setSelectedTab, tabKey]);

  return (
    <button
      className={classNames(
        'px-4 py-2 font-sans font-semibold uppercase focus:text-blue-100 focus:outline-none',
        {
          'text-grey-200 border-grey-500 border-b':
            selectedTab !== tabKey && isDisabled,
          'text-grey-700 border-grey-500 border-b hover:text-blue-100':
            selectedTab !== tabKey && !isDisabled,
          'border-b-4 border-blue-100 text-blue-100': selectedTab === tabKey,
        },
      )}
      onClick={onClick}
      disabled={isDisabled}
    >
      {title}
    </button>
  );
};
