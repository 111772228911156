import { SharePointUpdateResult } from 'lib/sharepoint';
import { Action, ActionWithPayload, createAction } from 'utils/reducer.utils';
import { SharepointActionType } from './sharepoint.types';

export type SharepointAction = SetSharepointStateAction | ResetAllAction;

type ResetAllAction = Action<SharepointActionType.RESET_ALL>;

type SetSharepointStateAction = ActionWithPayload<
  SharepointActionType.SET_SHAREPOINT_STATE_ACTION,
  SharePointUpdateResult
>;

export const resetAll = (): ResetAllAction =>
  createAction(SharepointActionType.RESET_ALL);

export const setSharepointState = (
  payload: SharePointUpdateResult,
): SetSharepointStateAction =>
  createAction(SharepointActionType.SET_SHAREPOINT_STATE_ACTION, payload);
