import React from 'react';
import { useTranslation } from 'react-i18next';

export const PermissionDenied: React.FC = () => {
  const { t } = useTranslation();
  return (
    <span className="text-grey-700 flex h-full w-full items-center justify-center">
      {t('components:permissionDenied')}
    </span>
  );
};
