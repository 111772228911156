import { Role, UserType } from 'shared/types/authorization';

export const userTypeIsInAllowedList = (
  userType: UserType,
  allowedList: UserType[],
): boolean => allowedList.includes(userType);

export const userRoleIsInRoleAllowedList = (
  userRole: Role,
  allowedList: Role[],
): boolean => allowedList.includes(userRole);

export const allowedRolesForUserRole = ({
  role,
}: {
  role: Role;
}): Role[] | undefined => {
  switch (role) {
    case Role.GLOBAL_USER_ADMIN:
    case Role.GLOBAL_USER_EDIT:
    case Role.PDM:
      return [
        Role.AZELIS_VISITOR,
        Role.DISTRIBUTION_MANAGER,
        Role.GLOBAL_USER_ADMIN,
        Role.GLOBAL_USER_EDIT,
        Role.GLOBAL_USER_VIEW,
        Role.PDM,
        Role.PRINCIPAL_ADMIN,
        Role.PRINCIPAL_DEVELOPER,
      ];
    case Role.PRINCIPAL_ADMIN:
      return [Role.DISTRIBUTION_MANAGER, Role.PRINCIPAL_DEVELOPER];
    default:
      return undefined;
  }
};

export const getUserTypeByEmail = (
  email: string | undefined,
): UserType | undefined => {
  if (!email) return;

  if (email.toLowerCase().includes('@azelis')) {
    return UserType.INTERNAL;
  }

  return UserType.EXTERNAL;
};

export const isInternalUser = (userType: UserType): boolean =>
  userType === UserType.INTERNAL || userType === UserType.GLOBAL;
