import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { List } from 'features/CookieStatement/List';
import { useRoleDefinitions } from 'hooks/queries';
import { allowedRolesForUserRole } from 'lib/rolesAndPermissions';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Role, UserType } from 'shared/types/authorization';
import { RoleDefinition } from 'utils/firebase/collection';

export type DefaultRoleFilter = ({
  value,
}: {
  value: Role;
}) => boolean | undefined;

export type RoleSelectorFilter = (
  value: [string, RoleDefinition],
  index?: number,
  array?: (typeof value)[],
) => boolean;

interface RoleSelectorProps {
  userType: UserType | undefined;
  selected?: Role;
  onChange?: (selectedOption: string | number | undefined) => void;
  filter?: RoleSelectorFilter;
  disabled?: boolean;
  userRole: Role;
}

export const RoleSelector: React.FC<RoleSelectorProps> = ({
  disabled = false,
  filter,
  onChange,
  selected,
  userRole,
  userType,
}) => {
  const [selectedRole, setSelectedRole] = useState<DropdownOptionProps>();
  const { t } = useTranslation();
  const roleDefinitions = useRoleDefinitions();

  const defaultTypeFilter: RoleSelectorFilter = ([, value]) => {
    return !!userType && userType === value.user_type;
  };

  const roleOptions = Object.entries(roleDefinitions)
    .filter(filter ?? defaultTypeFilter)
    // filtering out principal developer role untill the role has permissions
    .filter(([key]) => key !== Role.PRINCIPAL_DEVELOPER)
    .map(([key]) => {
      return {
        name: t(`features:portal-members:labels:roles:${key}`),
        value: key,
        active: selected ? key === selected : false,
      };
    });

  const filteredRoleOptions = roleOptions.filter(
    ({ value }: { value: string }) =>
      allowedRolesForUserRole({ role: userRole })?.includes(value as Role),
  );

  const renderText = (role: string) => {
    const roleDefs = roleDefinitions[role as Role];
    if (!roleDefs || !selectedRole) return;
    const content = roleDefs.permissions;
    return Object.entries(content).map(([key, value]) => {
      return (
        value &&
        key !== 'id' &&
        key !== 'exists' && (
          <li className="ml-1" key={key}>
            {t(`components:roleSelector:permissions:${key}`)}
          </li>
        )
      );
    });
  };

  const onChangeHandler = (option: DropdownOptionProps | undefined) => {
    if (selectedRole?.value !== option?.value) {
      setSelectedRole(option);
      onChange && onChange(option?.value);
    }
  };

  return (
    <div>
      <DropdownSingleSelect
        options={filteredRoleOptions}
        disabled={filteredRoleOptions.length === 0 || disabled}
        placeholder="Select"
        onChange={(option) =>
          onChangeHandler(option as DropdownOptionProps | undefined)
        }
        initialSelection={selectedRole}
      />
      {selectedRole && (
        <div className="my-2 block">
          {t('components:roleSelector:roleHasPermissions', {
            role: t(
              `features:portal-members:labels:roles:${selectedRole.value}`,
            ),
          })}
        </div>
      )}
      <List unordered>
        {selectedRole && renderText(selectedRole.value as string)}
      </List>
    </div>
  );
};
