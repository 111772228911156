import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DropdownOptionProps } from './DropdownTypes';
import {
  displayAsIntermediate,
  getAllChildren,
  isOptionActive,
  removeSelection,
} from './helper';

export type MultiSelectActiveOptionHookResult = {
  activeOptionState: DropdownOptionProps[];
  setActiveOptionState: Dispatch<SetStateAction<DropdownOptionProps[]>>;
  onClickHandler: (option: DropdownOptionProps) => void;
  onParentClickHandler: (option: DropdownOptionProps, active: boolean) => void;
  selectAllHandler: () => void;
  deselectAllHandler: () => void;
  customSelectionHandler: (options: DropdownOptionProps[]) => void;
};

export const useMultiSelectActiveOption = (
  options: DropdownOptionProps[],
  onChange?: (option: DropdownOptionProps[]) => void,
  initialSelection?: DropdownOptionProps[],
): MultiSelectActiveOptionHookResult => {
  const activeOptions =
    (options.filter((option) => option.active) && initialSelection) || [];

  const [activeOptionState, setActiveOptionState] =
    useState<DropdownOptionProps[]>(activeOptions);

  useEffect(() => {
    if (initialSelection) {
      setActiveOptionState(initialSelection);
    }
  }, [initialSelection]);

  const onClickHandler = (option: DropdownOptionProps) => {
    let calcState;

    if (isOptionActive(option.value, activeOptionState)) {
      calcState = removeSelection(option, activeOptionState);
    } else {
      calcState = [...activeOptionState, option];
    }

    setActiveOptionState(calcState);
    onChange?.(calcState);
  };

  const onParentClickHandler = (
    option: DropdownOptionProps,
    active: boolean,
  ) => {
    const allChildren = option.childOptions
      ? getAllChildren(option.childOptions)
      : [];

    let calcState;

    if (active || displayAsIntermediate(option, activeOptionState)) {
      calcState = removeSelection(allChildren, activeOptionState);
    } else {
      calcState = [...activeOptionState, ...allChildren];
    }

    setActiveOptionState(calcState);
    onChange?.(calcState);
  };

  const selectAllHandler = () => {
    const allChildOptions = getAllChildren(options);

    setActiveOptionState(allChildOptions);
    onChange?.(allChildOptions);
  };

  const deselectAllHandler = () => {
    setActiveOptionState([]);
    onChange?.([]);
  };

  const customSelectionHandler = (options: DropdownOptionProps[]) => {
    setActiveOptionState(options);
  };

  return {
    activeOptionState,
    setActiveOptionState,
    onClickHandler,
    onParentClickHandler,
    selectAllHandler,
    deselectAllHandler,
    customSelectionHandler,
  };
};
