import { CustomerSuppplierPagesData } from 'features/DigitalActivities/DigitalActivities';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { CRMCountryData, ExpansionOpportunity } from 'hooks/queries';
import { FileTreeElement } from 'lib/sharepoint';
import { models } from 'powerbi-client';
import { MarketInsight, MarketInsightResponse, Principal } from '../collection';
import { functions } from '../firebase-config';
import {
  BaseUserType,
  CalcMandatesPerUserRequest,
  CreateNewPortalUserRequest,
  CRMMandatesPayload,
  CRMStateData,
  DeleteUsersRequest,
  DeleteUsersResponse,
  EditUserRequest,
  EditUserResponse,
  EmailRequest,
  FirebaseTokenForDataConnectionApiParams,
  GetEmbedTokenRequestParams,
  GetTokenResponse,
  InviteNewPortalMemberRequest,
  MappedMandates,
  SuccessAndMessage,
  ViewUsersRequest,
  ViewUsersResponse,
} from './firebase-functions.types';

export const getFireBaseTokenForUser = async (
  idToken: string,
): Promise<HttpsCallableResult<GetTokenResponse>> => {
  const getFirebaseTokenForUser = httpsCallable<
    { idToken: string },
    GetTokenResponse
  >(functions, 'getFirebaseTokenForUser');
  return await getFirebaseTokenForUser({ idToken });
};

export const getFirebaseTokenForDataConnectionAPI = async (
  params: FirebaseTokenForDataConnectionApiParams,
): Promise<HttpsCallableResult<GetTokenResponse>> => {
  const getFirebaseTokenForDataConnectionAPI = httpsCallable<
    FirebaseTokenForDataConnectionApiParams,
    GetTokenResponse
  >(functions, 'getFirebaseTokenForDataConnectionAPI');
  return await getFirebaseTokenForDataConnectionAPI(params);
};

export const getEmbedToken = async (
  params: GetEmbedTokenRequestParams,
): Promise<HttpsCallableResult<string>> => {
  const request = httpsCallable<typeof params, string>(
    functions,
    'getEmbedToken',
  );
  return await request(params);
};

export const getCustomerSupplierPages = async (params: {
  principal: Principal;
}): Promise<HttpsCallableResult<CustomerSuppplierPagesData>> => {
  const request = httpsCallable<typeof params, CustomerSuppplierPagesData>(
    functions,
    'getCustomerSupplierPages',
  );

  return await request(params);
};

export const calculateMandatesPerUser = async (
  params: CalcMandatesPerUserRequest,
): Promise<HttpsCallableResult<void>> => {
  const request = httpsCallable<typeof params, void>(
    functions,
    'calculateMandatesPerUser',
  );
  return await request(params);
};

export const inviteNewPortalUser = async (
  params: InviteNewPortalMemberRequest,
): Promise<HttpsCallableResult<SuccessAndMessage>> => {
  const request = httpsCallable<typeof params, SuccessAndMessage>(
    functions,
    'inviteNewPortalUser',
  );
  return await request(params);
};

export const sendEmail = async (
  params: EmailRequest & {
    additionalData?: {
      currentPrincipal: Principal;
    };
  },
): Promise<HttpsCallableResult<SuccessAndMessage>> => {
  const request = httpsCallable<typeof params, SuccessAndMessage>(
    functions,
    'sendEmail',
  );
  return await request(params);
};

export const createNewPortalUser = async (
  params: CreateNewPortalUserRequest,
): Promise<HttpsCallableResult<SuccessAndMessage>> => {
  const request = httpsCallable<typeof params, SuccessAndMessage>(
    functions,
    'createNewPortalUser',
  );
  return await request(params);
};

export const getCRMMandates = async (
  params: CRMMandatesPayload,
): Promise<HttpsCallableResult<MappedMandates>> => {
  const request = httpsCallable<
    CRMMandatesPayload & {
      getMappedMandates: boolean;
    },
    MappedMandates
  >(functions, 'getCRMMandates');
  return await request({ ...params, getMappedMandates: true });
};

export const getCRMMarketInsights = async (params: {
  principalPri: string;
}): Promise<HttpsCallableResult<MarketInsightResponse[]>> => {
  const request = httpsCallable<typeof params, MarketInsightResponse[]>(
    functions,
    'getCRMMarketInsights',
  );
  return await request(params);
};

export const getCRMExpansionOpportunities = async (params: {
  principalPri: string;
}): Promise<HttpsCallableResult<ExpansionOpportunity[]>> => {
  const request = httpsCallable<typeof params, ExpansionOpportunity[]>(
    functions,
    'getCRMExpansionOpportunities',
  );
  return await request(params);
};

export const deleteUsers = async (
  params: DeleteUsersRequest,
): Promise<HttpsCallableResult<DeleteUsersResponse>> => {
  const request = httpsCallable<typeof params, DeleteUsersResponse>(
    functions,
    'deleteUsers',
  );
  return await request(params);
};

export const viewUsers = async (
  params: ViewUsersRequest,
): Promise<HttpsCallableResult<ViewUsersResponse>> => {
  const request = httpsCallable<typeof params, ViewUsersResponse>(
    functions,
    'viewUsers',
  );
  return await request(params);
};

export const editUser = async (
  params: EditUserRequest,
): Promise<HttpsCallableResult<EditUserResponse>> => {
  const request = httpsCallable<typeof params, EditUserResponse>(
    functions,
    'editUser',
  );
  return await request(params);
};

export const getCRMCountriesData = async (): Promise<
  HttpsCallableResult<CRMCountryData[]>
> => {
  const request = httpsCallable<unknown, CRMCountryData[]>(
    functions,
    'getCRMCountriesData',
  );
  return await request();
};

export const setUserDisplayName = async (
  currentPrincipal?: string,
): Promise<HttpsCallableResult<string>> => {
  const request = httpsCallable<typeof currentPrincipal, string>(
    functions,
    'setDisplayName',
  );
  return await request(currentPrincipal);
};

export const getCRMStatesData = async (): Promise<
  HttpsCallableResult<CRMStateData[]>
> => {
  const request = httpsCallable<unknown, CRMStateData[]>(
    functions,
    'getCRMStatesData',
  );
  return await request();
};

export const searchAdUsersByName = async (
  selectedPrincipalId: string,
  searchTerm: string,
): Promise<HttpsCallableResult<BaseUserType[]>> => {
  const request = httpsCallable<{ [key: string]: string }, BaseUserType[]>(
    functions,
    'searchAdUsersByName',
  );
  return await request({ selectedPrincipalId, searchTerm });
};

export const generateMarketIntelligenceEntryLink = async (params: {
  entry: MarketInsight;
  type: 'link' | 'email';
  text?: {
    intro: string;
    outro: string;
    subject: string;
  };
}): Promise<HttpsCallableResult<string>> => {
  const request = httpsCallable<typeof params, string>(
    functions,
    'generateMarketIntelligenceEntryLink',
  );
  return await request(params);
};

export const generateSharepointDownloadLink = async (params: {
  fileTreeElement: FileTreeElement;
  principalPri: string;
}): Promise<HttpsCallableResult<string>> => {
  const request = httpsCallable<typeof params, string>(
    functions,
    'generateSharepointDownloadLink',
  );
  return await request(params);
};

export const generateSocialListeningReportLink = async (params: {
  fileTreeElement: FileTreeElement;
  principalPri: string;
}): Promise<HttpsCallableResult<string>> => {
  const request = httpsCallable<typeof params, string>(
    functions,
    'generateSocialListeningReportLink',
  );
  return await request(params);
};

export const generatePowerBIEmbedUrl = async (params: {
  report: {
    reportId: string;
    groupId: string;
  };
  reportName: 'dashboard' | 'operational_performance' | 'customer_insights';
  principalPri: string;
  section?: string;
}): Promise<HttpsCallableResult<string>> => {
  const request = httpsCallable<typeof params, string>(
    functions,
    'generatePowerBIEmbedUrl',
  );
  return await request(params);
};

export const generatePowerBIFilters = async (params: {
  filters: models.IBasicFilter[];
  reportName: 'dashboard' | 'operational_performance' | 'customer_insights';
  principalPri: string;
  section?: string;
}): Promise<HttpsCallableResult<models.IBasicFilter[]>> => {
  const request = httpsCallable<typeof params, models.IBasicFilter[]>(
    functions,
    'generatePowerBIFilters',
  );
  return await request(params);
};
