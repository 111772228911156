import classNames from 'classnames';
import { Paragraph } from 'components/Paragraph';
import React from 'react';

export type LegendEntry = { color: string; name: string };

export interface ILegendProps {
  legendEntries: LegendEntry[];
}

export const Legend: React.FC<ILegendProps> = ({
  legendEntries,
}: ILegendProps) => {
  return (
    <div className="absolute bottom-0 mb-3 flex w-full px-3">
      {legendEntries.map(({ name, color }) => (
        <div className="float-left mr-1 mt-1 flex items-center" key={name}>
          <span
            className="mb-1px relative mr-1 flex h-3 w-3 shrink-0 items-center justify-center"
            aria-hidden="true"
          >
            <span
              className={classNames(
                'relative block h-3 w-3 rounded-full border border-white',
                [color],
              )}
            />
          </span>
          <Paragraph variant="small">{name}</Paragraph>
        </div>
      ))}
    </div>
  );
};
