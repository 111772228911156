import { Alert } from 'components/Alert';
import { CountryContact } from 'components/CountryContact';
import { CountryTile } from 'components/CountryTile';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import getCountryISO2 from 'country-iso-3-to-2';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NameEmailPair } from './types';

type CountryModalIntroProps = {
  iso3: string | undefined;
  name: string | undefined;
  region?: string;
  cluster?: string;
  about?: string;
  managingDirector: NameEmailPair;
  servedBy?: {
    aze_countryid: string;
    aze_isocode: string;
    aze_name: string;
  };
  extraMessage?: string;
};

export const Intro: React.FC<CountryModalIntroProps> = ({
  iso3,
  name,
  region,
  cluster,
  about,
  managingDirector,
  servedBy,
  extraMessage,
}: CountryModalIntroProps) => {
  const { t } = useTranslation();
  const iso2ForFlag = getCountryISO2(iso3).toLowerCase();

  const servedByName = servedBy?.aze_name;

  return (
    <>
      <div className="flex content-center items-center">
        <span
          className={`fi -top-3px mr-3 h-5 w-5 ${`fi-${iso2ForFlag}`}`}
        ></span>
        <div className="leading-9">
          <Heading
            level="h2"
            text={name || t('labels:unknown')}
            margin={0}
            weight="semibold"
          />
        </div>
      </div>
      <span className="text-grey-600 mb-4 block text-base leading-4">
        {region && (
          <>
            {t('modals:countryModal:azelisRegion')}:{' '}
            {region || t('labels:unknown')}
          </>
        )}
        {cluster && (
          <>
            , {t('modals:countryModal:cluster')}:{' '}
            {cluster || t('labels:unknown')}
          </>
        )}
      </span>
      {!!servedBy && (
        <div className="mb-5">
          <Alert
            message={t('modals:countryModal:countryServedBy', { servedByName })}
            flavour="info"
            icon="InfoCircle"
          />
        </div>
      )}
      {!!extraMessage && (
        <div className="mb-5">
          <Alert message={extraMessage} flavour="info" icon="InfoCircle" />
        </div>
      )}
      {about && (
        <>
          <Heading level="h3" text={t('labels:about')} />
          <div className="mb-3">
            <Paragraph lineNumber={4} isExpandable>
              {about || t('labels:unknown')}
            </Paragraph>
          </div>
        </>
      )}
      <div className="pb-3">
        <CountryTile headline={t('modals:countryModal:localContact')}>
          <CountryContact details={managingDirector} />
        </CountryTile>
      </div>
    </>
  );
};
