import { Alert } from 'components/Alert';
import { Input } from 'components/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseUserType } from 'utils/firebase';
import { NewUserDetails } from '..';

export type UserDetailsProps = {
  initialUserData?: Partial<NewUserDetails>;
  isEmailValid: boolean;
  isInputDisabled?: boolean;
  errorMessage?: string;
  placeholder: BaseUserType;
  heading: string;
  validateNewUsersEmailAdress: (value: string) => void;
  onChangeForm: (
    inputValue: Record<
      string,
      Record<string, string | string[] | Record<string, string[]>> | string
    >,
  ) => void;
};

export const UserDetails: React.FC<UserDetailsProps> = ({
  initialUserData,
  isEmailValid,
  heading,
  isInputDisabled,
  placeholder,
  errorMessage,
  validateNewUsersEmailAdress,
  onChangeForm,
}: UserDetailsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {errorMessage && (
        <div className="mb-4">
          <Alert
            message={errorMessage}
            flavour="error"
            icon="Warning"
            iconColor="red"
          />
        </div>
      )}
      <h3 className="mb-3 text-2xl font-medium">{heading}</h3>
      <fieldset>
        <div className="grid grid-cols-2 gap-4">
          <div className="w-full">
            <label
              htmlFor="newUser_firstName"
              className="mb-1 block font-medium"
            >
              {t('modals:addUserModal:firstName')}
            </label>
            <Input
              dataTestid="first-name"
              id="newUser_firstName"
              placeholder={placeholder.firstName}
              initialValue={initialUserData?.firstName}
              disabled={isInputDisabled}
              onChange={(e) => {
                onChangeForm({ firstName: e.target.value });
              }}
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="newUser_lastName"
              className="mb-1 block font-medium"
            >
              {t('modals:addUserModal:lastName')}
            </label>
            <Input
              dataTestid="last-name"
              id="newUser_lastName"
              placeholder={placeholder.lastName}
              initialValue={initialUserData?.lastName}
              disabled={isInputDisabled}
              onChange={(e) => {
                onChangeForm({ lastName: e.target.value });
              }}
            />
          </div>
        </div>
      </fieldset>
      <fieldset>
        <div className="mb-4 mt-4">
          <label htmlFor="newUser_email" className="mb-1 block font-medium">
            {t('modals:addUserModal:email')}
          </label>
          <Input
            dataTestid="email"
            id="newUser_email"
            placeholder={placeholder.email}
            type="email"
            initialValue={initialUserData?.email}
            disabled={isInputDisabled}
            error={!!errorMessage || !isEmailValid}
            onChange={(e) => validateNewUsersEmailAdress(e.target.value)}
          />
          {!isEmailValid && (
            <div className="mt-1 text-sm text-red-200">
              {t('modals:addUserModal:errors:invalidEmail')}
            </div>
          )}
        </div>
      </fieldset>
    </>
  );
};
