import { Link } from 'components/Link';
import { CountryProps } from 'features/Dashboard/Map/DashboardMap';
import { useMarketSegmentDefinitions } from 'hooks/queries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DaLocalizationConfig } from './DigitalActivitiesModal';

type LocalizationConfigDisplayProps = {
  fullAvailableCountries: CountryProps[];
  localizationConfig: DaLocalizationConfig | undefined;
  onEditClick: () => void;
};

export const LocalizationConfigDisplay: React.FC<
  LocalizationConfigDisplayProps
> = ({
  fullAvailableCountries,
  localizationConfig,
  onEditClick,
}: LocalizationConfigDisplayProps) => {
  const { t } = useTranslation();
  const marketSegmentDefinitions = useMarketSegmentDefinitions();
  return (
    <div className="bg-grey-100 mb-5 flex justify-between rounded p-4">
      {localizationConfig ? (
        <div className="mr-2">
          {t('features:digital-activities:configBar:country')}:{' '}
          <span className="font-medium">
            {fullAvailableCountries.find(
              (i) => i.ISO_A3 === localizationConfig.country,
            )?.name || localizationConfig.country}
          </span>
          {', '}
          {t('features:digital-activities:configBar:marketSegment')}:{' '}
          <span className="font-medium">
            {marketSegmentDefinitions[localizationConfig.marketSegmentId]?.name}
          </span>
          {', '}
          {t('features:digital-activities:configBar:language')}:{' '}
          <span className="font-medium">
            {localizationConfig.language
              ? t(`languages:${localizationConfig.language}`)
              : localizationConfig.language}
          </span>
        </div>
      ) : (
        <span className="text-grey-600">
          {t('features:digital-activities:configBar:noMarket')}
        </span>
      )}
      <Link
        label={t(
          `features:digital-activities:configBar:${
            localizationConfig ? 'edit' : 'select'
          }Market`,
        )}
        icon="Edit"
        iconPosition="left"
        onClick={onEditClick}
      />
    </div>
  );
};
