import React from 'react';

export type CountryTileProps = {
  headline: string;
  number?: number;
  children?: React.ReactNode;
};

export const CountryTile: React.FC<CountryTileProps> = ({
  headline,
  children,
  number,
}) => {
  return (
    <div className="rounded-4px border-grey-300 px-20px relative mb-4 border border-solid bg-white py-4">
      <h6 className="text-2xs leading-1 pb-4 font-sans uppercase tracking-wider">
        {headline}
      </h6>
      {number && (
        <span className="inline-block font-serif text-5xl font-semibold">
          {number}
        </span>
      )}
      {children}
    </div>
  );
};
