import classnames from 'classnames';
import React from 'react';
import { Icon } from '../Icon';

export type EmptyStateProps = {
  message?: string;
  hasBorder?: boolean;
};

export const EmptyState: React.FC<EmptyStateProps> = ({
  message,
  hasBorder,
}) => {
  return (
    <div
      className={classnames('flex flex-col items-center', {
        'border-grey-300 border p-6': hasBorder,
      })}
    >
      <span className="border-grey-300 h-20 w-20">
        <Icon name="EmptyMarket" />
      </span>
      {message && (
        <span
          className={classnames('text-grey-600 max-w-622px mt-4 text-center', {
            'text-2xl': message.length < 60,
          })}
        >
          {message}
        </span>
      )}
    </div>
  );
};
