import { InternalRoute } from 'controllers/ContentController/Routes';
import { useCurrentUser } from 'hooks/queries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Role } from 'shared/types/authorization';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';
import { NavProps, siteHeaderStyles } from './SiteHeader';

type LogoProps = NavProps & {
  dashboardRoute: InternalRoute;
  selectedPrincipal: Principal;
};

export const Logos: React.FC<LogoProps> = ({
  selectedPrincipal,
  bigMenu,
  dashboardRoute,
}) => {
  const { t } = useTranslation();
  const { user, role } = useCurrentUser();

  return (
    <>
      <h1 className="order-none shrink-0">
        <Link
          to={dashboardRoute.url}
          type="router"
          className={`flex ${bigMenu ? siteHeaderStyles.bigMenuLinks : ''}`}
        >
          <img
            src="/logo.svg"
            alt={t('labels:portalName')}
            className={`ml-5 h-full w-7 py-3 md:ml-3 lg:h-auto lg:w-9 lg:py-4 ${
              bigMenu ? '' : 'lg:m-auto lg:mx-auto lg:px-1'
            }`}
          />
          <span
            className={`${
              bigMenu ? siteHeaderStyles.bigMenuLabels : 'hidden'
            } my-auto ml-2 font-semibold`}
          >
            {t('labels:portalName')}
          </span>
        </Link>
      </h1>
      {user &&
        selectedPrincipal.logoUrl &&
        role === Role.DISTRIBUTION_MANAGER && (
          <>
            <div
              className={`mr-auto flex shrink-0 items-center ${
                bigMenu ? 'ml-4' : 'lg:mx-auto'
              }`}
            >
              <Link
                to={dashboardRoute.url}
                className={`flex ${
                  bigMenu ? siteHeaderStyles.bigMenuLinks : ''
                }`}
              >
                <div className="rounded bg-white">
                  <img
                    src={selectedPrincipal.logoUrl}
                    className="h-6 w-6"
                    alt={selectedPrincipal.name}
                  />
                </div>
                {user && bigMenu && (
                  <span
                    className={`${
                      bigMenu ? siteHeaderStyles.bigMenuLabels : 'lg:hidden'
                    } my-auto ml-5 pl-4 font-semibold leading-4`}
                  >
                    {selectedPrincipal.name}
                  </span>
                )}
              </Link>
            </div>
            <hr
              className={`border-grey-600 mt-3 ${bigMenu ? 'lg:w-full' : ''}`}
            />
          </>
        )}
    </>
  );
};
