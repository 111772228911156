import { Alert } from 'components/Alert';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { Heading } from 'components/Heading';
import { LoadingBounce } from 'components/Loading';
import { PowerBiReport } from 'components/PowerBiReport';
import { Tab, Tabs } from 'components/Tabs';
import { routes } from 'controllers/ContentController/Routes';
import { iso3CountryCodesToRegionsDropdownOptions } from 'features/DigitalActivities/DigitalActivitiesModal';
import {
  PerformanceTabConfigKeys,
  useCRMMandates,
  useCurrentPrincipal,
  useCurrentUser,
  useMarketSegmentDefinitions,
  useOpPerfTabConfigs,
} from 'hooks/queries';
import { useCRMStatesData } from 'hooks/queries/useCRMStateData';
import { MarketSegmentDefinitions } from 'hooks/queries/useMarketSegmentDefinitions';
import { generateFilters } from 'lib/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Regions, countriesMappedByRegion } from 'utils/countries';
import { MandateFiltersForPrincipal } from 'utils/firebase/collection/firebase-collection-types';
import { FilterOptions, OPFilters } from './OPFilters';

const initialFilterOptions: Omit<FilterOptions, 'state'> = {
  country: [],
  marketSegment: [],
  portfolio: [],
};

export const convertMandatesToFilterOptions = (
  userMandateFiltersForThisPrincipal: MandateFiltersForPrincipal,
  regions: Regions,
  marketSegmentDefinitions: MarketSegmentDefinitions,
): FilterOptions => {
  return Object.entries(userMandateFiltersForThisPrincipal).reduce(
    (newObj, [key, val]) => {
      if (key === 'country') {
        newObj[key as keyof FilterOptions] =
          iso3CountryCodesToRegionsDropdownOptions(val as string[], regions);
      } else if (key === 'marketSegment') {
        newObj[key as keyof FilterOptions] = (val as string[]).map((i) => ({
          name: marketSegmentDefinitions[i]?.name,
          value: i,
        }));
      } else if (key === 'portfolio') {
        newObj['portfolio'] = (val as string[]).map((i) => ({
          name: i,
          value: i,
        }));
      }

      return newObj;
    },
    initialFilterOptions,
  );
};

const GLOBAL_USER_FILTER_OPTIONS = {
  country: [],
  portfolio: [],
  marketSegment: [],
  state: [],
  hasAllMandates: true,
};

export const OperationalPerformance: React.FC = () => {
  const { t } = useTranslation();
  const { tab: tabFromURL } = useParams<{
    tab: PerformanceTabConfigKeys | undefined;
  }>();
  const { user, isGlobalUser } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(user.id);
  const { data: mandates } = useCRMMandates({
    principalPri: currentPrincipal.principalPri,
  });
  let { data: opPerfTabConfigs } = useOpPerfTabConfigs();

  // bringing tabs into a specific order
  opPerfTabConfigs = opPerfTabConfigs
    ? (({ Sales, Opportunities, Supplychain }) => ({
        Sales,
        Opportunities,
        Supplychain,
      }))(opPerfTabConfigs)
    : undefined;

  const navigate = useNavigate();
  const marketSegmentDefinitions = useMarketSegmentDefinitions();

  const [selectedTab, setSelectedTab] = useState<PerformanceTabConfigKeys>(
    tabFromURL ?? 'Sales',
  );
  const [appliedFilters, setAppliedFilters] = useState<
    FilterOptions | undefined
  >();
  const [filterOptions, setFilterOptions] = useState<
    FilterOptions | undefined
  >();
  const [isReportLoaded, setIsReportLoaded] = useState<boolean>(false);
  const areTabsDisabled = !isReportLoaded;

  useEffect(() => {
    const { operationalPerformanceRoute } = routes(currentPrincipal.slug);
    navigate(`${operationalPerformanceRoute.url}/${selectedTab}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const fullAvailableCountries = useMemo(
    () =>
      mandates?.countries.map((country) => ({
        ISO_A2: '',
        ISO_A3: country.iso,
        name: country.name,
      })) ?? [],
    [mandates],
  );

  const regions = countriesMappedByRegion(fullAvailableCountries);
  const usaStates = useCRMStatesData();
  const availableUSAStates = usaStates.map(({ id }) => id);

  useEffect(() => {
    const userMandateFiltersForThisPrincipal = isGlobalUser
      ? GLOBAL_USER_FILTER_OPTIONS
      : user?.mandateFilters?.[currentPrincipal.id];

    const countriesFromMandatesAreLoaded = fullAvailableCountries?.length;

    if (
      !userMandateFiltersForThisPrincipal ||
      !countriesFromMandatesAreLoaded ||
      filterOptions ||
      !mandates?.count.mandates
    )
      return;

    const availablePortfolios: DropdownOptionProps[] = mandates.portfolios.map(
      ({ name, id }) => ({
        name,
        value: id,
      }),
    );

    if (userMandateFiltersForThisPrincipal.hasAllMandates || isGlobalUser) {
      const availableMarketSegments = mandates.marketSegments.map(
        ({ name, id }) => ({
          name,
          value: id,
        }),
      );

      const availableCountries: DropdownOptionProps[] = Object.entries(
        regions,
      ).map(([region, countries]) => {
        return {
          name: region,
          value: region,
          childOptions: countries.map(({ ISO_A3, name }) => ({
            name,
            value: ISO_A3,
          })),
        };
      });

      setFilterOptions({
        country: availableCountries,
        portfolio: availablePortfolios,
        marketSegment: availableMarketSegments,
      });

      return;
    }

    const newFilterOptions = convertMandatesToFilterOptions(
      userMandateFiltersForThisPrincipal,
      regions,
      marketSegmentDefinitions,
    );

    const userAvailablePortfolios = newFilterOptions.portfolio.map(
      (portfolio) => {
        return {
          ...portfolio,
          name:
            availablePortfolios.find(
              (portId) => portId.value === portfolio.value,
            )?.name ?? '',
        };
      },
    );

    newFilterOptions.portfolio = userAvailablePortfolios;

    setFilterOptions(newFilterOptions);
  }, [
    availableUSAStates,
    currentPrincipal,
    filterOptions,
    fullAvailableCountries,
    isGlobalUser,
    mandates,
    marketSegmentDefinitions,
    regions,
    user,
  ]);

  const powerBiFilters = useMemo(() => {
    let filtersToUse = appliedFilters;

    if (!filtersToUse) {
      const convertedCountriesToSingleLevelFilters =
        filterOptions?.country.reduce((acc, item) => {
          if (item.childOptions) {
            acc = acc.concat(
              item.childOptions.map((childOption) => ({
                ...childOption,
              })),
            );
          } else {
            acc.push({ ...item });
          }
          return acc;
        }, [] as DropdownOptionProps[]);

      filtersToUse = filterOptions
        ? {
            ...filterOptions,
            country: convertedCountriesToSingleLevelFilters || [],
          }
        : undefined;
    }

    const selectedCountryFilterValues =
      filtersToUse?.country.map((country: DropdownOptionProps) =>
        country.value.toString(),
      ) || [];

    const selectedMarketSegmentFilterValues =
      filtersToUse?.marketSegment.map((marketSegment: DropdownOptionProps) =>
        marketSegment.value.toString(),
      ) || [];

    const selectedPortfolios =
      filtersToUse?.portfolio.map((portfolio: DropdownOptionProps) =>
        portfolio.value.toString(),
      ) || [];

    const portfolioColumnName =
      selectedTab === 'Opportunities' ? 'Products' : 'Items';

    return [
      generateFilters(
        'Market Segment',
        'Market Segment Code',
        selectedMarketSegmentFilterValues, // Change these to be codes (FH) rather than names
      ),
      generateFilters('Company', 'Country Code', selectedCountryFilterValues),
      generateFilters(portfolioColumnName, 'Portfolio Key', selectedPortfolios),
    ];
  }, [appliedFilters, selectedTab, filterOptions]);

  return (
    <div className="h-full w-full flex-1 bg-white px-4 md:px-6">
      <div className="max-w-xl-content mx-auto h-full">
        {opPerfTabConfigs && currentPrincipal && filterOptions ? (
          <>
            <div className="mb-7 pt-6 md:mb-3 md:pt-7" data-test="page-header">
              <div className="flex flex-col items-baseline justify-between md:flex-row md:space-x-2">
                <Heading
                  text={t('features:operationalPerformance:heading')}
                  margin={0}
                  level="h1"
                />
                <div className="flex w-full md:w-8/12 md:justify-end">
                  <div className="hidden md:block">
                    <OPFilters
                      filterOptions={filterOptions}
                      disableMarketSegments={
                        !!opPerfTabConfigs[selectedTab].disableMarketsegments
                      }
                      onChange={(newData: FilterOptions) => {
                        setAppliedFilters(newData);
                      }}
                      currentPrincipal={currentPrincipal.id}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Tabs
              activeTab={selectedTab}
              onTabChange={(tab) =>
                setSelectedTab(tab as PerformanceTabConfigKeys)
              }
              isDisabled={areTabsDisabled}
            >
              {Object.entries(opPerfTabConfigs).map(([key, value]) => (
                <Tab key={key} tabKey={key} title={value?.title?.toString()} />
              ))}
            </Tabs>
            <PowerBiReport
              filters={powerBiFilters}
              hideFilters={true}
              onReportLoadingStatusChange={setIsReportLoaded}
              reportName="operational_performance"
              reportPage={opPerfTabConfigs[selectedTab].reportPage}
              role={currentPrincipal.manufacturerName}
              tabTitle={opPerfTabConfigs[selectedTab].title}
            />
            {(selectedTab === 'Sales' || selectedTab === 'Opportunities') && (
              <Alert
                message={t('features:operationalPerformance:mandatesNote')}
                flavour="info"
              />
            )}
          </>
        ) : (
          <LoadingBounce />
        )}
      </div>
    </div>
  );
};
