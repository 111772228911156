import classNames from 'classnames';
import {
  DropdownComponentProps,
  DropdownSingleSelect,
} from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { GeoPath, GeoProjection } from 'd3-geo';
import { MapConfigs, MapName } from 'features/Dashboard/Map/MapConfigs';
import React, { useEffect, useState } from 'react';
import { logger } from 'shared/Logger';
import { Icon } from '../Icon';
import { ComposableMapContainer } from './ComposableMapContainer';
import { Legend, LegendEntry } from './Legend';
import './map.css';

export type MapsCountry = { ISO_A3: string; ISO_A2: string; name: string };

export type Geometry = {
  arcs: number[][] | number[][][];
  properties: {
    NAME: string;
    ISO_A3: string;
    ISO_A2: string;
  };
  type: 'Polygon' | 'MultiPolygon';
};

export type GeoDataResponse = {
  objects?: {
    ne_110m_admin_0_countries?: {
      geometries?: Geometry[];
    };
  };
};

const getGeoData = async (mapDataFilename: string) =>
  // Map data files can be found in {project_root}/public/map-data/
  fetch(`/map-data/${mapDataFilename}.json`)
    .then((res) => res.json())
    .catch((err) => console.error(err));

export const customiseMap = (geoData: GeoDataResponse): void => {
  const geometries = geoData?.objects?.ne_110m_admin_0_countries?.geometries;

  if (!geometries) {
    console.error(
      '🚨 Geometries not found in map source. The source data structure may have been updated. Check this urgently! 🚨',
    );
    return;
  }

  // Currently necessary because of the annexation of Crimea by the Russian Federation
  // https://en.wikipedia.org/wiki/Annexation_of_Crimea_by_the_Russian_Federation
  // Remove Crimea from Russia
  const russiaData = geometries.find((i) => i.properties.ISO_A3 === 'RUS');
  if (russiaData) {
    russiaData.arcs = (russiaData.arcs as number[][][]).filter(
      (i) => JSON.stringify(i).replace(/\s/g, '') !== '[[517,518]]',
    );

    // Mark Crimea as a DisputedTerritory
    geometries.push({
      arcs: [[517, 518]],
      properties: {
        NAME: 'DisputedTerritory',
        ISO_A2: 'XX',
        ISO_A3: 'XXX',
      },
      type: 'Polygon',
    });
  }

  // Make French Guiana as a DisputedTerritory
  const frenchGuianaData = geometries.find(
    (i) => i.properties.ISO_A3 === 'FRA',
  );
  if (frenchGuianaData) {
    frenchGuianaData.arcs = (frenchGuianaData.arcs as number[][][]).filter(
      (i) => JSON.stringify(i).replace(/\s/g, '') !== '[[-111, 278, 279]]',
    );

    geometries.push({
      arcs: [[-111, 278, 279]],
      properties: {
        NAME: 'DisputedTerritory',
        ISO_A2: 'XX',
        ISO_A3: 'XXX',
      },
      type: 'Polygon',
    });
  }

  // Change Western Sahara to become part of Morocco
  const westernSaharaData = geometries.find(
    (i) => i.properties.ISO_A3 === 'ESH',
  );
  if (westernSaharaData) {
    westernSaharaData.arcs = (westernSaharaData.arcs as number[][][]).filter(
      (i) =>
        JSON.stringify(i).replace(/\s/g, '') !== '[[-239, -453, 532, -426]]',
    );

    geometries.push({
      arcs: [[-239, -453, 532, -426]],
      properties: {
        NAME: 'Morocco',
        ISO_A2: 'MA',
        ISO_A3: 'MAR',
      },
      type: 'Polygon',
    });
  }
};

export type MapCountry = {
  ISO?: string;
  colorGroup: LegendEntry;
  // filterProperties?: string[];
  data?: string;
  // property name is only for expansion opportunities and will be removed by getting EOs from CRM!
  name?: string;
};

export type CountryClickEvent = {
  ISO_A3: string;
  ISO_A2: string;
  name: string;
};

export interface MapProps {
  // countries must not be names, please use ISO stuff to have no naming conflicts
  // --> generic creation of filters
  countries?: MapCountry[];
  // This property is only for expansion opportunities and has to be removed after getting EOs from CRM.
  opportunity?: boolean;
  fixedLegend?: LegendEntry[];
  mapName?: MapName;
  mapDropdown?: DropdownComponentProps;
  onClick?: (e: CountryClickEvent) => void;
}

type PositionProps = {
  coordinates: [number, number];
  zoom: number;
};

export const Map: React.FC<MapProps> = ({
  countries,
  opportunity,
  fixedLegend,
  mapName = MapName.WORLD,
  mapDropdown,
  onClick,
}) => {
  const { mapDataFilename, initialPosition } = MapConfigs[mapName];

  const [position, setPosition] = useState<PositionProps>(initialPosition);
  const [geoData, setGeoData] = useState<GeoDataResponse | undefined>();

  useEffect(() => {
    getGeoData(mapDataFilename).then((data) => {
      setPosition(initialPosition);

      if (mapName === MapName.WORLD) {
        customiseMap(data);
      }

      setGeoData(data);
    });
  }, [mapDataFilename, initialPosition, mapName]);

  const colorGroups = countries?.map((country) => country.colorGroup);
  const legendEntries: LegendEntry[] | undefined = colorGroups?.filter(
    (country, index, self) =>
      index ===
      self.findIndex(
        (t) => t.name === country.name && t.color === country.color,
      ),
  );

  const handleZoomIn = () => {
    if (position.zoom >= 8) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 1.2 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 0.8) setPosition((pos) => ({ ...pos, zoom: 0.8 }));
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 1.2 }));
  };

  const handleMoveEnd = (position: PositionProps) =>
    setPosition({ ...position });

  const getColor = (currCountry: string) => {
    const colorCode = countries?.find((country) => country.ISO === currCountry)
      ?.colorGroup.color;
    const colorCodeForName = countries?.find(
      (country) => country?.name === currCountry,
    )?.colorGroup.color;

    if (mapName === MapName.USA) return '#1A81C466';

    switch (opportunity ? colorCodeForName : colorCode) {
      case 'bg-purple-100':
        return '#C3A0C9';
      case 'bg-blue-200':
        return '#1A81C4';
      case 'bg-blue-240':
        return '#1A81C466';
      case 'bg-teal-100':
        return '#26BAAC';
      default:
        return '#FFF';
    }
  };

  const handleGeographyClick = (
    geo: Geometry,
    projection: GeoProjection,
    path: GeoPath,
  ) => {
    logger({ geo, projection, path });
    if (onClick) {
      //This is a temp solution until we get actual data for the us states mandates and state info
      const isUSAMap = mapName === MapName.USA;
      onClick({
        ISO_A2: isUSAMap ? 'US' : geo.properties.ISO_A2,
        ISO_A3: isUSAMap ? 'USA' : geo.properties.ISO_A3,
        name: isUSAMap ? 'United States of America' : geo.properties.NAME,
      });
    }
  };

  return (
    <div className="bg-map-sea relative block">
      {mapDropdown && (
        <div
          data-testid="map-dropdown"
          className="min-w-220px absolute ml-3 mt-3"
        >
          <DropdownSingleSelect
            label={mapDropdown.label}
            initialSelection={mapDropdown.initialSelection}
            options={mapDropdown.options}
            onChange={mapDropdown.onChange}
            widthVariant="full-width"
          />
        </div>
      )}
      <div className="left-16px border-grey-400 absolute bottom-[55px] mb-0 mt-0 flex flex-col justify-center border">
        <button
          className={classNames('p-4px h-4 w-4 bg-white', {
            'bg-grey-200 cursor-auto': position.zoom >= 8,
          })}
          onClick={handleZoomIn}
        >
          <Icon name="Add" color={position.zoom >= 8 ? 'grey-500' : 'black'} />
        </button>
        <button
          className={classNames('p-4px h-4 w-4 bg-white', {
            'bg-grey-200 cursor-auto': position.zoom <= 0.8,
          })}
          onClick={handleZoomOut}
        >
          <Icon
            name="Remove"
            color={position.zoom <= 0.8 ? 'grey-500' : 'black'}
          />
        </button>
      </div>
      <div className="legend-container absolute bottom-0 w-full p-1" />
      {legendEntries && <Legend legendEntries={fixedLegend || legendEntries} />}
      <ComposableMapContainer
        geoData={geoData}
        position={position}
        getColor={getColor}
        handleGeographyClick={handleGeographyClick}
        handleMoveEnd={handleMoveEnd}
      />
    </div>
  );
};
