import { Modal } from 'components/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type RemoveGlobalUserModalProps = {
  onCancel: () => void;
  usersToDelete: { id: string; name?: string }[];
  onComplete?: () => void;
};

export const RemoveGlobalUserModal: React.FC<RemoveGlobalUserModalProps> = ({
  onCancel,
  usersToDelete,
  onComplete,
}: RemoveGlobalUserModalProps) => {
  const { t } = useTranslation();
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const numberOfUsersToDelete = usersToDelete.length;

  const deleteDisplayedUser = () => {
    if (!usersToDelete.length) return;

    setDisableConfirmButton(true);

    if (onComplete) {
      onComplete();
      setDisableConfirmButton(false);
    }
  };

  return (
    <Modal
      heading={t(`features:global-users:removeGlobalUser:removeRightsHeader`)}
      confirmText={t(`features:global-users:removeGlobalUser:confirm`)}
      onConfirm={deleteDisplayedUser}
      onCancel={onCancel}
      isConfirmButtonDisabled={disableConfirmButton}
      headingAlign="left"
      isBig
    >
      <div className="pt-1 text-left" data-testid="show-remove-modal">
        <p className="text-grey-700 mb-4">
          {t(`features:global-users:removeGlobalUser:removeRightsMessage`, {
            userName:
              numberOfUsersToDelete <= 1
                ? usersToDelete[0].name
                : `${numberOfUsersToDelete} users`,
          })}
        </p>
      </div>
    </Modal>
  );
};
