import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Heading } from 'components/Heading';
import { Link } from 'components/Link';
import { useTranslation } from 'react-i18next';

export type CountryLocationProps = {
  items: string[];
  initialDisplayLimit?: number;
};

export const CountryLocation: React.FC<CountryLocationProps> = ({
  items,
  initialDisplayLimit,
}: CountryLocationProps) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState<boolean>(false);

  // Needed to force hiding on re-render
  useEffect(() => {
    if (items) {
      setShowAll(false);
    }
  }, [items]);

  return (
    <>
      <ul className="mt-4">
        {items.map((location, index) => {
          const isHidden =
            initialDisplayLimit && index + 1 > initialDisplayLimit && !showAll;
          const isLastItem = index + 1 === items.length;
          const isLastDisplayedItem =
            !showAll && index + 1 === initialDisplayLimit;
          const locationParts = location.split(/\r\n|\n/);
          if (locationParts.length === 1) {
            locationParts.unshift('Azelis');
          }
          // \r\n and \n is a constant used in the CRM data to replace linebreak
          // characters for easier processing.
          return (
            !isHidden && (
              <li
                className={classNames(`p3`, {
                  'mb-6': !isLastItem && !isLastDisplayedItem,
                  'mb-5': isLastDisplayedItem,
                })}
                data-testid="country-location-item"
                key={index}
              >
                <address className="not-italic">
                  <Heading text={locationParts[0]} level="h4" />
                  {locationParts.slice(1).map((lp, index2) => (
                    <span className="break-normal" key={index2}>
                      {lp}
                      <br />
                    </span>
                  ))}
                </address>
                <div className="mt-1 text-sm uppercase">
                  <Link
                    label={t('features:dashboard:map:showOnMap')}
                    icon="ExternalLink"
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      locationParts.join(' '),
                    )}`}
                    variant="medium"
                  />
                </div>
              </li>
            )
          );
        })}
      </ul>
      {!!initialDisplayLimit && items.length > initialDisplayLimit && (
        // Flex class needed to top link spanning full width
        <div className="mt-5 flex" data-testid="show-more-link">
          <Link
            label={t(`linksOrButtons:${showAll ? 'showLess' : 'showXMore'}`, {
              number: items.length - initialDisplayLimit,
            })}
            onClick={() => setShowAll(!showAll)}
          />
        </div>
      )}
    </>
  );
};
