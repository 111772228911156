import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { Alert } from 'components/Alert';
import { SiteFooter } from 'components/SiteFooter';
import { clearCustomKeysFromLocalStorage } from 'lib/localStorageManager';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button';
import { Heading } from '../../components/Heading';
import { Icon } from '../../components/Icon';
import { tracking } from '../../lib/tracking';
import { loginRequest } from '../../shared/authentication-config';

type IntroProps = {
  error?: string;
  gotFirebaseUser?: boolean;
  logoutInProcess?: boolean;
};

export const Intro: React.FC<IntroProps> = ({
  error,
  gotFirebaseUser,
}: IntroProps) => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const firstFired = useRef(false);

  useEffect(() => {
    if (!firstFired.current && gotFirebaseUser === undefined) {
      tracking.push('content', 'Content View', {
        category: 'User Area',
        type: 'Sign in',
        content: 'User Sign in',
      });
      firstFired.current = true;

      // This is the most reliable way to identify whether the user has "logged out"
      // - The logout path may not be called if the user closes the tab/browser
      // - The login path is called whenever the user reloads the page
      clearCustomKeysFromLocalStorage();
    }
  }, [firstFired, gotFirebaseUser]);

  const introTranslations: string[] = t('features:intro:content:benefits', {
    returnObjects: true,
  });

  return (
    <>
      <div className="flex grow justify-center">
        <div className="xs:justify-center flex grow flex-wrap">
          <div className="min-h-50p w-full md:justify-center lg:w-6/12">
            <div className="px-5 py-9 md:px-11 lg:h-full">
              <img
                src="/logo-blue.svg"
                alt={t('labels:portalName')}
                className="mb-3 h-full lg:h-auto"
              />
              <div className="flex flex-col justify-center md:h-full">
                <div className="flex flex-col py-4">
                  <Heading
                    text={t('features:intro:heading:main')}
                    level="h1"
                    align="left"
                  />
                  <p>{t('features:intro:content:main')}</p>

                  <div className="mb-8 mt-5">
                    <UnauthenticatedTemplate>
                      <Button
                        label={t('features:signIn:loginWithActiveDirectory')}
                        onClick={() =>
                          instance.loginRedirect({
                            ...loginRequest,
                            prompt: 'select_account',
                          })
                        }
                      />
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                      <Button
                        label={t('features:signIn:logout')}
                        onClick={() => instance.logoutRedirect(loginRequest)}
                      />
                    </AuthenticatedTemplate>
                    {error && (
                      <div className="pt-5 font-semibold text-red-300">
                        {t(`features:signIn:errors:${error}`)}
                      </div>
                    )}
                  </div>
                  <div className="mb-8 mt-5">
                    <Heading
                      text={t('features:intro:heading:about')}
                      level="h3"
                      align="left"
                    />
                    <ul>
                      {introTranslations.map((item, index) => (
                        <li key={index} className="mb-3 flex">
                          <span className="bg-text1 p-4px mr-3 inline-block h-4 w-4 shrink-0 rounded-full text-white">
                            <Icon name="Checkmark" />
                          </span>
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Alert
                    message="The cookie statement, privacy statement and terms of use can be found at the footer of each page of the portal, please ensure you familiarise yourself with our policies before using the portal."
                    flavour="info"
                    hasBorder={false}
                  />
                  <div className="mt-5 items-start">
                    <SiteFooter leftAligned={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="min-h-75p w-full lg:w-6/12">
            <div className="flex h-full grow bg-[url('/public/intro-bg.jpg')] bg-cover bg-bottom"></div>
          </div>
        </div>
      </div>
    </>
  );
};
