import { useMsal } from '@azure/msal-react';
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

type MissingPortalMemberProps = {
  error?: string;
};

export const AuthErrorScreen: React.FC<MissingPortalMemberProps> = ({
  error,
}: MissingPortalMemberProps) => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const goBackToLoginModal = async () => {
    instance.logoutRedirect({ onRedirectNavigate: () => false });
  };

  // set text for auth error screen, specific case portal member is missing
  const errorTextPrefix =
    error === 'missing-portal-member'
      ? 'features:missing-portal-member'
      : 'features:auth-error';

  const heading = t(`${errorTextPrefix}:heading`);
  const text = t(`${errorTextPrefix}:description`);

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div className="w-200px h-200px">
        <Icon name="ContentError" />
      </div>
      <div className="max-w-550px mb-4 flex flex-col items-center text-center">
        <Heading text={heading} />
        <p className="whitespace-pre">{text}</p>
      </div>
      <Button
        label={t('features:auth-error:buttonText')}
        onClick={goBackToLoginModal}
      />
    </div>
  );
};
