import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { Modal } from 'components/Modal';
import {
  NO_PRINCIPAL_DEFINED_ERROR,
  Principals,
  redirectToSelectedPrincipal,
  usePrincipals,
} from 'hooks/queries';
import { t } from 'i18next';
import { ReactElement, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { storePrincipalIdSlugLocalStorage } from 'utils/localStorage.utils';

const redirectAndStorePrincipal = (
  userId: string,
  selectedPrincipalDropdownSelection: string,
  principals: Principals,
) => {
  storePrincipalIdSlugLocalStorage(userId, selectedPrincipalDropdownSelection);
  redirectToSelectedPrincipal(userId, principals);
};

type PrincipalFallbackProps = FallbackProps & {
  userId: string;
};

export const ErrorFallbackPrincipals = ({
  error,
  userId,
}: PrincipalFallbackProps): ReactElement => {
  //Handle only NO_PRINCIPAL_DEFINED_ERROR error, all other errors just pass
  if (error.message !== NO_PRINCIPAL_DEFINED_ERROR) throw error;
  const { principals } = usePrincipals();
  const [
    selectedPrincipalDropdownSelection,
    setSelectedPrincipalDropdownSelection,
  ] = useState<string>();

  const onModalConfirmHandler = () => {
    if (selectedPrincipalDropdownSelection) {
      redirectAndStorePrincipal(
        userId,
        selectedPrincipalDropdownSelection,
        principals,
      );
    }
  };

  //If only one principal is assigned to current user, set and store principal as current principal and redirect to it
  if (Object.keys(principals).length === 1) {
    setSelectedPrincipalDropdownSelection(Object.values(principals)[0].slug);
    onModalConfirmHandler();
  }

  const onPrincipalChangeHandler = (
    selectedOption: DropdownOptionProps | undefined,
  ) => {
    const principalSlug = (selectedOption as DropdownOptionProps)
      ?.value as string;
    if (principalSlug) setSelectedPrincipalDropdownSelection(principalSlug);
  };

  return (
    <Modal
      icon="AccountBehalf"
      heading={t('modals:changeCustomerSelection:prompt')}
      confirmText={t('modals:changeCustomerSelection:confirm')}
      onConfirm={onModalConfirmHandler}
      isHidden={false}
      isCancelButtonHidden={true}
      isCloseIconHidden={true}
    >
      <DropdownSingleSelect
        height="50vh--90"
        options={Object.keys(principals)
          .map((principal: string): DropdownOptionProps => {
            return {
              name: principals[principal]?.name || '',
              value: principals[principal]?.slug,
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name))}
        onChange={onPrincipalChangeHandler}
        widthVariant="full-width"
      />
    </Modal>
  );
};
