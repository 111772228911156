import { User } from 'firebase/auth';
import { GlobalUser, UserData } from 'utils/firebase/collection';

export enum UserActionType {
  SET_AUTH = 'user/SET_AUTH',
  SET_USER_DATA = 'user/SET_USER_DATA',
  SET_GLOBAL_USER = 'user/SET_GLOBAL_USER',
}

export type UserState = {
  auth: User | null;
  data: UserData | undefined;
  global: GlobalUser | undefined | null;
  error?: Error;
};
