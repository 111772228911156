import { useMemo } from 'react';
import { useCollection } from 'utils/firebase/hooks/useCollection';

export type UnitDefinitions = { [key in UnitType]?: { [key: string]: string } };

export type UnitsHookResult = {
  unitDefinitions: UnitDefinitions;
  currency: UnitNameAndValue[];
  weight: UnitNameAndValue[];
};

type UnitNameAndValue = { name: string; value: string };

export type UnitType = 'currency' | 'weight';

export const useUnits = (): UnitsHookResult => {
  const collection = useCollection('units');

  return useMemo(() => {
    //Temp hack should be fixed to make all dependancies work with undefined
    const unitDefinitions: UnitDefinitions = (collection || []).reduce(
      (acc: UnitDefinitions, curr) => ({
        ...acc,
        [curr.id]: curr.data.availableUnits,
      }),
      {} as UnitDefinitions,
    );

    const currency: UnitNameAndValue[] = [];
    const weight: UnitNameAndValue[] = [];

    if (unitDefinitions.currency) {
      for (const [key, value] of Object.entries(unitDefinitions.currency)) {
        currency.push({ name: value, value: key });
      }
    }

    if (unitDefinitions.weight) {
      for (const [key, value] of Object.entries(unitDefinitions.weight)) {
        weight.push({ name: value, value: key });
      }
    }
    return { unitDefinitions, weight, currency };
  }, [collection]);
};
