import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const BASE_TOGGLESWITCH_OUTER_CLASSES =
  'inline-block inline-flex items-center border select-none w-4 h-4 rounded shrink-0';
const DEFAULT_TOGGLESWITCH_OUTER_CLASSES = `${BASE_TOGGLESWITCH_OUTER_CLASSES} border-grey-500 bg-white hover:border-blue-100 focus:border-blue-100`;
const DISABLED_TOGGLESWITCH_OUTER_CLASSES = `${BASE_TOGGLESWITCH_OUTER_CLASSES} bg-grey-100 border-grey-200 cursor-default`;

export type ToggleSwitchProps = {
  /**
   * Checked status of the Toggle Switch
   */
  initialChecked?: boolean;
  /**
   * Label of the Toggle Switch
   */
  label?: string;
  /**
   * Values of the Toggle Switch
   */
  offValue?: string;
  onValue?: string;
  /**
   * Flag to disable Toggle Switch
   */
  disabled?: boolean;
  /**
   * Change function for Toggle Switch
   */
  onChange?: (isChecked: boolean) => void;
};

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  initialChecked,
  label,
  offValue,
  onValue,
  disabled,
  onChange,
}: ToggleSwitchProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!initialChecked);

  useEffect(() => setIsChecked(initialChecked || false), [initialChecked]);

  const changeToggleSwitch = () => {
    if (!disabled) {
      if (onChange) {
        // If there's a change event, let that handle things
        onChange(!isChecked);
      }
      setIsChecked((isChecked) => !isChecked);
    }
  };

  return (
    <div
      className={classNames('flex', {
        'cursor-default': disabled,
        'cursor-pointer': !disabled,
      })}
      tabIndex={-1}
    >
      <div
        data-testid="toggle-button"
        className="flex items-center justify-start"
        onClick={changeToggleSwitch}
      >
        {!!label && (
          <span
            className={classNames('mr-4 justify-center', {
              'text-grey-300': disabled,
              'text-grey-700 hover:text-blue-100': !disabled,
            })}
          >
            {label}
          </span>
        )}
        <button
          type="button"
          className={classNames(
            'relative inline-flex h-5 w-auto shrink-0 rounded border-2 duration-200 ease-in-out',
            {
              [disabled
                ? DISABLED_TOGGLESWITCH_OUTER_CLASSES
                : DEFAULT_TOGGLESWITCH_OUTER_CLASSES]: true,
            },
          )}
          role="switch"
          aria-checked={isChecked}
        >
          <span
            aria-hidden="true"
            className={classNames('h-5 w-7 rounded transition-transform', {
              'bg-grey-300': disabled,
              'bg-blue-100': !disabled,
              'translate-x-7': isChecked,
              'translate-x-0': !isChecked,
            })}
          />
          <span
            className={classNames('absolute z-10 w-7', {
              'text-grey-500': isChecked,
              'text-white': !isChecked,
            })}
          >
            {offValue ? offValue : 'Off'}
          </span>
          <span
            className={classNames('z-10 w-7', {
              'text-grey-500': !isChecked,
              'text-white': isChecked,
            })}
          >
            {onValue ? onValue : 'On'}
          </span>
        </button>
      </div>
    </div>
  );
};
