import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type TextareaProps = {
  /**
   * Placeholder of the input
   */
  placeholder?: string;
  /**
   * Flag to disable input
   */
  disabled?: boolean;
  /**
   * Flag for error
   */
  error?: boolean;
  /**
   * Rows of text area
   */
  rows?: number;
  /**
   * Test ID passed in for testing
   */
  dataTestId?: string;
  /**
   * Maximum length for input
   */
  maxLength?: number;
  /**
   * Change action of the input
   */
  onChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
  /**
   * Focus action of the input
   */
  onFocus?(): void;
  /**
   * Blur action of the input
   */
  onBlur?(): void;
  /**
   * On key down action of the input
   */
  onKeyDown?(): void;
  /**
   * On key down action of the input
   */
  onKeyUp?(e: KeyboardEvent): void;
  /**
   * Initial Value of textarea
   */
  initialValue?: string;
};

const BASE_TEXTAREA_CLASSES =
  'font-normal rounded p-2b border bg-white outline-none resize-y w-full h-auto';
const DEFAULT_TEXTAREA_CLASSES = `${BASE_TEXTAREA_CLASSES} border-grey-500 placeholder-grey-600 text-grey-700 hover:border-blue-100 focus:border-blue-100`;
const DISABLED_TEXTAREA_CLASSES = `${BASE_TEXTAREA_CLASSES} border-grey-200 placeholder-grey-400 text-grey-400`;
const ERROR_TEXTAREA_CLASSES = `${BASE_TEXTAREA_CLASSES} border-red-200 placeholder-grey-600 text-grey-700 hover:border-blue-100 focus:border-blue-100`;

export const Textarea: React.FC<TextareaProps> = ({
  placeholder,
  disabled,
  error,
  rows,
  dataTestId,
  maxLength,
  initialValue,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  onKeyUp,
}: TextareaProps) => {
  const className = useMemo(
    () =>
      disabled
        ? DISABLED_TEXTAREA_CLASSES
        : error
        ? ERROR_TEXTAREA_CLASSES
        : DEFAULT_TEXTAREA_CLASSES,
    [disabled, error],
  );

  const [value, setValue] = useState<string | undefined>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <textarea
      data-testid={dataTestId}
      className={className}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      maxLength={maxLength}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value);
        if (onChange && event) {
          onChange(event);
        }
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      rows={rows || 1}
    />
  );
};
