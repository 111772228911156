import { PowerBiReport } from 'components/PowerBiReport';
import { generateFilters } from 'lib/utils';
import React from 'react';
import { DaLocalizationConfig } from './DigitalActivitiesModal';

type CustomerPortalInsightsTabProps = {
  principal: string;
  filters?: DaLocalizationConfig;
};

export const CustomerPortalInsightsTab: React.FC<
  CustomerPortalInsightsTabProps
> = ({ principal, filters }) => {
  return (
    <PowerBiReport
      reportName="customer_insights"
      role={principal}
      filters={
        filters
          ? [
              generateFilters('Market Segment', 'Market Segment Code', [
                filters?.marketSegmentId,
              ]),
              generateFilters('Country', 'Country Code', [filters?.country]),
              generateFilters('Language', 'Language Code', [filters?.language]),
            ]
          : undefined
      }
    />
  );
};
