import { Icon, IconName } from 'components/Icon';
import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Heading } from 'components/Heading';
import { Link } from 'components/Link';
import { useTranslation } from 'react-i18next';

export type CountryListItem = {
  displayText: string;
  icon?: IconName;
};

export type CountryListProps = {
  header?: string;
  items: CountryListItem[];
  initialDisplayLimit?: number;
};

export const CountryList: React.FC<CountryListProps> = ({
  header,
  items,
  initialDisplayLimit,
}: CountryListProps) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState<boolean>(false);

  // Needed to force hiding on re-render
  useEffect(() => {
    if (items) {
      setShowAll(false);
    }
  }, [items]);

  return (
    <>
      {!!header && <Heading text={header} level="h4" margin={0} />}
      <ul data-testid="country-list-container">
        {items.map(({ displayText, icon }, index) => {
          const isHidden =
            initialDisplayLimit && index + 1 > initialDisplayLimit && !showAll;
          return (
            !isHidden && (
              <li
                className={classNames(`flex items-center`, {
                  'py-4': !icon,
                  'py-3': !!icon,
                  'border-grey-200 border-t': index !== 0,
                })}
                data-testid="country-list-item"
                key={index}
              >
                {!!icon && (
                  <div className="mr-3 w-7 grow-0" data-testid="icon-container">
                    <Icon name={icon} />
                  </div>
                )}
                <div className="flex-1">{displayText}</div>
              </li>
            )
          );
        })}
      </ul>
      {!!initialDisplayLimit && items.length > initialDisplayLimit && (
        // Flex class needed to top link spanning full width
        <div className="mt-3 flex">
          <Link
            label={t(`linksOrButtons:${showAll ? 'showLess' : 'showXMore'}`, {
              number: items.length - initialDisplayLimit,
            })}
            onClick={() => setShowAll(!showAll)}
          />
        </div>
      )}
    </>
  );
};
