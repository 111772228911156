import { useCallback, useMemo } from 'react';

import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

interface LocalizationCookieHandler {
  changeAppLanguage(): void;
}

export const useLocalizationCookieHandler: () => LocalizationCookieHandler =
  () => {
    const { i18n } = useTranslation();

    const changeAppLanguage = useCallback(() => {
      const localization = Cookies.get('localization');
      if (localization) {
        i18n.changeLanguage(localization);
      }
    }, [i18n]);

    const localizationCookieHandler = useMemo(
      () => ({
        changeAppLanguage,
      }),
      [changeAppLanguage],
    );

    return localizationCookieHandler;
  };
