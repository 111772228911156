import React, { ReactNode } from 'react';

type TableBodyProps = {
  children: ReactNode;
};

export const TableBody: React.FC<TableBodyProps> = ({
  children,
}: TableBodyProps) => {
  return <tbody>{children}</tbody>;
};
