import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getCRMExpansionOpportunities } from 'utils/firebase/cloud-functions';
import { MarketData } from '.';
import { ExpansionOpportunity as CRMExpansionOpportunity } from '../../../../functions/src/types/crm';

type FormattedValues = {
  createdby: string | undefined;
  labsupportresources: string | undefined;
  portfoliofit: string | undefined;
  salesteamstrength: string | undefined;
};

export type ExpansionOpportunity = CRMExpansionOpportunity &
  FormattedValues &
  MarketData;

const mapCRMData = (data: CRMExpansionOpportunity): ExpansionOpportunity => {
  return {
    ...data,
    portfolio1: data.aze_portfolio,
    portfolio2: data.aze_portfolio2,
    marketSegment: data.aze_marketsegmentid,
    createdby:
      data['_createdby_value@OData.Community.Display.V1.FormattedValue'],
    salesteamstrength:
      data['aze_salesteamstrength@OData.Community.Display.V1.FormattedValue'],
    portfoliofit:
      data['aze_portfoliofit@OData.Community.Display.V1.FormattedValue'],
    labsupportresources:
      data['aze_labsupportresources@OData.Community.Display.V1.FormattedValue'],
  };
};

export const useExpansionOpportunities = ({
  principalPri,
}: {
  principalPri: string;
}): UseQueryResult<ExpansionOpportunity[], Error> => {
  return useQuery({
    queryKey: ['expansionOpportunities', principalPri],
    queryFn: async () => await getCRMExpansionOpportunities({ principalPri }),
    select: ({ data }) => data.map(mapCRMData),
  });
};
