import classNames from 'classnames';
import { GeoPath, GeoProjection } from 'd3-geo';
import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';
import { GeoDataResponse, Geometry } from '../Map';

type PositionProps = {
  coordinates: [number, number];
  zoom: number;
};

type ComposableMapContainerProps = {
  geoData: GeoDataResponse | undefined;
  position: PositionProps;
  getColor: (currCountry: string) => string;
  handleGeographyClick: (
    geo: Geometry,
    projection: GeoProjection,
    path: GeoPath,
  ) => void;
  handleMoveEnd: (position: PositionProps) => void;
};
export const getPointerCursorAndColor = (
  geo: Geometry,
  getColor: (currCountry: string) => string,
): { pointerCursor: 'pointer' | 'default'; color: string } => {
  const color = getColor(geo.properties.ISO_A3);
  const isPointerCursor = color !== '#CFD3D7';
  return { pointerCursor: isPointerCursor ? 'pointer' : 'default', color };
};

export const ComposableMapContainer: React.FC<ComposableMapContainerProps> = ({
  geoData,
  getColor,
  handleGeographyClick,
  handleMoveEnd,
  position,
}) => {
  return (
    <ComposableMap
      projection="geoMercator"
      className="w-full truncate"
      height={537}
    >
      <ZoomableGroup
        zoom={position.zoom}
        center={position.coordinates}
        minZoom={0.8}
        maxZoom={8}
        onMoveEnd={handleMoveEnd}
      >
        <pattern
          id="pattern-stripe-CC"
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
        >
          <path
            d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
            style={{
              stroke: '#26BAAC',
              strokeWidth: 3,
            }}
          />
          <path
            d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2"
            style={{
              stroke: '#126261',
              strokeWidth: 1.5,
            }}
          />
        </pattern>
        <Geographies geography={geoData}>
          {({ geographies, projection, path }) =>
            geographies.map((geo) => {
              if (geo.properties.NAME === 'Antarctica') return null;
              return (
                <Geography
                  key={geo.rsmKey}
                  className={classNames({
                    'enter-country': geo.properties.ISO_A3 === 'IDN',
                  })}
                  geography={geo}
                  stroke="#EBF5FC"
                  fill={getPointerCursorAndColor(geo, getColor).color}
                  style={{
                    default: { outline: 'none' },
                    hover: {
                      outline: 'none',
                      cursor: getPointerCursorAndColor(geo, getColor)
                        .pointerCursor,
                    },
                    pressed: { outline: 'none' },
                  }}
                  onClick={() => handleGeographyClick(geo, projection, path)}
                />
              );
            })
          }
        </Geographies>
      </ZoomableGroup>
    </ComposableMap>
  );
};
