import { Disclosure } from '@headlessui/react';
import { Checkbox } from 'components/Checkbox';
import { Icon } from 'components/Icon';
import React from 'react';
import { useDropdownContext } from '../Dropdown';
import { DropdownOptionProps } from '../helper/DropdownTypes';
import {
  CreateNestedOptionMarkup,
  displayAsIntermediate,
  isOptionActive,
  isParentActive,
} from '../helper/helper';
import { DropdownItem } from './Item';

type NestedOptionMarkupProps = {
  option: DropdownOptionProps;
  activeOptionState: DropdownOptionProps[];
  onParentClickHandler: (option: DropdownOptionProps, active: boolean) => void;
  onClickHandler: (option: DropdownOptionProps) => void;
  expandOnInit?: boolean;
};

const filterOptionsByString = (
  options: DropdownOptionProps[],
  nameFilter: string,
): DropdownOptionProps[] => {
  return options.reduce(
    (filtered: DropdownOptionProps[], option: DropdownOptionProps) => {
      if (option.name?.toLowerCase().includes(nameFilter?.toLowerCase())) {
        filtered.push({
          ...option,
          childOptions: option.childOptions
            ? filterOptionsByString(option.childOptions, nameFilter)
            : undefined,
        });
      } else if (option.childOptions) {
        const filteredChildOptions = filterOptionsByString(
          option.childOptions,
          nameFilter,
        );

        if (filteredChildOptions.length > 0) {
          filtered.push({
            ...option,
            childOptions: filteredChildOptions,
          });
        }
      }

      return filtered;
    },
    [],
  );
};

export const NestedOptions = ({
  activeOptionState,
  onClickHandler,
  onParentClickHandler,
  options,
}: {
  activeOptionState: DropdownOptionProps[];
  onClickHandler: (option: DropdownOptionProps) => void;
  onParentClickHandler: (option: DropdownOptionProps, active: boolean) => void;
  options: DropdownOptionProps[];
}) => {
  const { typeAheadValue } = useDropdownContext();
  const filteredOptions = filterOptionsByString(options, typeAheadValue);

  return (
    <>
      {filteredOptions?.map((option: DropdownOptionProps, index: number) => (
        <NestedOptionMarkup
          key={index}
          option={option}
          activeOptionState={activeOptionState}
          onParentClickHandler={onParentClickHandler}
          onClickHandler={onClickHandler}
          expandOnInit={options.length === 1}
        />
      ))}
    </>
  );
};

export const NestedOptionMarkup: React.FC<NestedOptionMarkupProps> = ({
  option,
  activeOptionState,
  onParentClickHandler,
  onClickHandler,
  expandOnInit = false,
}) => {
  const isParentActiveValue = isParentActive(option, activeOptionState);
  const isOptionActiveValue = isOptionActive(option.value, activeOptionState);

  return (
    <Disclosure defaultOpen={expandOnInit}>
      {({ open }) => (
        <DropdownItem
          value={option.value}
          disabled={option.disabled}
          key={option.value}
          active={
            option.childOptions ? isParentActiveValue : isOptionActiveValue
          }
          name={option.name}
          closeOnSelect={false}
          isParent={!!option.childOptions}
        >
          <div className="flex items-center">
            {option.childOptions && option.childOptions.length > 0 ? (
              <Disclosure.Button
                data-testid="dropdown-disclosure"
                className="mr-1 block h-4 w-4"
              >
                <Icon name={open ? 'ArrowDown' : 'ArrowRight'} />
              </Disclosure.Button>
            ) : null}
            <Checkbox
              initialChecked={
                option.childOptions ? isParentActiveValue : isOptionActiveValue
              }
              label={option.name}
              disabled={option.disabled}
              onChange={
                option.childOptions
                  ? () => onParentClickHandler(option, isParentActiveValue)
                  : () => onClickHandler(option)
              }
              displayAsIntermediate={
                option.childOptions &&
                displayAsIntermediate(option, activeOptionState)
              }
            />
          </div>
          <Disclosure.Panel className="ml-3">
            {option.childOptions?.map((child: DropdownOptionProps) =>
              child.childOptions ? (
                <CreateNestedOptionMarkup
                  childProps={option}
                  key={`child-${option.value}`}
                >
                  <NestedOptionMarkup
                    option={option}
                    activeOptionState={activeOptionState}
                    onParentClickHandler={onParentClickHandler}
                    onClickHandler={onClickHandler}
                  />
                </CreateNestedOptionMarkup>
              ) : (
                <NestedOptionMarkup
                  option={child}
                  activeOptionState={activeOptionState}
                  onParentClickHandler={onParentClickHandler}
                  onClickHandler={onClickHandler}
                  key={`child-${option.value}-${child.value}`}
                />
              ),
            )}
          </Disclosure.Panel>
        </DropdownItem>
      )}
    </Disclosure>
  );
};
