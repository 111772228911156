export const CookiesTableData = [
  {
    name: '_fbp',
    type: 'Necessary',
    domain: '.azelis.com',
    storage_period: '3 months',
  },
  {
    name: '_hjAbsoluteSessionInProgress',
    type: 'Necessary',
    domain: '.azelis.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: '_gid',
    type: 'Necessary',
    domain: '.azelis.com',
    storage_period: '1 day',
  },
  {
    name: 'CookieControl',
    type: 'Necessary',
    domain: '.azelis.com',
    storage_period: '3 months',
  },
  {
    name: '_ga',
    type: 'Necessary',
    domain: '.azelis.com',
    storage_period: '2 years',
  },
  {
    name: 'Localization',
    type: 'Necessary',
    domain: '.azelis.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: 'clrc',
    type: 'Necessary',
    domain: 'login.microsoftonline.com',
    storage_period: '1 month',
  },
  {
    name: 'ESTSAUTHLIGHT',
    type: 'Necessary',
    domain: 'login.microsoftonline.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: 'ESTSSSOTILES',
    type: 'Necessary',
    domain: 'login.microsoftonline.com',
    storage_period: '10 years',
  },
  {
    name: 'buid',
    type: 'Necessary',
    domain: 'login.microsoftonline.com',
    storage_period: '1 month',
  },
  {
    name: 'SignInStateCookie',
    type: 'Necessary',
    domain: 'login.microsoftonline.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: 'AADSSOTILES',
    type: 'Necessary',
    domain: 'login.microsoftonline.com',
    storage_period: '10 years',
  },
  {
    name: 'ESTSSC',
    type: 'Necessary',
    domain: 'login.microsoftonline.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: 'x-ms-gateway-slice',
    type: 'Necessary',
    domain: 'login.microsoftonline.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: 'fpc',
    type: 'Necessary',
    domain: 'login.microsoftonline.com',
    storage_period: '1 month',
  },
  {
    name: 'stsservicecookie',
    type: 'Necessary',
    domain: 'login.microsoftonline.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: 'ARRAffinitySameSite',
    type: 'Necessary',
    domain: 'app.powerbi.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: 'ai_user',
    type: 'Necessary',
    domain: 'app.powerbi.com',
    storage_period: '1 year',
  },
  {
    name: 'PowerBISignedInFlag',
    type: 'Necessary',
    domain: 'app.powerbi.com',
    storage_period: '1 year',
  },
  {
    name: 'WFESessionId',
    type: 'Necessary',
    domain: 'app.powerbi.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: 'PreferredLanguage',
    type: 'Necessary',
    domain: 'app.powerbi.com',
    storage_period: '1 year',
  },
  {
    name: 'PowerBIUserSignedUp',
    type: 'Necessary',
    domain: 'app.powerbi.com',
    storage_period: '1 year',
  },
  {
    name: 'wlidperf',
    type: 'Necessary',
    domain: '.microsoftonline.com',
    storage_period: '1 year',
  },
  {
    name: 'mbox',
    type: 'Necessary',
    domain: '.microsoft.com',
    storage_period: '1 year',
  },
  {
    name: 'MUID',
    type: 'Necessary',
    domain: '.microsoft.com',
    storage_period: '1 year',
  },
  {
    name: '_mkto_trk',
    type: 'Necessary',
    domain: '.microsoft.com',
    storage_period: '1 year',
  },
  {
    name: 'LPVID',
    type: 'Necessary',
    domain: '.microsoft.com',
    storage_period: '1 year',
  },
  {
    name: '_ga',
    type: 'Necessary',
    domain: '.microsoft.com',
    storage_period: '3 months',
  },
  {
    name: 'MC1',
    type: 'Necessary',
    domain: '.microsoft.com',
    storage_period: '46 days',
  },
  {
    name: 'display-culture',
    type: 'Necessary',
    domain: '.microsoft.com',
    storage_period: '20 weeks',
  },
  {
    name: 'brcap',
    type: 'Necessary',
    domain: '.login.microsoftonline.com',
    storage_period: '1 year',
  },
  {
    name: 'ch',
    type: 'Necessary',
    domain: '.login.microsoftonline.com',
    storage_period: '60 days',
  },
  {
    name: 'ESTSAUTHPERSISTENT',
    type: 'Necessary',
    domain: '.login.microsoftonline.com',
    storage_period: '60 days',
  },
  {
    name: 'ESTSAUTH',
    type: 'Necessary',
    domain: '.login.microsoftonline.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: 'CCState',
    type: 'Necessary',
    domain: '.login.microsoftonline.com',
    storage_period: '4 weeks',
  },
  {
    name: 'esctx',
    type: 'Necessary',
    domain: '.login.microsoftonline.com',
    storage_period:
      'Session (removed after the browser session ends (when the browser is closed))',
  },
  {
    name: 'All Cookies with Prefix “MP_”',
    type: 'Analytical',
    domain: 'Mixpanel.com',
    storage_period: 'Max. 1 year',
  },
];
