import { Checkbox } from 'components/Checkbox';
import { Link } from 'components/Link';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { ToggleUsersToDeleteFunc } from 'features/PortalMembers/PortalMembers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserData } from 'utils/firebase/collection/firebase-collection-types';
import { PortalMemberName } from '../PortalMembers/PortalMemberName';

type GlobalUserTableRowProps = {
  user: UserData;
  selectedUser: UserData | undefined;
  userIdsToDelete?: string[];
  isExistingGlobalUsersTable?: boolean;
  handleSelect: (user: UserData) => void;
  toggleUsersToDelete?: ToggleUsersToDeleteFunc;
  toggleEditPrincipalsModal?: () => void;
};

export const GlobalUserTableRow: React.FC<GlobalUserTableRowProps> = ({
  user,
  selectedUser,
  isExistingGlobalUsersTable,
  userIdsToDelete,
  handleSelect,
  toggleUsersToDelete,
  toggleEditPrincipalsModal,
}: GlobalUserTableRowProps) => {
  const { t } = useTranslation();

  const userIsChecked = userIdsToDelete?.includes(user.id);

  return (
    <TableRow
      key={user.id}
      hoverable
      onClick={() => {
        handleSelect(user);
      }}
      selected={user.name === selectedUser?.name || userIsChecked}
    >
      {isExistingGlobalUsersTable && (
        <TableCell smallHeaderTable>
          <Checkbox
            onChange={(isChecked) =>
              toggleUsersToDelete && toggleUsersToDelete([user.id], isChecked)
            }
            initialChecked={userIsChecked}
          />
        </TableCell>
      )}
      <TableCell>
        <PortalMemberName name={user.name} email={user.email?.toLowerCase()} />
      </TableCell>
      {isExistingGlobalUsersTable && (
        <TableCell justify="end">
          <Link
            onClick={(event) => {
              event.preventDefault();
              toggleEditPrincipalsModal && toggleEditPrincipalsModal();
            }}
            label={t('features:global-users:editGlobalUser')}
            icon="Edit"
            iconPosition="left"
          />
        </TableCell>
      )}
    </TableRow>
  );
};
