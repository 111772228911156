import React, { useEffect, useMemo, useState } from 'react';

import { Icon } from 'components/Icon';
import { Link } from 'components/Link';
import { useEnvironment } from 'hooks/useEnviornment';
import { useTranslation } from 'react-i18next';

type ContactDetails = {
  name: string | undefined;
  email: string | undefined;
};

export type CountryContactProps = {
  details: ContactDetails;
};

export const CountryContact: React.FC<CountryContactProps> = ({
  details,
}: CountryContactProps) => {
  const { t } = useTranslation();
  const { name = t('labels:unknown'), email } = details;
  const envi = useEnvironment();
  const [defaultImage, setDefaultImage] = useState(false);

  const image = useMemo(() => {
    return (
      <img
        src={`https://firebasestorage.googleapis.com/v0/b/azelis-principal-portal-${envi}.appspot.com/o/marketDirector-images%2F${name
          ?.replace(/\s/g, '')
          .replace('-', '')
          .toLocaleLowerCase()}.jpg?alt=media`}
        data-testid="contact-image"
        alt={name}
        onError={() => {
          setDefaultImage(true);
        }}
      />
    );
  }, [envi, name]);

  useEffect(() => {
    setDefaultImage(false);
  }, [name]);

  return (
    <div className="flex">
      <div className="bg-grey-100 mr-4 h-8 w-8 overflow-hidden rounded-full">
        {defaultImage ? (
          <div className="text-grey-500 p-3" data-testid="no-image">
            <Icon name="Account" />
          </div>
        ) : (
          image
        )}
      </div>
      <div>
        <div className="text-grey-700 font-medium">{name}</div>
        <div className="text-grey-600">
          {t('features:dashboard:map:managingDirector')}
        </div>
        {!!email && (
          <div className="mt-1 flex">
            <Link href={`mailto:${email}`} label={email} icon="Mail" />
          </div>
        )}
      </div>
    </div>
  );
};
