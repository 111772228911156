import { Modal } from 'components/Modal';
import { RadioButtonProps } from 'components/RadioButton/RadioButton';
import { RadioButtonGroup } from 'components/RadioButtonGroup/RadioButtonGroup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from 'shared/types/authorization';

type SelectUserTypeModalProps = {
  onConfirm: (userType: UserType) => void;
  onCancel: () => void;
};

export const SelectUserTypeModal: React.FC<SelectUserTypeModalProps> = ({
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [userType, setUserType] = useState<UserType>();

  const userTypeRadioOptions: RadioButtonProps[] = [
    {
      label: t('modals:selectUserTypeModal:labels:internalOption'),
      value: UserType.INTERNAL,
    },
    {
      label: t('modals:selectUserTypeModal:labels:externalOption'),
      value: UserType.EXTERNAL,
    },
  ];

  return (
    <Modal
      headingAlign="left"
      isCancelButtonHidden
      heading={t('modals:selectUserTypeModal:heading')}
      confirmText={t('linksOrButtons:next')}
      onCancel={onCancel}
      isBig
      onConfirm={() => onConfirm(userType as UserType)}
      isConfirmButtonDisabled={!userType}
      isConfirmButtonAlignedRight
    >
      <form>
        <div className="mb-3 mt-6">
          <RadioButtonGroup
            items={userTypeRadioOptions}
            groupName="userTypeSelector"
            hasBorder
            doOptionsFillSpace
            onChange={(val) => setUserType(val as UserType)}
          />
        </div>
      </form>
    </Modal>
  );
};
