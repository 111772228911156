import { ExpansionOpportunity } from 'components/ExpansionOpportunity';
import { Loading } from 'components/Loading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExpansionOpportunity as ExpansionOpportunityType,
  useCurrentPrincipal,
  useCurrentUser,
  useExpansionOpportunities,
} from '../../hooks/queries';
import { EmptyState } from '../EmptyState';
import { Map } from '../Map/Map';

export const ExpansionOpportunitiesWithMap: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(user.id);

  const { data: expansionOpportunities } = useExpansionOpportunities({
    principalPri: currentPrincipal.principalPri,
  });

  const [countries, setCountries] = useState<string[]>([]);

  useEffect(() => {
    const tempCountries: string[] =
      expansionOpportunities?.map(
        ({ aze_country }) => aze_country.aze_isocode ?? '',
      ) || [];
    if (JSON.stringify(countries) !== JSON.stringify(tempCountries)) {
      setCountries(tempCountries);
    }
  }, [countries, expansionOpportunities]);

  const isLoading = !expansionOpportunities;
  const hasExpansionOpportunities =
    expansionOpportunities && expansionOpportunities.length > 0;

  return (
    <Loading loading={isLoading}>
      <div className="xl:max-w-xl-content flex grow flex-wrap">
        <div className="mb-4 flex w-full flex-wrap md:mb-5">
          {hasExpansionOpportunities ? (
            <>
              <div className="xs:w-6/12">
                {expansionOpportunities.map(
                  (entry: ExpansionOpportunityType, index: number) => (
                    <ExpansionOpportunity
                      country={entry.aze_country?.aze_name ?? undefined}
                      date={entry.createdon}
                      rationale={entry.aze_principalportalrationale ?? ''}
                      principalPortfolio={entry.aze_portfolio?.aze_name ?? ''}
                      portfolioFit={+(entry.portfoliofit ?? 0)}
                      salesTeamStrength={+(entry.salesteamstrength ?? 0)}
                      labSupportResources={+(entry.labsupportresources ?? 0)}
                      key={index}
                      marketSegmentName={entry.aze_marketsegmentid?.aze_name}
                    />
                  ),
                )}
              </div>
              <div className="xs:px-4 xs:w-6/12 w-full justify-center">
                {!!countries.length && (
                  <Map
                    opportunity={true}
                    countries={countries.map((country) => ({
                      name: country,
                      colorGroup: {
                        name: 'Expansion Opportunity',
                        color: 'bg-purple-100',
                      },
                    }))}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="mt-4 w-full md:px-3">
              <EmptyState
                message={t(
                  'features:market-intelligence:expansionOpportunities:empty',
                )}
                hasBorder
              />
            </div>
          )}
        </div>
      </div>
    </Loading>
  );
};
