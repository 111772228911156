import { Disclosure } from '@headlessui/react';
import { Checkbox } from 'components/Checkbox';
import { Icon } from 'components/Icon';
import { LoadingBounce } from 'components/Loading';
import { useCRMStatesData } from 'hooks/queries/useCRMStateData';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countriesMappedByRegion } from 'utils/countries';
import { MappedMandates, MappedPortfolio } from 'utils/firebase';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';

export type SegmentName = 'portfolio' | 'country' | 'marketSegment' | 'state';

export type SelectedMandates = {
  [key in SegmentName]: string[];
};

export type MandateSelectionFilterProps = {
  [key: string]: {
    hasAllMandates?: boolean;
    portfolio: string[];
    country: string[];
    marketSegment: string[];
    state: string[];
  };
};

export type MandateSelectionProps = {
  mandates?: MappedMandates;
  handleMandateFilterChange?: (selectedMandates: SelectedMandates) => void;
  initMandateFilters?: MandateSelectionFilterProps;
  selectedPrincipal: Principal;
  isGlobalUser?: boolean;
  disabled?: boolean;
};

export const MandateSelection: React.FC<MandateSelectionProps> = ({
  mandates,
  handleMandateFilterChange,
  initMandateFilters,
  selectedPrincipal,
  isGlobalUser = false,
  disabled,
}) => {
  const { t } = useTranslation();

  // ALL DATA LOGIC
  const availableMarketSegments = mandates?.marketSegments ?? [];

  const availablePortfolios = mandates?.portfolios ?? [];

  const availableCountries =
    mandates?.countries.map((country) => ({
      ISO_A2: '',
      ISO_A3: country.iso,
      name: country.name,
    })) ?? [];

  const usaStates = useCRMStatesData();

  const availableUSAStates = usaStates.map(({ id }) => id);

  const generateSelectedMandates = (
    filters: MandateSelectionFilterProps | undefined,
    selectedPrincipalId: string,
  ) => ({
    portfolio: filters?.[selectedPrincipalId]?.portfolio || [],
    country: filters?.[selectedPrincipalId]?.country || [],
    marketSegment: filters?.[selectedPrincipalId]?.marketSegment || [],
    state: filters?.[selectedPrincipalId]?.state || [],
  });

  const initSelectedMandates = useMemo(
    () => generateSelectedMandates(initMandateFilters, selectedPrincipal.id),
    [initMandateFilters, selectedPrincipal],
  );

  // CHECKBOX LOGIC
  const [sortCheckedBoxes, setSortCheckedBoxes] =
    useState<SelectedMandates>(initSelectedMandates);

  const hasAllMandates =
    isGlobalUser || initMandateFilters?.[selectedPrincipal.id]?.hasAllMandates;

  const handleSingleCheckboxChange = (value: string, segment: SegmentName) => {
    const checkedValues = sortCheckedBoxes[segment];
    const newCheckboxValues = {
      ...sortCheckedBoxes, // Deep copy not working
      [segment]: [...checkedValues].includes(value)
        ? [...checkedValues].filter((str) => str !== value)
        : [...checkedValues, value],
    };

    setSortCheckedBoxes(newCheckboxValues);
    handleMandateFilterChange?.(newCheckboxValues);
  };

  const handleSingleStateCheckboxChange = (value: string) => {
    const checkedValues = sortCheckedBoxes.state;

    let newCheckboxValues;

    newCheckboxValues = {
      ...sortCheckedBoxes, // Deep copy not working
      state: [...checkedValues].includes(value)
        ? [...checkedValues].filter((str) => str !== value)
        : [...checkedValues, value],
    };

    const hasAllStatesSelected = (updatedValues: {
      state: string[];
      portfolio: string[];
      country: string[];
      marketSegment: string[];
    }) =>
      availableUSAStates.every((state) => updatedValues.state.includes(state));

    if (hasAllStatesSelected(newCheckboxValues)) {
      newCheckboxValues = {
        ...newCheckboxValues,
        country: [...newCheckboxValues.country, 'USA'],
      };
    }

    setSortCheckedBoxes(newCheckboxValues);
    handleMandateFilterChange?.(newCheckboxValues);
  };

  const handleAllCheckboxChange = (
    val: string[],
    isAllChecked: boolean,
    segment: string,
  ) => {
    const newCheckboxValues = {
      ...sortCheckedBoxes,
      [segment]: isAllChecked ? [...val] : [],
    };

    setSortCheckedBoxes(newCheckboxValues);
    handleMandateFilterChange?.(newCheckboxValues);
  };

  useEffect(() => {
    setSortCheckedBoxes(initSelectedMandates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAllMandates, initMandateFilters]);

  const handleAllPortfolioChange = (
    portfolios: MappedPortfolio[],
    isAllChecked: boolean,
    segment: string,
  ) => {
    const portfolioIds = portfolios.map(({ id }) => id);
    const newCheckboxValues = {
      ...sortCheckedBoxes,
      [segment]: isAllChecked ? [...portfolioIds] : [],
    };

    setSortCheckedBoxes(newCheckboxValues);
    handleMandateFilterChange?.(newCheckboxValues);
  };

  const regions = countriesMappedByRegion(availableCountries);

  const handleAllRegionsCheckboxChange = (
    region: string,
    isAllChecked: boolean,
  ) => {
    const selectedCountries = sortCheckedBoxes.country;

    const countriesBySelectedRegion = regions[region].map(
      ({ ISO_A3 }) => ISO_A3,
    );

    const updatedCountries = selectedCountries.filter(
      (country) => !countriesBySelectedRegion.includes(country),
    );

    const selectedRegionIsAmericas = region === 'AMERICAS';

    const newCheckboxValues = {
      ...sortCheckedBoxes,
      country: isAllChecked
        ? [...countriesBySelectedRegion, ...selectedCountries]
        : [...updatedCountries],
      ...(selectedRegionIsAmericas && {
        state: isAllChecked ? [...availableUSAStates] : [],
      }),
    };

    setSortCheckedBoxes(newCheckboxValues);
    handleMandateFilterChange?.(newCheckboxValues);
  };

  const hasAllCountriesSelected = (region: string) => {
    const selectedCountries = sortCheckedBoxes.country;

    const countriesBySelectedRegion = regions[region].map(
      ({ ISO_A3 }) => ISO_A3,
    );

    return countriesBySelectedRegion.every((country) =>
      selectedCountries.includes(country),
    );
  };

  const hasSomeCountriesSelected = (region: string) => {
    const selectedCountries = sortCheckedBoxes.country;

    const countriesBySelectedRegion = regions[region].map(
      ({ ISO_A3 }) => ISO_A3,
    );

    const hasAllSelected = hasAllCountriesSelected(region);

    return (
      !hasAllSelected &&
      countriesBySelectedRegion.some((country) =>
        selectedCountries.includes(country),
      )
    );
  };

  const displayUSAsIntermediate = () => {
    const selectedStates = sortCheckedBoxes.state;

    return (
      !!selectedStates.length &&
      !availableUSAStates.every((state) => selectedStates.includes(state))
    );
  };

  const displayRegionAsIntermediate = (region: string): boolean => {
    let hasSomeSelected;

    if (region === 'AMERICAS') {
      const usaAsIntermediate = displayUSAsIntermediate();

      hasSomeSelected = usaAsIntermediate || hasSomeCountriesSelected(region);
    } else {
      hasSomeSelected = hasSomeCountriesSelected(region);
    }

    return hasSomeSelected;
  };

  const handleUSACheckboxChange = () => {
    handleSingleCheckboxChange('USA', 'country');

    const checkedValues = sortCheckedBoxes.country;
    const newCheckboxValues = {
      ...sortCheckedBoxes,
      country: [...checkedValues].includes('USA')
        ? [...checkedValues].filter((str) => str !== 'USA')
        : [...checkedValues, 'USA'],
      state: [...checkedValues].includes('USA') ? [] : [...availableUSAStates],
    };

    setSortCheckedBoxes(newCheckboxValues);
    handleMandateFilterChange?.(newCheckboxValues);
  };

  return (
    <div className="grid grid-flow-row grid-cols-3 gap-4">
      <div
        data-testid="portfolio-checkboxes"
        className="bg-grey-100 max-w-408px col-span-1 rounded p-2"
      >
        <h2 className="text-grey-700 mb-3 font-medium">
          {t('modals:addUserModal:portfolio')}
        </h2>
        {availablePortfolios.length > 0 ? (
          <>
            <Checkbox
              displayAsIntermediate={
                availablePortfolios.length !==
                  sortCheckedBoxes.portfolio.length &&
                sortCheckedBoxes.portfolio.length !== 0
              }
              label={t('modals:addUserModal:all')}
              onChange={(isAllChecked) => {
                handleAllPortfolioChange(
                  availablePortfolios,
                  isAllChecked,
                  'portfolio',
                );
              }}
              initialChecked={
                hasAllMandates ||
                sortCheckedBoxes.portfolio.length === availablePortfolios.length
              }
              disabledVisibleLabel={hasAllMandates || disabled}
            />

            {availablePortfolios.map((portfolio) => (
              <div className="my-1" key={`${portfolio.name}${portfolio.id}`}>
                <Checkbox
                  initialChecked={
                    hasAllMandates
                      ? true
                      : !!sortCheckedBoxes.portfolio?.find(
                          (option) => option === portfolio.id,
                        )
                  }
                  label={portfolio.name}
                  onChange={() =>
                    handleSingleCheckboxChange(
                      portfolio.id as string,
                      'portfolio',
                    )
                  }
                  disabledVisibleLabel={hasAllMandates || disabled}
                />
              </div>
            ))}
          </>
        ) : (
          <LoadingBounce />
        )}
      </div>
      <div
        data-testid="countries-checkboxes"
        className="bg-grey-100 max-w-408px col-span-1 rounded p-2"
      >
        <h2 className="text-grey-700 mb-3 font-medium">
          {t('modals:addUserModal:country')}
        </h2>

        {Object.keys(regions).length > 0 ? (
          Object.entries(regions).map(([region, countries]) => {
            if (!countries.length) return null;

            return (
              <Disclosure key={region}>
                {({ open }) => (
                  <div className="mb-1">
                    <div className="flex items-center">
                      <Disclosure.Button className="mr-1 block h-4 w-4">
                        <Icon name={open ? 'ArrowDown' : 'ArrowRight'} />
                      </Disclosure.Button>
                      <Checkbox
                        initialChecked={
                          hasAllMandates
                            ? true
                            : hasAllCountriesSelected(region)
                        }
                        label={region}
                        onChange={(isAllChecked) =>
                          handleAllRegionsCheckboxChange(region, isAllChecked)
                        }
                        disabledVisibleLabel={hasAllMandates || disabled}
                        displayAsIntermediate={displayRegionAsIntermediate(
                          region,
                        )}
                      />
                    </div>
                    <Disclosure.Panel className="mt-1 space-y-1">
                      {countries.map(({ ISO_A3, name }) => {
                        if (ISO_A3 === 'USA')
                          return (
                            <Disclosure key={ISO_A3}>
                              {({ open }) => (
                                <div className="mb-1">
                                  <div className="flex items-center">
                                    <Disclosure.Button className="mr-1 block h-4 w-4">
                                      <Icon
                                        name={open ? 'ArrowDown' : 'ArrowRight'}
                                      />
                                    </Disclosure.Button>
                                    <Checkbox
                                      initialChecked={
                                        hasAllMandates
                                          ? true
                                          : !!sortCheckedBoxes.country?.find(
                                              (option) => option === ISO_A3,
                                            )
                                      }
                                      label={name}
                                      onChange={handleUSACheckboxChange}
                                      displayAsIntermediate={displayUSAsIntermediate()}
                                      disabledVisibleLabel={
                                        hasAllMandates || disabled
                                      }
                                    />
                                  </div>
                                  <Disclosure.Panel className="mt-1 space-y-1">
                                    {usaStates?.map((state) => (
                                      <div className="ml-5" key={state.id}>
                                        <Checkbox
                                          initialChecked={
                                            hasAllMandates
                                              ? true
                                              : !!sortCheckedBoxes.state?.find(
                                                  (option) =>
                                                    option === state.id,
                                                )
                                          }
                                          label={state.label}
                                          onChange={() =>
                                            handleSingleStateCheckboxChange(
                                              state.id,
                                            )
                                          }
                                          disabledVisibleLabel={
                                            hasAllMandates || disabled
                                          }
                                        />
                                      </div>
                                    ))}
                                  </Disclosure.Panel>
                                </div>
                              )}
                            </Disclosure>
                          );

                        return (
                          <div className="ml-5" key={ISO_A3}>
                            <Checkbox
                              initialChecked={
                                hasAllMandates
                                  ? true
                                  : !!sortCheckedBoxes.country?.find(
                                      (option) => option === ISO_A3,
                                    )
                              }
                              label={name}
                              onChange={() =>
                                handleSingleCheckboxChange(ISO_A3, 'country')
                              }
                              disabledVisibleLabel={hasAllMandates || disabled}
                            />
                          </div>
                        );
                      })}
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            );
          })
        ) : (
          <LoadingBounce />
        )}
      </div>
      <div
        data-testid="marketSegment-checkboxes"
        className="bg-grey-100 max-w-408px col-span-1 rounded p-2"
      >
        <h2 className="text-grey-700 mb-3 font-medium">
          {t('modals:addUserModal:marketSegment')}
        </h2>

        {availableMarketSegments.length > 0 ? (
          <>
            <Checkbox
              displayAsIntermediate={
                availableMarketSegments.length !==
                  sortCheckedBoxes.marketSegment.length &&
                sortCheckedBoxes.marketSegment.length !== 0
              }
              label={t('modals:addUserModal:all')}
              onChange={(isAllChecked) => {
                handleAllCheckboxChange(
                  availableMarketSegments.map((val) => {
                    return typeof val === 'string' ? val : val.id;
                  }),
                  isAllChecked,
                  'marketSegment',
                );
              }}
              initialChecked={
                hasAllMandates ||
                sortCheckedBoxes.marketSegment.length ===
                  availableMarketSegments.length
              }
              disabledVisibleLabel={hasAllMandates || disabled}
            />

            {availableMarketSegments.map((val) => (
              <div className="my-1" key={val.id}>
                <Checkbox
                  initialChecked={
                    hasAllMandates
                      ? true
                      : !!sortCheckedBoxes.marketSegment?.find(
                          (option) => option === val.id,
                        )
                  }
                  label={val.name}
                  onChange={() =>
                    handleSingleCheckboxChange(val.id, 'marketSegment')
                  }
                  disabledVisibleLabel={hasAllMandates || disabled}
                />
              </div>
            ))}
          </>
        ) : (
          <LoadingBounce />
        )}
      </div>
    </div>
  );
};
