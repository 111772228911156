import { Reducer } from '@reduxjs/toolkit';
import { PrincipalsAction } from './principals.action';
import { PrincipalsActionType, PrincipalsState } from './principals.types';

const INITIAL_STATE: PrincipalsState = {
  data: undefined,
};

export const principalsReducer: Reducer<PrincipalsState, PrincipalsAction> = (
  state = INITIAL_STATE,
  action,
): PrincipalsState => {
  const { type, payload } = action;

  switch (type) {
    case PrincipalsActionType.SET_PRINCIPALS_DATA:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
};
