import { combineReducers } from '@reduxjs/toolkit';
import { principalsReducer } from './principals/principals.reducer';
import { sharepointReducer } from './sharepoint/sharepoint.reducer';
import { userReducer } from './user/user.reducer';

export const rootReducer = combineReducers({
  sharepoint: sharepointReducer,
  user: userReducer,
  principals: principalsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
