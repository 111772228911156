import { Principals } from 'hooks/queries';

export enum PrincipalsActionType {
  SET_PRINCIPALS_DATA = 'principals/SET_PRINCIPALS_DATA',
}

export type PrincipalsState = {
  data: Principals | undefined;
  error?: Error;
};
