import classNames from 'classnames';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { ToggleSwitch } from 'components/ToggleSwitch';
import React, { useCallback } from 'react';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';

type PrincipalTableRowProps = {
  principal: Principal;
  initalRoleSelection: string;
  key?: number;
  onChange: (isChecked: boolean, principal: Principal) => void;
};

export const PrincipalTableRow: React.FC<PrincipalTableRowProps> = ({
  principal,
  initalRoleSelection,
  onChange,
}: PrincipalTableRowProps) => {
  const handleToggleSwitchChange = useCallback(
    (isChecked: boolean) => {
      onChange(isChecked, principal);
    },
    [onChange, principal],
  );

  return (
    <TableRow key={principal.id} hoverable>
      <TableCell smallTable>
        <div className="mt-1 inline-flex flex-row items-center">
          <img
            src={principal.logoUrl}
            className={classNames('h-40px mr-5', {
              'pr-40px': !principal.logoUrl,
            })}
            alt=""
            title={`${principal.name} icon`}
          />

          <div className="truncate whitespace-nowrap">{principal.name}</div>
        </div>
      </TableCell>
      <TableCell smallTable justify="end">
        <ToggleSwitch
          offValue="View"
          onValue="Edit"
          initialChecked={
            initalRoleSelection === 'global_user_edit' ? true : false
          }
          onChange={handleToggleSwitchChange}
        />
      </TableCell>
    </TableRow>
  );
};
