import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

export const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (window && window.scrollTo) {
      window.scrollTo(0, 0);
    }
    const mainContent = document.getElementById('main-content');
    if (mainContent && mainContent.scrollTo) {
      mainContent.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};
