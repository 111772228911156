export enum LocalStorageKeys {
  POWER_BI_REPORT_TOKENS = 'power_bi_report_tokens',
  DIGITAL_ACTIVITIES = 'digital-activities',
}

export const addToLocalStorage = (
  key: LocalStorageKeys,
  value: unknown,
): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = <ReturnType>(
  key: LocalStorageKeys,
): ReturnType | null => {
  const result = localStorage.getItem(key);
  if (result) {
    return JSON.parse(result);
  }
  return null;
};

export const deleteFromLocalStorage = (key: LocalStorageKeys): void => {
  localStorage.removeItem(key);
};

export const clearCustomKeysFromLocalStorage = (): void => {
  for (const value of Object.values(LocalStorageKeys)) {
    localStorage.removeItem(value);
  }
};
