import { Link } from 'components/Link';
import React from 'react';
import { DropdownOptionProps } from '../helper/DropdownTypes';

export type DropdownButtonBarProps = {
  buttons: SelectionButton[];
};

export type SelectionButton = RequireAtLeastOne<T> & {
  label: string;
  onClick?: () => void;
};

type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> &
    Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];
type T = {
  customSelection?: DropdownOptionProps[];
  disabled: boolean;
};

export const ButtonBar: React.FC<DropdownButtonBarProps> = ({ buttons }) => {
  const delimiter = buttons.length - 1;
  return (
    <div className="flex px-2">
      {buttons.map((button, index) => (
        <div key={index} className="flex">
          <Link {...button} />{' '}
          {index !== delimiter && <span className="px-1">/</span>}
        </div>
      ))}
    </div>
  );
};
