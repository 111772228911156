import { Timestamp } from 'firebase/firestore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateMarketIntelligenceEntryLink } from 'utils/firebase/cloud-functions';
import { MarketInsight as MarketInsightType } from 'utils/firebase/collection';
import { Divider } from '../Divider';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { IconName } from '../Icon/Icon';
import { Link } from '../Link';

export type MarketInsightProps = {
  entry?: MarketInsightType;
  compact?: boolean;
  divider?: boolean;
  categories: string[];
  countries: string[];
  region: string | null;
  marketSegment: string;
  content: string;
  link?: {
    href?: string;
    label?: string;
  };
  publicationDate?: Timestamp;
  title: string;
};

export const MarketInsight: React.FC<MarketInsightProps> = ({
  compact = false,
  entry,
  divider = true,
  title = 'Demo Headline',
  publicationDate,
  content = 'Dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore eirmod magna sed amet sadipscing',
  link,
  categories = ['#Trend', '#PCUK'],
  countries = [],
  region,
  marketSegment = 'All',
}: MarketInsightProps) => {
  const { t } = useTranslation();
  const mapMarketSegmentToIcon = (segement: string): IconName => {
    switch (segement) {
      case 'CASE':
        return 'CaseSolid';
      case 'Rubber & PA':
        return 'RubberAndPlasticAdditivesSolid';
      case 'Personal Care':
        return 'PersonalCareSolid';
      case 'Pharma':
        return 'PharmaSolid';
      case 'Food & Health':
        return 'FoodAndHealthSolid';
      case 'Animal Nutrition':
        return 'AnimalNutritionSolid';
      case 'Specialty Agri Horti':
        return 'SpecialityAgriHortiSolid';
      case 'Homecare & Industrial Cleaning':
        return 'HomecareAndIndustrialCleaningSolid';
      case 'Lubricants & MWF':
        return 'LubricantsAndMetalWorkingFluidsSolid';
      case 'Essential Chemicals':
        return 'EssentialChemicalsSolid';
      case 'Electronis':
        return 'ElectronicsSolid';
      case 'Fine Chemicals':
        return 'FineChemicalsSolid';
      case 'Textiles Leather & Paper':
        return 'TextilesLeatherAndPaperSolid';
      case 'Other Applications':
        return 'OtherApplicationsSolid';
      default:
        return 'GlobalSolid';
    }
  };

  /**
   * Returns a compacted representation of the content
   * @param string
   * @returns string limited to x lines and y characters
   */
  const compactContent = (string: string): string => {
    const fourLines = string
      .match(/[^\r\n]+/g)
      ?.slice(0, 4)
      .join('\n');
    if (fourLines && fourLines.length > 200) {
      return content.substring(0, 200) + ' ...';
    }
    return fourLines ?? '';
  };

  const onClickLink = async ({
    entry,
    type,
  }: {
    entry?: MarketInsightType;
    type: 'email' | 'link';
  }) => {
    if (!entry) return;

    const response = await generateMarketIntelligenceEntryLink({
      entry,
      type,
      text: {
        intro: t('features:market-intelligence:marketInsights:email:intro'),
        outro: t('features:market-intelligence:marketInsights:email:outro'),
        subject: t('features:market-intelligence:marketInsights:email:subject'),
      },
    });

    window.open(response.data, '_blank', 'noopener, noreferrer');
  };

  const isGlobal = !region && !countries.length;

  return (
    <div className="xl:max-w-xl-content flex grow flex-col">
      <div className="xl:max-w-xl-content flex">
        <div
          className={`xs:justify-start flex w-full md:w-3/12 ${
            compact ? 'max-w-48px lg:w-2/12' : 'max-w-80px lg:w-3/12'
          }`}
        >
          <div className={`w-full ${compact ? 'h-7b' : 'h-80px'}`}>
            <Icon inline={true} name={mapMarketSegmentToIcon(marketSegment)} />
          </div>
        </div>
        <div
          className={`flex grow pl-2 ${
            compact
              ? 'w-full md:w-9/12 lg:w-10/12'
              : 'w-9/12 items-end lg:w-9/12'
          }`}
        >
          <div className="xl:max-w-xl-content flex grow flex-col">
            <div className="w-full">
              <Heading
                text={title}
                margin={0}
                level={compact ? 'h6' : 'h3'}
                truncate={compact}
              />
            </div>
            <div className="w-full">
              <span className={`text-grey-600 ${compact ? 'text-2xs' : ''}`}>
                {publicationDate?.toDate?.().toLocaleDateString()}
              </span>
            </div>
          </div>
          {!compact && (
            <div className="flex h-full shrink-0">
              {link?.href && (
                <button
                  onClick={() =>
                    onClickLink({
                      entry,
                      type: 'email',
                    })
                  }
                  className="ml-2 h-4 w-4 cursor-pointer"
                >
                  <Icon
                    name="Share"
                    tooltip={t(
                      'features:market-intelligence:marketInsights:share',
                    )}
                  />
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="w-full">
        <div
          className={`whitespace-pre-line ${
            compact ? 'text-2xs my-1' : 'my-3 '
          }`}
        >
          {compact ? compactContent(content) : content}
        </div>
      </div>
      {link?.href && (
        <div className="w-full">
          <div className={`${compact ? '' : 'mb-4 mt-1'}`}>
            <Link
              icon="ExternalLink"
              onClick={() =>
                onClickLink({
                  entry,
                  type: 'link',
                })
              }
              label={link.label}
              target="_blank"
              variant={compact ? 'compact' : 'default'}
            />
          </div>
        </div>
      )}
      <div className="w-full">
        <div className="text-grey-700 mb-3 text-xs">
          {countries.length === 1 && !region
            ? t('features:market-intelligence:marketInsights:regionLabel')
            : t('features:market-intelligence:marketInsights:regionsLabel')}
          {isGlobal
            ? t('features:market-intelligence:marketInsights:global')
            : [region, ...countries].flat().filter(Boolean).join(', ')}
        </div>
      </div>
      <div className="w-full">
        {Array.isArray(categories) &&
          categories.length > 0 &&
          categories.map((category, index) => {
            return (
              <React.Fragment key={title + index}>
                <span className="inline-block">
                  <span className="rounded-4px border-grey-300 text-grey-700 text-2xs leading-1 bg-grey-300 inline-block border px-1 text-center font-sans font-medium uppercase tracking-wider">
                    {category}
                  </span>
                </span>{' '}
              </React.Fragment>
            );
          })}
      </div>
      {divider && (
        <div className="w-full">
          <div className="my-5">
            <Divider />
          </div>
        </div>
      )}
    </div>
  );
};
