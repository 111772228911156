import { Role } from 'shared/types/authorization';
import { RoleDefinition } from 'utils/firebase/collection';
import { useCollection } from 'utils/firebase/hooks/useCollection';

export type RoleDefinitions = {
  [key in Role]: RoleDefinition;
};

export function useRoleDefinitions(): RoleDefinitions {
  const collection = useCollection('role_definitions');
  //Temp hack should be fixed to make all dependancies work with undefined
  return (collection || []).reduce((acc: RoleDefinitions, definition) => {
    acc[definition.id as Role] = definition.data;
    return acc;
  }, {} as RoleDefinitions);
}
