import classNames from 'classnames';
import { Paragraph } from 'components/Paragraph';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapStringToNameValueOption } from '../../lib/utils';
import { getCountries } from '../CountryDropdown/CountryDropdown';
import { Divider } from '../Divider';
import { Heading } from '../Heading';
import { Steps } from '../Steps/Steps';

export type ExpansionOpportunityProps = {
  divider?: boolean;
  border?: boolean;
  country?: string;
  rationale?: string;
  principalPortfolio: string;
  portfolioFit: number;
  salesTeamStrength: number;
  labSupportResources: number;
  date?: string;
  marketSegmentName: string | undefined;
  noPadding?: boolean;
  maxRationaleLinesToDisplay?: number;
};

export const ExpansionOpportunity: React.FC<ExpansionOpportunityProps> = ({
  divider = false,
  border = true,
  country,
  rationale,
  portfolioFit,
  salesTeamStrength,
  labSupportResources,
  principalPortfolio = 'All',
  marketSegmentName,
  noPadding,
  maxRationaleLinesToDisplay,
}) => {
  const { t } = useTranslation();

  const countryOption = mapStringToNameValueOption(country, getCountries());

  const ratingOptions: string[] = t(
    'features:market-intelligence:expansionOpportunities:rating:options',
    {
      returnObjects: true,
    },
  );
  const ratingOptionsLength = ratingOptions.length || 0;

  return (
    <div className="xl:max-w-xl-content flex grow flex-col">
      <div
        className={classNames({
          'border-grey-300 mb-4 rounded border md:mb-5': border,
          'p-4 pt-5': !noPadding,
        })}
      >
        <div className="xl:max-w-xl-content flex">
          <div className="flex w-9/12 grow items-end lg:w-9/12">
            <div className="xl:max-w-xl-content flex grow flex-col">
              {!!country && (
                <div className="w-full">
                  <div className="mb-1 flex items-center">
                    <span
                      className={classNames('fi shrink-0 text-3xl', {
                        [`fi-${countryOption?.value.toString().toLowerCase()}`]:
                          countryOption?.value,
                      })}
                    ></span>
                    <span className="pl-2">
                      <Heading text={country} margin={0} level="h3" />
                    </span>
                  </div>
                </div>
              )}
              <div className="w-full">
                <div className="text-grey-700 text-xs">
                  {t(
                    'features:market-intelligence:expansionOpportunities:portfolio',
                  )}
                  : <strong>{principalPortfolio}</strong>
                  <span className="mx-2">|</span>
                  {marketSegmentName && (
                    <>
                      {t(
                        'features:market-intelligence:expansionOpportunities:marketSegment',
                      )}
                      : <strong>{marketSegmentName}</strong>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="text-grey-700 mb-4 mt-1 whitespace-pre-line">
            {maxRationaleLinesToDisplay ? (
              <Paragraph lineNumber={maxRationaleLinesToDisplay} isExpandable>
                {rationale || ''}
              </Paragraph>
            ) : (
              rationale
            )}
          </div>
        </div>
        <div className="w-full">
          <dl className="flex flex-wrap">
            {/* 👇 This mb-2 is only on this span to give a margin from the overflowing third span */}
            <span className="mb-2 flex flex-col">
              <dt className="text-grey-700 order-2 text-sm font-medium">
                {t(
                  'features:market-intelligence:expansionOpportunities:portfolioFit',
                )}
              </dt>
              <dd className="order-1 mb-1">
                <Steps total={ratingOptionsLength} selected={portfolioFit} />
              </dd>
            </span>
            <span className="mx-4 flex flex-col">
              <dt className="text-grey-700 order-2 text-sm font-medium">
                {t(
                  'features:market-intelligence:expansionOpportunities:salesTeamStrength',
                )}
              </dt>
              <dd className="order-1 mb-1">
                <Steps
                  total={ratingOptionsLength}
                  selected={salesTeamStrength}
                />
              </dd>
            </span>
            <span className="flex flex-col">
              <dt className="text-grey-700 order-2 text-sm font-medium">
                {t(
                  'features:market-intelligence:expansionOpportunities:labSupportResources',
                )}
              </dt>
              <dd className="order-1 mb-1">
                <Steps
                  total={ratingOptionsLength}
                  selected={labSupportResources}
                />
              </dd>
            </span>
          </dl>
        </div>
      </div>
      {divider && (
        <div className="w-full">
          <div className="my-5">
            <Divider />
          </div>
        </div>
      )}
    </div>
  );
};
