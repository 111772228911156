import { useMsal } from '@azure/msal-react';
import { Loading } from 'components/Loading';
import { PrincipalSelectionBar } from 'components/PrincipalSelectionBar';
import { SiteHeader } from 'components/SiteHeader';
import {
  redirectToSelectedPrincipal,
  useCurrentPrincipal,
  useCurrentUser,
  usePrincipals,
} from 'hooks/queries';
import React, { useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Role } from 'shared/types/authorization';
import { Error404 } from '../../components/ErrorPages';
import { ScrollToTop } from '../../components/ScrollToTop';
import { SiteFooter } from '../../components/SiteFooter';
import { useLocalizationCookieHandler } from '../../hooks/useLocalizationCookieHandler';
import { tracking } from '../../lib/tracking';
import { useWindowSize } from '../../shared/useWindowSize';
import { AcceptPoliciesModal } from './AcceptPoliciesModal';
import { findRouteDefintionByPathname, routes } from './Routes';

const ProtectedRoute: React.FC<{ Component: React.FC }> = ({ Component }) => {
  const { isLoaded } = useCurrentUser();

  return (
    <Loading
      loading={!isLoaded}
      className="h-300px flex w-full items-center justify-center"
    >
      <Component />
    </Loading>
  );
};

export const ContentController: React.FC = () => {
  const { user, role, isLoaded, userType, update } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(user.id);
  const { principals } = usePrincipals();
  const { instance } = useMsal();

  const localizationCookieHandler = useLocalizationCookieHandler();

  const calledOnce = useRef(false);
  const [windowWidth] = useWindowSize();

  useEffect(() => {
    if (!calledOnce.current && windowWidth !== 0) {
      tracking.push('user', 'User Login', {
        loginStatus: 'logged in',
        type: role,
        principalID: currentPrincipal.id,
      });

      const {
        additionalInformation: { trackingCategory = '', trackingType = '' },
        name = '',
      } = findRouteDefintionByPathname(
        window.location.pathname,
        currentPrincipal.slug,
      );

      tracking.push('content', 'Content View', {
        category: trackingCategory,
        type: trackingType,
        content: name,
      });

      calledOnce.current = true;
    }
  }, [calledOnce, user, windowWidth, role, currentPrincipal, userType]);

  useEffect(() => {
    redirectToSelectedPrincipal(user.id, principals);
  }, [currentPrincipal, principals, user]);

  useEffect(() => {
    localizationCookieHandler.changeAppLanguage();
  }, [localizationCookieHandler]);

  if (window.location.pathname === '/')
    return (
      <Loading className="flex w-full items-center justify-center" loading />
    );

  return (
    <>
      <ScrollToTop />
      <AcceptPoliciesModal
        isHidden={!!user.acceptedTermsOfUse}
        onConfirm={() => update({ acceptedTermsOfUse: new Date() })}
        onCancel={() => {
          instance.logoutRedirect({ onRedirectNavigate: () => false });
        }}
      />
      <div className="grow flex-col">
        <div className="flex h-screen w-full flex-col lg:flex-row">
          {role !== Role.DISTRIBUTION_MANAGER && (
            <div className="'sticky z-100 left-0 top-0 block lg:hidden">
              <PrincipalSelectionBar />
            </div>
          )}
          <SiteHeader />
          <div
            id="main-content"
            className="flex grow flex-col md:overflow-y-auto"
          >
            {role !== Role.DISTRIBUTION_MANAGER && (
              <div className="'sticky z-100 left-0 top-0 hidden lg:block">
                <PrincipalSelectionBar />
              </div>
            )}
            <div className="relative flex shrink-0 grow flex-col">
              {isLoaded && (
                <Routes>
                  {Object.values(routes(currentPrincipal.slug)).map(
                    ({ url, Component, authRequired }) => (
                      <Route
                        key={url}
                        path={url}
                        element={
                          <div className="main-page flex grow overflow-x-hidden">
                            {authRequired ? (
                              <ProtectedRoute Component={Component} />
                            ) : (
                              <Component />
                            )}
                          </div>
                        }
                      />
                    ),
                  )}
                  <Route path="*" element={<Error404 />} />
                </Routes>
              )}
            </div>
            <SiteFooter />
          </div>
        </div>
      </div>
    </>
  );
};
