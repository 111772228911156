import { Link } from 'components/Link';
import React from 'react';
import { useTranslation } from 'react-i18next';

type MemberDetailsDataBlockProps = {
  label: string;
  data: string | number | undefined;
  allowEdit?: boolean;
  onEditClick?: () => void;
};

export const MemberDetailsDataBlock: React.FC<MemberDetailsDataBlockProps> = ({
  label,
  data,
  allowEdit,
  onEditClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-2 mr-1" key={label}>
      <div className="truncate whitespace-nowrap font-medium">{label}</div>
      <div className="flex">
        <div className="truncate whitespace-nowrap">
          {data || <span className="text-grey-600">{t('labels:unknown')}</span>}
        </div>
        {allowEdit && (
          <div className="ml-1">
            <Link icon="Edit" onClick={onEditClick} />
          </div>
        )}
      </div>
    </div>
  );
};
