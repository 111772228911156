export const useEnvironment = (): string => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
      return 'prod';
    case 'staging':
      return 'sta';
    case 'development':
      return 'dev';
    default:
      return 'sta';
  }
};
