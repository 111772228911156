import classNames from 'classnames';
import { Icon } from 'components/Icon';
import React, { ReactElement } from 'react';
import { useDropdownContext } from '../Dropdown';

export type DropdownButtonProps = {
  children: ReactElement | ReactElement[] | string;
  placeholder?: string;
  label?: string;
  error: boolean;
  errorText?: string;
  disabled?: boolean;
};

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  children,
  placeholder,
  error,
  errorText,
  disabled,
  label,
}) => {
  const { open, toggleOpen } = useDropdownContext();

  return (
    <>
      <div
        onClick={disabled ? undefined : toggleOpen}
        data-testid="dropdown-button"
        className={classNames(
          'border-grey-500 error:border-grey-500 focus:shadow-2dp font-regular flex h-[3.15rem] w-full cursor-pointer justify-between rounded border bg-white p-2 text-left hover:border-blue-100 focus:border-blue-100 focus:outline-none',
          {
            'rounded-b-none border-blue-100': open,
            'border-red-300': error,
            'text-grey-700': !disabled,
            'text-grey-400 border-grey-200 pointer-events-none cursor-not-allowed':
              disabled,
          },
        )}
      >
        <div className="flex">
          {label && (
            <span
              className={classNames('mr-1 shrink-0', {
                'text-grey-400': disabled,
              })}
            >
              {label}
            </span>
          )}
          {children ? <div className="truncate">{children}</div> : placeholder}
        </div>
        <span
          className={classNames('ml-1 w-4 shrink-0', {
            'text-grey-400': disabled,
          })}
        >
          {open ? <Icon name="ArrowUp" /> : <Icon name="ArrowDown" />}
        </span>
      </div>
      {error && errorText && !open && (
        <div className="flex text-red-300">
          <span className="h-3 w-3">
            <Icon name="InfoCircle" />
          </span>
          <span className="ml-2px text-2xs">{errorText}</span>
        </div>
      )}
    </>
  );
};
