import classNames from 'classnames';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Icon } from '../Icon';

const BASE_CHECKBOX_OUTER_CLASSES =
  'inline-block inline-flex justify-center items-center border select-none w-4 h-4 rounded shrink-0';
const DEFAULT_CHECKBOX_OUTER_CLASSES = `${BASE_CHECKBOX_OUTER_CLASSES} border-grey-500 bg-white hover:border-blue-100 focus:border-blue-100`;
const DISABLED_CHECKBOX_OUTER_CLASSES = `${BASE_CHECKBOX_OUTER_CLASSES} bg-grey-100 border-grey-200 cursor-default`;
const DISABLED_VISABLE_LABEL_CHECKBOX_OUTER_CLASSES = `${BASE_CHECKBOX_OUTER_CLASSES} bg-grey-200 border-grey-300 cursor-default`;
const ERROR_CHECKBOX_OUTER_CLASSES = `${BASE_CHECKBOX_OUTER_CLASSES} bg-white border-error`;

export type CheckboxProps = {
  /**
   * Checked status of the checkbox
   */
  initialChecked?: boolean;
  /**
   * Label of the checkbox
   */
  label?: string;
  /**
   * Label of the checkbox on the right
   */
  labelPosition?: 'left' | 'right';
  /**
   * Flag to disable checkbox
   */
  disabled?: boolean;
  /**
   * Flag to disable checkbox
   */
  disabledVisibleLabel?: boolean;
  /**
   * Flag to turn text Light Grey
   */
  lightGrey?: boolean;
  /**
   * Flag for error
   */
  error?: boolean;
  /**
   * Flag for displaying checked state as intermediate
   */
  displayAsIntermediate?: boolean;
  /**
   * Change function for checkbox
   */
  onChange?: (isChecked: boolean) => void;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  initialChecked,
  label,
  labelPosition = 'right',
  disabled,
  disabledVisibleLabel,
  lightGrey,
  error,
  displayAsIntermediate,
  onChange,
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!initialChecked);

  useEffect(() => setIsChecked(initialChecked || false), [initialChecked]);

  const checkboxOuterClasses = useMemo(() => {
    switch (true) {
      case disabled:
        return DISABLED_CHECKBOX_OUTER_CLASSES;
      case disabledVisibleLabel:
        return DISABLED_VISABLE_LABEL_CHECKBOX_OUTER_CLASSES;
      case error:
        return ERROR_CHECKBOX_OUTER_CLASSES;
      default:
        return DEFAULT_CHECKBOX_OUTER_CLASSES;
    }
  }, [disabled, disabledVisibleLabel, error]);

  const changeCheckbox = useCallback(() => {
    if (!disabled && !disabledVisibleLabel) {
      const newValue = !isChecked;
      setIsChecked(newValue);
      if (onChange) {
        // If there's a change event, let that handle things
        onChange(newValue);
      }
    }
  }, [disabled, disabledVisibleLabel, setIsChecked, isChecked, onChange]);

  const displayIcon: ReactElement | null = useMemo(() => {
    if (displayAsIntermediate) {
      return (
        <div
          className={classNames(
            'h-3 w-3',
            disabledVisibleLabel || disabled
              ? 'text-grey-600'
              : 'text-blue-100',
          )}
          data-testid="is-intermediate"
        >
          <Icon name="Remove" />
        </div>
      );
    } else if (isChecked) {
      return (
        <div
          className={classNames(
            'h-3 w-3',
            disabledVisibleLabel || disabled
              ? 'text-grey-600'
              : 'text-blue-100',
          )}
          data-testid="is-selected"
        >
          <Icon name="Checkmark" />
        </div>
      );
    } else {
      return null;
    }
  }, [displayAsIntermediate, isChecked, disabled, disabledVisibleLabel]);

  const isLabelOnTheLeft = labelPosition === 'left';

  return (
    <div className={classNames('flex')} tabIndex={-1}>
      {!isLabelOnTheLeft && (
        <button
          type="button"
          className={checkboxOuterClasses}
          onClick={changeCheckbox}
          data-testid="checkbox"
        >
          {displayIcon}
        </button>
      )}
      {label && (
        <label
          className={classNames('w-auto', {
            'mr-1': isLabelOnTheLeft,
            'ml-1': !isLabelOnTheLeft,
            'text-grey-400': disabled && !disabledVisibleLabel,
            'text-grey-700': disabledVisibleLabel && !disabled,
            'text-grey-700 cursor-pointer hover:text-blue-100':
              !disabled && !disabledVisibleLabel && !lightGrey,
            'text-grey-600 cursor-pointer': lightGrey,
          })}
        >
          <input
            type="checkbox"
            className="hidden"
            checked={isChecked}
            onChange={changeCheckbox}
          />
          <span>{label}</span>
        </label>
      )}
      {isLabelOnTheLeft && (
        <button
          type="button"
          className={checkboxOuterClasses}
          onClick={changeCheckbox}
        >
          {displayIcon}
        </button>
      )}
    </div>
  );
};
