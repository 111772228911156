import classNames from 'classnames';
import Count from 'components/Count/Count';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../Dropdown';
import { DropdownButton } from '../atoms/Button';
import { ButtonBar, SelectionButton } from '../atoms/ButtonBar';
import { Items } from '../atoms/Items';
import { NestedOptions } from '../atoms/NestedMultiselectOption';
import { DropdownOptionProps } from '../helper/DropdownTypes';
import { getAllChildren } from '../helper/helper';
import { useMultiSelectActiveOption } from '../helper/useMultiSelectActiveOption';

export type DropdownMultiSelectComponentProps = {
  disabled?: boolean;
  placeholder?: string;
  options: DropdownOptionProps[];
  onChange?: (option: DropdownOptionProps[]) => void;
  typeAhead?: boolean;
  label?: string;
  error?: boolean;
  errorText?: string;
  selectAllLabel?: string;
  deselectAllLabel?: string;
  customSelectionButton?: SelectionButton;
  initialSelection?: DropdownOptionProps[];
  disabledMessage?: string;
  widthVariant?: 'full-width' | 'content';
};

export const DropdownFilter: React.FC<DropdownMultiSelectComponentProps> = ({
  placeholder,
  disabled,
  options,
  onChange,
  error,
  errorText,
  selectAllLabel,
  deselectAllLabel,
  customSelectionButton,
  typeAhead,
  label,
  initialSelection,
  disabledMessage,
  widthVariant,
}) => {
  const {
    activeOptionState,
    onClickHandler,
    onParentClickHandler,
    selectAllHandler,
    deselectAllHandler,
    customSelectionHandler,
  } = useMultiSelectActiveOption(options, onChange, initialSelection);
  const { t } = useTranslation();

  const showSpecialDisabledBehaviour = disabled && disabledMessage;

  const calcButtonValue = () => {
    switch (activeOptionState.length) {
      case 0:
        return (
          <span>
            {showSpecialDisabledBehaviour
              ? disabledMessage
              : t('placeholders:dropdownNone')}
          </span>
        );
      case 1:
        return (
          <span className="shrink truncate">
            {showSpecialDisabledBehaviour
              ? disabledMessage
              : activeOptionState[0].name}
          </span>
        );
      case getAllChildren(options).length:
        return <span>{t('placeholders:dropdownAll')}</span>;
      default: {
        return showSpecialDisabledBehaviour ? (
          <span>{disabledMessage}</span>
        ) : (
          <div className="h4 w-4">
            <Count number={activeOptionState.length} />
          </div>
        );
      }
    }
  };

  const selectionButtons = [];

  if (selectAllLabel)
    selectionButtons.push({
      label: selectAllLabel,
      onClick: selectAllHandler,
      disabled: activeOptionState.length === getAllChildren(options).length,
    });
  if (deselectAllLabel)
    selectionButtons.push({
      label: deselectAllLabel,
      onClick: deselectAllHandler,
      disabled: activeOptionState.length === 0,
    });
  if (customSelectionButton) {
    selectionButtons.push({
      ...customSelectionButton,
      onClick: () => {
        if (customSelectionButton.customSelection)
          customSelectionHandler(customSelectionButton.customSelection);
        if (customSelectionButton.onClick) customSelectionButton.onClick();
      },
    });
  }

  return (
    <Dropdown>
      <DropdownButton
        placeholder={placeholder || t('placeholders:dropdownDefault')}
        error={!!error}
        errorText={errorText}
        disabled={disabled}
        label={label}
      >
        <div
          className={classNames('flex', {
            'text-grey-700': !error && !disabled,
          })}
        >
          {calcButtonValue()}
        </div>
      </DropdownButton>
      <Items typeAhead={typeAhead} widthVariant={widthVariant}>
        {(selectAllLabel || deselectAllLabel || customSelectionButton) && (
          <ButtonBar buttons={selectionButtons} />
        )}
        <NestedOptions
          activeOptionState={activeOptionState}
          onClickHandler={onClickHandler}
          onParentClickHandler={onParentClickHandler}
          options={options}
        />
      </Items>
    </Dropdown>
  );
};
