import React from 'react';

export const getNameInitials = (
  name: string | undefined,
): string | undefined => {
  if (!name) return;
  const nameSplit = name.includes('.') ? name.split('.') : name.split(' ');
  const nameSplitFilter = nameSplit.filter((e) => e);
  const firstInitial = nameSplitFilter[0][0];
  const secondInitial = nameSplitFilter[nameSplitFilter.length - 1][0];
  return firstInitial + secondInitial;
};

export const generateCircleColor = (
  nameFirstInitial: string | undefined,
): string => {
  if (!nameFirstInitial) return 'bg-grey-500';

  const colorList: { [key: string]: string[] } = {
    red: ['a', 'b', 'c', 'd'],
    blue: ['e', 'f', 'g'],
    purple: ['h', 'i', 'j'],
    violet: ['k', 'l', 'm'],
    teal: ['n', 'o', 'p'],
    green: ['q', 'r', 's'],
    yellow: ['t', 'u', 'v'],
    orange: ['w', 'x', 'y', 'z'],
  };

  const colorIndex = nameFirstInitial.toLowerCase();
  const color = Object.keys(colorList).find((key: string) =>
    colorList[key].includes(colorIndex),
  );
  return `bg-${color || 'grey'}-200`;
};

export type CircleInitialsProps = {
  name: string | undefined;
};

export const CircleInitials: React.FC<CircleInitialsProps> = ({
  name,
}: CircleInitialsProps) => {
  return (
    <div
      className={`${generateCircleColor(
        name?.[0],
      )} h-40px w-40px mr-2 flex shrink-0 items-center justify-center rounded-full font-semibold text-white`}
    >
      {getNameInitials(name) || '?'}
    </div>
  );
};

export default CircleInitials;
