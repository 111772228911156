import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
  Middleware,
} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer, RootState } from './root-reducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const isDevOrLocal =
  process.env.REACT_APP_ENVIRONMENT === 'local' ||
  process.env.REACT_APP_ENVIRONMENT === 'dev';

const middleWares = [isDevOrLocal && logger].filter(Boolean) as Middleware[];

const composeEnhancer =
  (isDevOrLocal && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

type ExtendedPersistConfig = PersistConfig<RootState> & {
  whitelist?: (keyof RootState)[];
  blacklist?: (keyof RootState)[];
};

const persistConfig: ExtendedPersistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composedEnhancers = composeEnhancer(applyMiddleware(...middleWares));

export const store = createStore(
  persistedReducer,
  undefined,
  composedEnhancers,
);

export const persistor = persistStore(store);
