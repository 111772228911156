import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { Input } from 'components/Input';
import { Link } from 'components/Link';
import { Loading } from 'components/Loading';
import { PermissionDenied } from 'components/PermissionDenied/PermissionDenied';
import {
  AddGlobalUserModal,
  SavingModal,
} from 'components/UserManagementModals';
import {
  GlobalUserPrincipalRole,
  GlobalUserTableColumn,
} from 'components/UserManagementModals/AddGlobalUser/AddGlobalUserModal';
import {
  ToggleUsersToDeleteFunc,
  comparer,
  stringComparer,
} from 'features/PortalMembers/PortalMembers';
import { UpdateData, deleteField } from 'firebase/firestore';
import { useUsers } from 'hooks/queries';
import { usePermission } from 'hooks/usePermission';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingConfig } from 'shared/types/SortingConfig';
import { Permission, Role } from 'shared/types/authorization';
import {
  Principal,
  UserData,
} from 'utils/firebase/collection/firebase-collection-types';
import { docRef, updateDocument } from 'utils/firebase/firebase-utils';
import { GlobalUserTableContent } from './GlobalUserTableContent';
import { RemoveGlobalUserModal } from './RemoveGlobalUserModal';

export const sorter = (
  objectA: UserData | Principal,
  objectb: UserData | Principal,
  { column, asc }: SortingConfig<GlobalUserTableColumn>,
): number => {
  switch (column) {
    case GlobalUserTableColumn.NAME: {
      return comparer(asc, objectA.name, objectb.name, stringComparer);
    }

    default:
      return 0;
  }
};

export const GlobalUsers: React.FC = () => {
  const { t } = useTranslation();
  const { userHasPermission } = usePermission();
  const userCanCreateGlobalUsers = userHasPermission(
    Permission.EDIT_GLOBAL_USERS,
  );
  const { users } = useUsers();
  const hasGobalUsers = !!users?.find(
    ({ user, isGlobalUser }) =>
      isGlobalUser && user.global?.role !== Role.GLOBAL_USER_ADMIN,
  );
  const [showAddGlobalUserModal, setShowAddGlobalUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserData>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<
    SortingConfig<GlobalUserTableColumn>
  >({
    column: GlobalUserTableColumn.NAME,
    asc: true,
  });
  const [userIdsToDelete, setUserIdsToDelete] = useState<string[]>([]);
  const [initalStepNumber, setInitalStepNumber] = useState<number>(1);
  const [savingGlobalUserData, setSavingGlobalUserData] = useState(false);

  const filteredGlobalUsers = useMemo(() => {
    return users
      ?.filter((user) => user.isGlobalUser)
      ?.filter((user) => user.role !== Role.GLOBAL_USER_ADMIN)
      ?.map(({ user }) => user)
      ?.filter((user) => {
        if (searchTerm.length === 0) return true;
        return (
          user.name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
      ?.sort((a: UserData, b: UserData) => sorter(a, b, sortConfig));
  }, [users, sortConfig, searchTerm]);

  const handleSortingChange = (targetColumn: GlobalUserTableColumn) => {
    const asc = sortConfig.column === targetColumn ? !sortConfig.asc : true;
    setSortConfig({ column: targetColumn, asc });
  };

  const toggleUsersToDelete: ToggleUsersToDeleteFunc = (userIds, toAdd) => {
    let newUserIdsToDelete = [...userIdsToDelete];
    userIds.forEach((uid) => {
      if (toAdd) {
        newUserIdsToDelete.push(uid);
        return;
      }

      newUserIdsToDelete.splice(newUserIdsToDelete.indexOf(uid), 1);
    });
    newUserIdsToDelete = [...new Set(newUserIdsToDelete)];
    setUserIdsToDelete(newUserIdsToDelete);
  };

  const toggleEditPrincipalsModal = () => {
    setShowAddGlobalUserModal(true);
    setInitalStepNumber(2);
  };

  const onCancel = () => {
    setShowAddGlobalUserModal(false);
    setSelectedUser(undefined);
    setInitalStepNumber(1);
  };

  const processSavingGlobalUserData = (
    userId: string,
    principalRoles: GlobalUserPrincipalRole,
  ) => {
    setSavingGlobalUserData(true);
    const globalUserExists = users?.find(({ user }) => user.id === userId);
    const role = Role.GLOBAL_USER_VIEW;

    const onGlobalUserSaveHandler = () => {
      setSavingGlobalUserData(false);
      setShowAddGlobalUserModal(false);
    };
    const userChanges = {
      ...globalUserExists?.user,
      global_user: { role, principalRoles },
    };

    updateDocument(
      docRef('users', userId),
      userChanges as UpdateData<UserData>,
    ).then(() => {
      onGlobalUserSaveHandler();
    });
  };

  const processRemovingGlobalUserData = () => {
    setSavingGlobalUserData(true);

    userIdsToDelete.forEach((globalUserId) => {
      updateDocument(docRef('users', globalUserId), {
        global_user: deleteField(),
      }).then(() => {
        setSavingGlobalUserData(false);
        setShowDeleteUserModal(false);
        setUserIdsToDelete([]);
      });
    });
  };

  if (filteredGlobalUsers === undefined) {
    return (
      <Loading className="flex w-full items-center justify-center" loading />
    );
  }

  return (
    <div className="w-full">
      {userCanCreateGlobalUsers ? (
        <>
          <div className="w-full px-4 md:px-6">
            <div className="max-w-full">
              <div className="pt-6 md:pt-7">
                <div className="align-start flex flex-col items-baseline justify-between sm:flex-row md:space-x-2">
                  <Heading
                    text={t('features:global-users:heading')}
                    margin={0}
                    level="h1"
                  />
                  {!!hasGobalUsers && (
                    <div className="my-3 whitespace-nowrap sm:my-0">
                      <Link
                        onClick={(event) => {
                          event.preventDefault();
                          setShowAddGlobalUserModal(true);
                        }}
                        label={t('features:global-users:addGlobalUser')}
                        icon="Add"
                        iconPosition="left"
                      />
                    </div>
                  )}
                </div>
              </div>
              {!!hasGobalUsers && (
                <div data-testid="globalUsers" className="flex flex-row">
                  {!!userIdsToDelete.length && (
                    <div
                      className="my-4 flex w-full flex-col items-start"
                      data-testid="delete-users-button"
                    >
                      <div className="flex items-center ">
                        <div className="mr-3">
                          {userIdsToDelete.length}{' '}
                          {t(
                            `features:portal-members:labels:user${
                              userIdsToDelete.length !== 1 ? 's' : ''
                            }Selected`,
                          )}
                        </div>
                        <Link
                          label={t('labels:delete')}
                          icon="Delete"
                          onClick={() => setShowDeleteUserModal(true)}
                        />
                      </div>
                    </div>
                  )}
                  <div className="my-3 flex w-full flex-col items-end">
                    <div className="sm:w-300px">
                      <Input
                        placeholder={t('labels:userNameSearch')}
                        iconPosition="right"
                        icon="Search"
                        onChange={(e) => setSearchTerm(e.currentTarget.value)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Loading loading={!users}>
              {hasGobalUsers ? (
                <GlobalUserTableContent
                  isExistingGlobalUsersTable
                  filteredUsers={filteredGlobalUsers}
                  sortConfig={sortConfig}
                  userIdsToDelete={userIdsToDelete}
                  toggleUsersToDelete={toggleUsersToDelete}
                  toggleEditPrincipalsModal={toggleEditPrincipalsModal}
                  handleSortingChange={handleSortingChange}
                  handleSelectUser={setSelectedUser}
                />
              ) : (
                <div
                  className="border-grey-300 mt-6 box-border border p-6"
                  data-testid="noGlobalUsers"
                >
                  <div className="flex flex-col items-baseline justify-center sm:flex-row md:space-x-2">
                    <Icon name="EmptyMarket" />
                  </div>
                  <div className="my-3 flex flex-col items-baseline justify-center sm:flex-row md:space-x-2">
                    <div className="text-grey-600">
                      {t('features:global-users:noGlobalUsers')}
                    </div>
                  </div>
                  <div className="flex flex-col items-baseline justify-center sm:flex-row md:space-x-2 ">
                    <Link
                      onClick={(event) => {
                        event.preventDefault();
                        setShowAddGlobalUserModal(true);
                      }}
                      label={t('features:global-users:addGlobalUser')}
                      icon="Add"
                      iconPosition="left"
                    />
                  </div>
                </div>
              )}
            </Loading>
          </div>

          {showAddGlobalUserModal && (
            <AddGlobalUserModal
              initalStepNumber={initalStepNumber}
              selectedUserToEdit={selectedUser}
              onCancel={onCancel}
              onConfirm={(userId, principalRoles) =>
                processSavingGlobalUserData(userId, principalRoles)
              }
            />
          )}

          {showDeleteUserModal && (
            <RemoveGlobalUserModal
              usersToDelete={userIdsToDelete.map((id) => ({
                id,
                name: filteredGlobalUsers?.find((i) => i.id === id)?.name,
              }))}
              onCancel={() => setShowDeleteUserModal(false)}
              onComplete={processRemovingGlobalUserData}
            />
          )}

          {savingGlobalUserData && (
            <SavingModal
              topMessage={t('modals:addUserModal:createUserMessage')}
              bottomMessage={t('modals:editUserModal:waitingMessage')}
            />
          )}
        </>
      ) : (
        <PermissionDenied />
      )}
    </div>
  );
};
