import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateSharepointDownloadLink } from 'utils/firebase/cloud-functions';
import {
  FileTreeElement,
  SharePointUpdateResult,
  getRegionAndSubTypeFolderForFile,
  isFolder,
} from '../../lib/sharepoint';
import { EmptyState } from '../EmptyState';
import { Icon, IconName } from '../Icon';
import { Link } from '../Link';

type FileTreeElementExtended = FileTreeElement & {
  updates: number;
  filesInAllSubTrees: number;
};

export type SharepointProps = {
  /**
   * Id of the current principal
   */
  currentPrincipalId: string;
  /**
   * Id of the current folder
   */
  currentFolderId?: string;
  /**
  /**
   * Id of the folder parenting the currentfolder, if undefined we are at principal root
   */
  parentFolderId?: string;
  /**
   * Content of the Current folder
   */
  fileTree: FileTreeElement[];
  /**
   * Content of the Subfolders, if there are folders
   */
  subFileTrees?: SharePointUpdateResult[];
  /**
   * Click action of folder FileTreeElement
   */
  onFolderClick?(fileTreeElement: FileTreeElement): void;
  /**
   * Click action for going up to a parent folder
   */
  onParentFolderClick?(): void;
};

export const Sharepoint: React.FC<SharepointProps> = ({
  currentPrincipalId,
  currentFolderId,
  parentFolderId,
  fileTree,
  subFileTrees,
  onFolderClick,
  onParentFolderClick,
}: SharepointProps) => {
  const [filteredTree, setFilteredTree] = useState<
    FileTreeElement[] | FileTreeElementExtended[] | undefined
  >(undefined);
  const [sortNameAsc, setSortNameAsc] = useState<boolean>(true);
  const [sortLastModifiedAsc, setSortLastModifiedAsc] = useState<boolean>(true);
  const [sortOwnerAsc, setSortOwnerAsc] = useState<boolean>(true);
  const [activeFilter, setActiveFilter] = useState<
    'name' | 'modified' | 'owner' | undefined
  >('name');
  const { t } = useTranslation();

  /**
   * Filter FileTree
   */
  useEffect(() => {
    if (!subFileTrees) {
      setFilteredTree(fileTree ?? []);
    } else {
      const finalTree = fileTree.map((treeItem: FileTreeElement) => {
        if (!isFolder(treeItem)) {
          return { ...treeItem, filesInAllSubTrees: -1 };
        } else {
          const subTreeCache = subFileTrees?.find(
            (tree) => tree.sharePointFolder === treeItem.id,
          );
          const updates = subTreeCache?.updates ?? 0;
          const filesInAllSubTrees = subTreeCache?.filesInAllSubTrees ?? 0;
          return {
            ...treeItem,
            updates,
            filesInAllSubTrees,
          };
        }
      });
      setFilteredTree(finalTree ? finalTree : []);
    }
  }, [fileTree, subFileTrees]);

  /**
   * Sort File Tree by active Filter, in current implementation only one filter can be active at a time or no filter is active at all
   */
  const filteredSortedFileTree = useCallback(() => {
    if (!filteredTree) return [];
    if (activeFilter) {
      const sortedFileTree = filteredTree.sort((a, b): number => {
        if (activeFilter === 'name') {
          if (a.name === b.name) return 0;
          if (sortNameAsc) {
            return a.name < b.name ? -1 : 1;
          } else {
            return a.name > b.name ? -1 : 1;
          }
        } else if (activeFilter === 'modified') {
          if (
            new Date(a.lastModifiedDateTime).getTime() ===
            new Date(b.lastModifiedDateTime).getTime()
          ) {
            return 0;
          }
          if (sortLastModifiedAsc) {
            return new Date(a.lastModifiedDateTime).getTime() <
              new Date(b.lastModifiedDateTime).getTime()
              ? -1
              : 1;
          } else {
            return new Date(a.lastModifiedDateTime).getTime() >
              new Date(b.lastModifiedDateTime).getTime()
              ? -1
              : 1;
          }
        } else {
          if (sortOwnerAsc) {
            if (a.createdBy.user.displayName < b.createdBy.user.displayName)
              return -1;
            if (a.createdBy.user.displayName > b.createdBy.user.displayName)
              return 1;
            return 0;
          } else {
            if (a.createdBy.user.displayName < b.createdBy.user.displayName)
              return 1;
            if (a.createdBy.user.displayName > b.createdBy.user.displayName)
              return -1;
            return 0;
          }
        }
      });
      return sortedFileTree;
    } else {
      return filteredTree;
    }
  }, [
    filteredTree,
    activeFilter,
    sortNameAsc,
    sortLastModifiedAsc,
    sortOwnerAsc,
  ]);

  const mimeTypeToIcon = (mimeType: string | undefined): IconName => {
    switch (mimeType) {
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'FileDoc';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'FileXLS';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'FilePPT';
      case 'application/pdf':
        return 'FilePDF';
      case 'image/png':
        return 'FileImage';
      case 'image/jpg':
        return 'FileImage';
      case 'image/jpeg':
        return 'FileImage';
      default:
        return 'File';
    }
  };

  function isFileTreeElementExtend(
    element: FileTreeElement | FileTreeElementExtended,
  ): element is FileTreeElementExtended {
    return (element as FileTreeElementExtended).updates !== undefined;
  }

  const getFileTreeElementUpdates = (
    element: FileTreeElement | FileTreeElementExtended,
  ) => (isFileTreeElementExtend(element) ? element.updates : undefined);

  // Find out if Component is used for Last added or All Documents
  const isAllDocumentsMode = !!currentFolderId;

  useEffect(() => {
    if (currentFolderId) {
      setActiveFilter('name');
    } else {
      setActiveFilter('modified');
      setSortLastModifiedAsc(false);
    }
  }, [currentFolderId]);

  const rootOffset = process.env.REACT_APP_ENVIRONMENT === 'local' ? 1 : 2;
  const getDisplayFileLocation = (path: string) => {
    const splitted = path.split('/');
    const rootIndex = splitted.findIndex((element) => element === 'root:');
    const displayPath = splitted
      .slice(rootIndex + rootOffset)
      .join(' / ')
      .replace(/ /g, '\u00a0');
    return displayPath;
  };

  const onFileClick = async (
    fileTreeElement: FileTreeElement,
    principalPri: string,
  ) => {
    const response = await generateSharepointDownloadLink({
      fileTreeElement,
      principalPri,
    });

    onDownloadTracking(fileTreeElement);

    window.open(response.data, '_blank');
  };

  const onDownloadTracking = (
    file: FileTreeElement | FileTreeElementExtended,
  ) => {
    const regionAndSubType = getRegionAndSubTypeFolderForFile(file);
    window.dataLayer.push({ file: null });
    window.dataLayer.push({
      event: 'File Download',
      schema: 'file',
      file: {
        region: regionAndSubType.region,
        subtype: regionAndSubType.subType,
        filename: file.name,
      },
    });
  };

  return (
    <div>
      <div className="xs:flex-col xl:max-w-xl-content flex grow">
        <div className="min-h-48px mb-2 flex flex-col tracking-wide">
          <div className="w-full justify-start">
            {parentFolderId !== undefined &&
              parentFolderId !== currentFolderId && (
                <Link
                  onClick={() =>
                    onParentFolderClick ? onParentFolderClick() : undefined
                  }
                  label={t('components:sharepoint:buttons:back')}
                  icon="ArrowLeft"
                />
              )}
          </div>
        </div>
        <div className="border-grey-700 flex border-b-2 pb-4">
          <div className="xs:w-7/12 w-full md:px-5">
            <div
              className="flex cursor-pointer select-none font-semibold"
              id="sharepoint_sortNames"
              onClick={() => {
                if (activeFilter === 'name') setSortNameAsc(!sortNameAsc);
                setActiveFilter('name');
              }}
            >
              {t('components:sharepoint:table:headers:filename')}
              <span className="ml-1 w-3 hover:text-blue-100">
                <Icon
                  name={sortNameAsc ? 'ArrowNavUp' : 'ArrowNavDown'}
                  inline={true}
                  color={activeFilter === 'name' ? undefined : 'grey-400'}
                  hoverColor="blue-100"
                />
              </span>
            </div>
          </div>
          <div className="xs:w-2/12 w-full">
            <div
              id="sharepoint_sortModified"
              className="flex cursor-pointer select-none font-semibold"
              onClick={() => {
                if (activeFilter === 'modified')
                  setSortLastModifiedAsc(!sortLastModifiedAsc);
                setActiveFilter('modified');
              }}
            >
              {t('components:sharepoint:table:headers:lastModified')}
              <span className="ml-1 w-3">
                <Icon
                  name={sortLastModifiedAsc ? 'ArrowNavUp' : 'ArrowNavDown'}
                  inline={true}
                  color={activeFilter === 'modified' ? undefined : 'grey-400'}
                  hoverColor="blue-100"
                />
              </span>
            </div>
          </div>
          <div className="xs:w-2/12 w-full">
            <div
              id="sharpoint_sortOwner"
              className="flex cursor-pointer select-none font-semibold"
              onClick={() => {
                if (activeFilter === 'owner') setSortOwnerAsc(!sortOwnerAsc);
                setActiveFilter('owner');
              }}
            >
              {t('components:sharepoint:table:headers:owner')}
              <span className="ml-1 w-3">
                <Icon
                  name={sortOwnerAsc ? 'ArrowNavUp' : 'ArrowNavDown'}
                  inline={true}
                  color={activeFilter === 'owner' ? undefined : 'grey-400'}
                  hoverColor="blue-100"
                />
              </span>
            </div>
          </div>
          <div className="xs:w-1/12 w-full"></div>
        </div>
        {(!fileTree ||
          !fileTree.length ||
          !filteredTree ||
          !filteredTree.length) && (
          <div className="mt-9">
            <EmptyState
              message={t('components:sharepoint:emptyFolder')}
              hasBorder
            />
          </div>
        )}
        {filteredTree &&
          filteredSortedFileTree().map(
            (
              fileTreeElement: FileTreeElement | FileTreeElementExtended,
              index,
            ) => (
              <div
                key={index}
                className="border-grey-300 hover:bg-grey-100 flex cursor-pointer border-b py-4"
                onClick={
                  isFolder(fileTreeElement)
                    ? () => onFolderClick && onFolderClick(fileTreeElement)
                    : () => onFileClick(fileTreeElement, currentPrincipalId)
                }
              >
                <div className="xs:w-7/12 flex w-full">
                  <div className="w-4">
                    {!isAllDocumentsMode &&
                      fileTreeElement.modifiedSinceLastLogin && (
                        <div className="relative">
                          <span className="-left-15px top-8px absolute h-1 w-1 rounded-full bg-red-300" />
                        </div>
                      )}
                    <Icon
                      name={
                        isFolder(fileTreeElement)
                          ? 'Folder'
                          : mimeTypeToIcon(fileTreeElement.file?.mimeType)
                      }
                      notification={
                        isAllDocumentsMode
                          ? getFileTreeElementUpdates(fileTreeElement)
                          : undefined
                      }
                      inline={true}
                    />
                  </div>
                  <div className="tooltip pl-2">
                    {fileTreeElement.name}
                    {!isAllDocumentsMode && (
                      <span className="tooltiptext tooltiptext-large text-2xs w-auto select-none p-1">
                        {getDisplayFileLocation(
                          fileTreeElement.parentReference.path,
                        )}
                      </span>
                    )}
                  </div>
                </div>
                <div className="xs:w-2/12 w-full">
                  {`${
                    fileTreeElement.lastModifiedDateTime
                      ? new Date(
                          fileTreeElement.lastModifiedDateTime,
                        ).toLocaleDateString()
                      : ''
                  }`}
                </div>
                <div className="xs:w-2/12 w-full">
                  {fileTreeElement.createdBy?.user?.displayName &&
                  !isFolder(fileTreeElement)
                    ? fileTreeElement.createdBy.user.displayName
                    : '-'}
                </div>
                <div className="xs:w-1/12 w-full"></div>
              </div>
            ),
          )}
      </div>
    </div>
  );
};

export default Sharepoint;
