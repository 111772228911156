import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { getCRMMarketInsights } from 'utils/firebase/cloud-functions';
import { MarketInsight } from 'utils/firebase/collection';

export const useCRMMarketInsights = (
  selectedPrincipal: string,
): { data: MarketInsight[]; isLoading: boolean } => {
  const [data, setData] = useState<MarketInsight[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setData([]);
    setIsLoading(true);
    getCRMMarketInsights({ principalPri: selectedPrincipal }).then(
      ({ data }) => {
        setData(
          data.map((entry) => {
            const publicationDate = entry.aze_publicationdate
              ? Timestamp.fromDate(new Date(entry.aze_publicationdate))
              : undefined;

            return {
              id: entry.aze_marketinsightid,
              author:
                entry[
                  '_createdby_value@OData.Community.Display.V1.FormattedValue'
                ],
              categories:
                entry[
                  'aze_category@OData.Community.Display.V1.FormattedValue'
                ]?.split(';'),
              countries: entry.aze_aze_marketinsight_aze_country.map(
                (country) => country.aze_name,
              ),
              region: entry.aze_region,
              marketSegment: {
                name:
                  entry[
                    '_aze_marketsegment_value@OData.Community.Display.V1.FormattedValue'
                  ] ?? '',
                value: entry['_aze_marketsegment_value'] ?? '',
              },
              content: entry.aze_content,
              publicationDate: publicationDate,
              link: {
                href: entry.aze_link,
                label: entry.aze_linklabel || entry.aze_link,
              },
              principalId:
                entry[
                  '_aze_principal_value@OData.Community.Display.V1.FormattedValue'
                ] || selectedPrincipal,
              title: entry.aze_title || entry.aze_name,
              editDisabled: true,
            };
          }),
        );
        setIsLoading(false);
      },
    );
  }, [selectedPrincipal]);

  return { data, isLoading };
};
