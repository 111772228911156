import { Permission, Permissions } from 'shared/types/authorization';
import { useCurrentUser, useRoleDefinitions } from './queries';

export const usePermission = (): {
  userHasPermission: (permission: Permission | Permission[]) => boolean;
  userHasAtLeastOnePermission: (permission: Permission[]) => boolean;
} => {
  const { role } = useCurrentUser();
  const roleDefinitions = useRoleDefinitions();

  const userPermissions = role
    ? roleDefinitions?.[role]?.permissions
    : ({} as Permissions);

  const userHasPermission = (
    permissions: Permission | Permission[],
  ): boolean => {
    permissions = ([] as Permission[]).concat(permissions);
    return (
      permissions.length === 0 || permissions.every((t) => userPermissions?.[t])
    );
  };

  const userHasAtLeastOnePermission = (targets: Permission[]): boolean =>
    targets.length === 0 || targets.some((t) => userPermissions?.[t]);

  return {
    userHasPermission,
    userHasAtLeastOnePermission,
  };
};
