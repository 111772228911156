import { useEffect, useState } from 'react';
import { BaseUserType } from 'utils/firebase';
import { searchAdUsersByName } from 'utils/firebase/cloud-functions';

export const useAdUsersByName = (
  selectedPrincipalId: string,
  searchTerm: string | undefined,
): BaseUserType[] | undefined => {
  const [adUsers, setAdUsers] = useState<BaseUserType[]>();

  useEffect(() => {
    if (!searchTerm) return;
    setAdUsers(undefined);
    searchAdUsersByName(selectedPrincipalId, searchTerm).then(({ data }) => {
      setAdUsers(data);
    });
  }, [selectedPrincipalId, searchTerm]);
  return adUsers;
};
