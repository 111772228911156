import { Link } from 'components/Link';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Variant = 'default' | 'small' | 'medium' | 'intro';
type Color = 'dark' | 'darker' | 'light' | 'lighter';

export type ParagraphProps = {
  children?: React.ReactNode;
  /**
   * Variant to define the button style
   */
  variant?: Variant;
  /**
   * Color to define the button style
   */
  color?: Color;
  marginBottom?: number;
  mdMarginBottom?: number;
  lineNumber?: number;
  lineHeight?: number;
  isExpandable?: boolean;
};

const BASE_PARAGRAPH_CLASSES = ['font-sans'];
const DEFAULT_PARAGRAPH_CLASSES = ['font-regular', 'leading-4'];
const MEDIUM_PARAGRAPH_CLASSES = ['font-regular', 'text-xs', 'leading-3'];
const SMALL_PARAGRAPH_CLASSES = ['font-medium', 'text-2xs', 'leading-2'];
const INTRO_PARAGRAPH_CLASSES = ['font-regular', 'text-lg', 'leading-6'];

export const Paragraph: React.FC<ParagraphProps> = ({
  children = 'This is a paragraph',
  variant = 'default',
  color = 'darker',
  marginBottom,
  mdMarginBottom,
  lineNumber = 4,
  lineHeight = 24,
  isExpandable,
}) => {
  const [isShowMore, setIsShowMore] = useState<boolean>(true);
  const [height, setHeight] = useState<number>();

  const { t } = useTranslation();
  const paragraphRef = useRef<HTMLParagraphElement>(null);

  // Needed to force hiding on re-render
  useEffect(() => {
    if (children) {
      setIsShowMore(true);
    }
  }, [children]);

  useEffect(() => {
    setHeight(paragraphRef.current?.offsetHeight);
  }, [paragraphRef]);

  const paragraphStyle = () => {
    const obj = {
      WebkitLineClamp: lineNumber,
      lineClamp: lineNumber,
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    };
    return isShowMore ? obj : {};
  };

  const numOfLines = !!height && Math.round(height / lineHeight);

  const manageLargeContent = numOfLines && numOfLines >= lineNumber;

  const className = useMemo(() => {
    const classes = BASE_PARAGRAPH_CLASSES;

    switch (color) {
      case 'dark':
        if (variant !== 'default') {
          classes.push('text-grey-600');
        } else {
          classes.push('text-grey-700');
        }
        break;
      case 'darker':
        classes.push('text-grey-700');
        break;
      case 'light':
        if (variant !== 'default') {
          classes.push('text-grey-300');
        } else {
          classes.push('text-white');
        }
        break;
      case 'lighter':
        classes.push('text-white');
        break;
    }

    if (marginBottom) {
      classes.push(`mb-${marginBottom}`);
    }
    if (mdMarginBottom) {
      classes.push(`md:mb-${mdMarginBottom}`);
    }

    switch (variant) {
      case 'small':
        classes.push(...SMALL_PARAGRAPH_CLASSES);
        break;
      case 'medium':
        classes.push(...MEDIUM_PARAGRAPH_CLASSES);
        break;
      case 'intro':
        classes.push(...INTRO_PARAGRAPH_CLASSES);
        break;
      default:
        classes.push(...DEFAULT_PARAGRAPH_CLASSES);
    }

    return classes.join(' ');
  }, [variant, color, marginBottom, mdMarginBottom]);

  return isExpandable ? (
    <>
      <p
        ref={paragraphRef}
        className="text-grey-700 leading-4"
        style={paragraphStyle()}
      >
        {children}
      </p>
      {manageLargeContent && (
        <p className="mt-1">
          <Link
            label={t(`linksOrButtons:${isShowMore ? 'showMore' : 'showLess'}`)}
            onClick={() => setIsShowMore(!isShowMore)}
          />
        </p>
      )}
    </>
  ) : (
    <p className={className}>{children}</p>
  );
};
