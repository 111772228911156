import { Link } from 'components/Link';
import { Modal } from 'components/Modal';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type FallbackProps = {
  link: string;
  title: string;
  imageUrl: string;
};

export type ReportPreviewProps = {
  imageUrl: string;
  title: string;
  fallback?: FallbackProps;
};

export const ReportPreview: FC<ReportPreviewProps> = ({
  imageUrl,
  title,
  fallback,
}) => {
  const { t } = useTranslation();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [hasImageLoadError, setHasImageLoadError] = useState(false);

  return (
    <>
      <div
        className="bg-grey-100 border-grey-300 max-w-800px max-h-400px relative flex justify-center overflow-hidden rounded-md border p-5"
        data-testid="report-preview"
      >
        <img
          src={hasImageLoadError ? fallback?.imageUrl : imageUrl}
          alt={t('components:reportPreview:previewSmallAlt')}
          className="h-full rounded-md drop-shadow-lg"
          onError={() => {
            setHasImageLoadError(true);
          }}
        />
        <div className="absolute bottom-0 left-0 flex w-full justify-between bg-white p-4">
          <div className="mr-2 flex-1 truncate">
            {(hasImageLoadError ? fallback?.title : title) ||
              t('labels:unknown')}
          </div>
          <div className="flex flex-row space-x-3">
            {imageUrl && (
              <Link
                label={t('components:reportPreview:preview')}
                icon="File"
                iconPosition="left"
                href={hasImageLoadError ? fallback?.link : undefined}
                onClick={() => {
                  if (!hasImageLoadError) setShowPreviewModal(true);
                }}
                target={hasImageLoadError ? '_blank' : undefined}
              />
            )}
          </div>
        </div>
      </div>
      {showPreviewModal && (
        <Modal hideAllButtons isBig onCancel={() => setShowPreviewModal(false)}>
          <img
            src={imageUrl}
            alt={t('components:reportPreview:previewMainAlt')}
          />
        </Modal>
      )}
    </>
  );
};
