import { groupBy } from 'lodash';

import { CountryProps } from 'features/Dashboard/Map/DashboardMap';
import { countryList } from 'lib/countryList';

export type Regions = Record<
  string,
  {
    ISO_A3: string;
    ISO_A2: string;
    name: string;
    abb: string;
    region: string;
  }[]
>;

export const countriesMappedByRegion = (
  availableCountries: CountryProps[],
): Regions => {
  const filteredCountries = [];

  for (const availableCountry of availableCountries) {
    const country = countryList.find(
      (country) => country.name === availableCountry.name,
    );

    if (country) {
      filteredCountries.push({ ...country, ...availableCountry });
    }
  }

  return groupBy(filteredCountries, 'region');
};
