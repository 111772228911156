import { CircleInitials } from 'components/CircleInitials';
import React from 'react';
import { useTranslation } from 'react-i18next';

type PortalMemberNameProps = {
  name: string | undefined;
  email: string | undefined;
};

// Generates color based on users first initial

export const PortalMemberName: React.FC<PortalMemberNameProps> = ({
  name,
  email,
}) => {
  const { t } = useTranslation();

  return (
    <div className="inline-flex flex-row items-center">
      <CircleInitials name={name || ''} />
      <div className="max-w-550px">
        <div className="truncate whitespace-nowrap">
          {name || t('features:portal-members:labels:unknownName')}
        </div>
        <div className="text-2xs text-grey-600 truncate whitespace-nowrap">
          {email || t('features:portal-members:labels:unknownEmail')}
        </div>
      </div>
    </div>
  );
};
