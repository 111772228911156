import { Checkbox } from 'components/Checkbox';
import { EmptyState } from 'components/EmptyState';
import { Table } from 'components/Table/Table';
import { TableBody } from 'components/Table/TableBody';
import { TableCell } from 'components/Table/TableCell';
import { TableHeader } from 'components/Table/TableHeader';
import { TableRow } from 'components/Table/TableRow';
import {
  GlobalUserPrincipalRole,
  GlobalUserTableColumn,
} from 'components/UserManagementModals';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingConfig } from 'shared/types/SortingConfig';
import { Role } from 'shared/types/authorization';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';
import { PrincipalTableRow } from './PrincipalTableRow';

interface PrincipalTableContentProps {
  principals: Principal[];
  sortConfig: SortingConfig<GlobalUserTableColumn>;
  initalRoleSelection: GlobalUserPrincipalRole;
  handlePrinciplRightsChange: (
    isChecked: boolean,
    principal: Principal,
  ) => void;
  handleSortingChange: (targetColumn: GlobalUserTableColumn) => void;
}

const sortOrderToShow = (
  targetColumn: string,
  { column, asc }: SortingConfig<GlobalUserTableColumn>,
): 'asc' | 'desc' | undefined => {
  if (column !== targetColumn) return;
  return asc ? 'asc' : 'desc';
};

type TableHeaderCellProps = {
  type: GlobalUserTableColumn;
  displaySortingButton?: boolean;
  align?: 'left' | 'center' | 'right';
  sortConfig: SortingConfig<GlobalUserTableColumn>;
  handleSortingChange?: (targetColumn: GlobalUserTableColumn) => void;
};

const TableHeaderCell = ({
  type,
  displaySortingButton,
  sortConfig,
  align,
  handleSortingChange,
}: TableHeaderCellProps) => {
  const { t } = useTranslation();

  return (
    <TableCell
      noWrap
      displaySortingButton={displaySortingButton}
      smallHeaderTable
      align={align}
      sortOrder={sortOrderToShow(type, sortConfig)}
      onClick={() => {
        handleSortingChange?.(type);
      }}
    >
      {t(`features:global-users:createGlobalUsers:${type}`)}
    </TableCell>
  );
};

export const PrincipalTableContent: React.FC<PrincipalTableContentProps> = ({
  principals,
  sortConfig,
  initalRoleSelection,
  handlePrinciplRightsChange,
  handleSortingChange,
}: PrincipalTableContentProps) => {
  const { t } = useTranslation();

  const isAllChecked = Object.values(initalRoleSelection).find(
    (role) => role === Role.GLOBAL_USER_VIEW,
  );

  const toggleCheckAll = useCallback(
    (isChecked: boolean) => {
      principals.forEach((principal) =>
        handlePrinciplRightsChange(isChecked, principal),
      );
    },
    [handlePrinciplRightsChange, principals],
  );

  if (principals.length === 0) {
    return (
      <div className="my-3">
        <EmptyState
          message={t('features:portal-members:labels:noResults:principals')}
          hasBorder
        />
      </div>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow darkBorder>
          <TableHeaderCell
            type={GlobalUserTableColumn.NAME}
            displaySortingButton
            handleSortingChange={handleSortingChange}
            sortConfig={sortConfig}
          />

          <TableHeaderCell
            type={GlobalUserTableColumn.EDIT}
            sortConfig={sortConfig}
            align="right"
          />
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow noBorder>
          <TableCell justify="end" customYPadding colSpan={2}>
            <Checkbox
              labelPosition="left"
              label={t('features:global-users:createGlobalUsers:editRightsAll')}
              lightGrey
              onChange={toggleCheckAll}
              initialChecked={!isAllChecked}
            />
          </TableCell>
        </TableRow>

        {principals.map((principal: Principal, index: number) => (
          <PrincipalTableRow
            key={index}
            principal={principal}
            initalRoleSelection={
              initalRoleSelection[principal.id] || 'global_user_read'
            }
            onChange={handlePrinciplRightsChange}
          />
        ))}
      </TableBody>
    </Table>
  );
};
