import { PortalUser } from 'hooks/queries/useUser';
import { Permission, Permissions, Role } from 'shared/types/authorization';

/**
 * Merges two sets of permissions into a single set of permissions
 * @param permissions1
 * @param permissions2
 * @returns fusion of two permission sets
 */
export const mergePermissions = (
  permissions1: Permissions,
  permissions2: Permissions,
): Permissions => {
  let permissions = { ...permissions1 };
  if (permissions2) {
    for (const pp in permissions2)
      if (permissions2[pp as Permission])
        permissions = { ...permissions, [pp]: permissions2[pp as Permission] };
  }
  return permissions;
};

// If global user returns global user role for principal (if present) else global user role
// If not global user returns regular user role for principal
export const extractRoleFromUser = (
  currentUserSelectedPrincipal: string,
  portalUser: PortalUser | undefined,
): Role | undefined => {
  if (!portalUser) return;
  const { global_user, ...user } = portalUser;
  if (global_user) {
    const principalRoles = global_user.principalRoles;
    if (principalRoles?.[currentUserSelectedPrincipal])
      return principalRoles[currentUserSelectedPrincipal];
    if (global_user.role) return global_user.role;
    return;
  }
  return user.roles?.[currentUserSelectedPrincipal];
};
