import classNames from 'classnames';
import React from 'react';

import { Heading } from '../Heading';

export type TabProps = {
  tabKey: string;
  title?: string;
  children?: React.ReactNode;
  heading?: string;
};

export const Tab: React.FC<TabProps> = ({ children, heading }: TabProps) => {
  return (
    <div className={classNames({ children: 'pb-4 md:pb-5' })}>
      {heading && (
        <div className="mb-3 flex grow items-center md:mb-0">
          <Heading text={heading} margin={0} level="h2" />
        </div>
      )}
      {children}
    </div>
  );
};
