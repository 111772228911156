import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { LoadingBounce } from 'components/Loading';
import {
  useAuth,
  useCurrentPrincipal,
  useCurrentUser,
  usePowerBI,
  usePrincipals,
} from 'hooks/queries';
import { ReportKey } from 'hooks/queries/usePowerBI';
import { tracking } from 'lib/tracking';
import { Embed, Report, models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logger } from 'shared/Logger';
import { useWindowSize } from 'shared/useWindowSize';
import {
  generatePowerBIEmbedUrl,
  generatePowerBIFilters,
  getEmbedToken,
} from 'utils/firebase/cloud-functions';
import {
  LocalStorageKeys,
  addToLocalStorage,
  getFromLocalStorage,
} from '../../lib/localStorageManager';
import { Notification } from '../../lib/notifications/notifications';
import { isTimestampOlderThanOneHour } from '../../lib/utils';
import {
  ReportPersistence,
  UserData,
} from '../../utils/firebase/collection/firebase-collection-types';

type TokenStorage = {
  [role: string]: {
    [reportGroupCombo: string]: {
      token: string;
      createdTimestamp: number;
    };
  };
};

type ReportSize = {
  height: number;
  width: number;
};

type ReportStatus =
  | 'loading'
  | 'authenticating'
  | 'loadingEmbedInfo'
  | 'rendering'
  | 'settingState'
  | 'error'
  | 'done';

type EmbedConfig = models.IReportEmbedConfiguration;

export const getToken = async (
  reportName: string,
  userEmail: string,
  role: string,
  reportId: string,
  groupId: string,
): Promise<string> => {
  const reportGroupCombo = `${reportId}_${groupId}`;
  let storedTokens = getFromLocalStorage<TokenStorage>(
    LocalStorageKeys.POWER_BI_REPORT_TOKENS,
  );

  if (storedTokens) {
    // If there's a storage token, try to get the value for this principal / report / group combo
    const tokenForRoleReportGroupCombo = storedTokens[role]?.[reportGroupCombo];
    if (
      tokenForRoleReportGroupCombo &&
      !isTimestampOlderThanOneHour(
        tokenForRoleReportGroupCombo.createdTimestamp,
      )
    ) {
      // And if it's not older than 1hr, use it
      return tokenForRoleReportGroupCombo.token;
    }
  } else {
    // If storedTokens doesn't exist yet, let's create it so we can fill it bellow
    storedTokens = {};
  }

  // If we haven't found and returned a valid token, carry on getting a new one
  const token = await getEmbedToken({
    reportName,
    userEmail,
    role,
  });

  // And cache the token for this principal / report / group combo to be used again
  if (!storedTokens[role]) storedTokens[role] = {};
  storedTokens[role][reportGroupCombo] = {
    token: token.data,
    createdTimestamp: new Date().getTime(),
  };
  addToLocalStorage(LocalStorageKeys.POWER_BI_REPORT_TOKENS, storedTokens);

  return `${token.data}`;
};

type PowerBiReportProps = {
  filters?: models.IBasicFilter[];
  headline?: string;
  hideFilters?: boolean;
  onReportLoadingStatusChange?: (reportIsLoaded: boolean) => void;
  reportName: ReportKey;
  reportPage?: string;
  role: string;
  tabTitle?: string;
};

const IFRAME_HEIGHT_DEFAULT = 200 as const;

export const PowerBiReport: React.FC<PowerBiReportProps> = ({
  filters,
  headline,
  hideFilters,
  onReportLoadingStatusChange,
  reportName,
  reportPage,
  role,
  tabTitle,
}) => {
  const auth = useAuth();
  const { user, update: updateUser } = useCurrentUser();
  const { principals } = usePrincipals();
  const { currentPrincipal } = useCurrentPrincipal(user.id);
  const { t } = useTranslation();
  const { data: powerBIConfig } = usePowerBI();
  const windowWidth = useWindowSize()[0];

  const reportDestination: string = {
    dashboard: 'Dashboard',
    operational_performance: 'Operational Performance',
    customer_insights: 'Customer Insights',
  }[reportName];

  const [embedToken, setEmbedToken] = useState<string>();
  const [embedClient, setEmbedClient] = useState<Report>();
  const [powerBiFilters, setPowerBIFilters] = useState<
    models.IBasicFilter[] | undefined
  >();
  const [gotPersistedData, setGotPersistedData] = useState<boolean>();
  const [reportRendered, setReportRendered] = useState<boolean>();
  const [reportReadyToView, setReportReadyToView] = useState<boolean>();
  const [reportStatus, setReportStatus] = useState<ReportStatus>('loading');
  const [embedConfig, setEmbedConfig] = useState<EmbedConfig>();
  const [isSmallViewport, setIsSmallViewport] = useState<boolean>(false);
  const [containerHeight, setContainerHeight] = useState<number>(
    IFRAME_HEIGHT_DEFAULT,
  );

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      user?.reportPersistence?.[currentPrincipal.principalPri]?.[reportName]
    ) {
      setGotPersistedData(true);
    } else {
      setGotPersistedData(false);
    }
  }, [user, reportName, currentPrincipal]);

  useEffect(() => {
    if (gotPersistedData && embedClient && reportRendered) {
      const persistedData =
        user?.reportPersistence?.[currentPrincipal.principalPri]?.[
          reportName
        ] || null;

      if (persistedData !== null) {
        embedClient?.bookmarksManager.applyState(persistedData as string);
        setTimeout(() => {
          setReportReadyToView(true);
        }, 250);
      }
    }
  }, [
    user,
    embedClient,
    gotPersistedData,
    reportRendered,
    reportName,
    currentPrincipal,
  ]);

  const resetReportPersistence = async () => {
    const userData = { ...user } as UserData;

    if (userData.reportPersistence?.[currentPrincipal.principalPri]) {
      delete userData.reportPersistence[currentPrincipal.principalPri];
      if (user) {
        await updateUser({
          reportPersistence: { ...userData.reportPersistence },
        });
      }
    }

    Notification({
      message: t(`components:powerBiReport:notification:reset`),
    });

    tracking.push('interaction', 'Interaction', {
      group: 'Report',
      name: 'Reset',
      label: reportDestination,
    });

    embedClient?.reload();
  };

  const setReportPersistence = async () => {
    const bookmark = await embedClient?.bookmarksManager.capture({
      personalizeVisuals: true,
      allPages: true,
    });

    if (bookmark) {
      bookmark.displayName = `${auth.uid}-${reportName}`;

      const userData = { ...user } as UserData;
      if (!userData.reportPersistence) {
        userData.reportPersistence = {};
      }
      userData.reportPersistence[currentPrincipal.principalPri] = {
        [reportName]: bookmark.state,
      };
      await updateUser({
        reportPersistence: { ...userData.reportPersistence },
      });
    }

    Notification({
      message: t(`components:powerBiReport:notification:save`),
      button: {
        text: t(`components:powerBiReport:notification:undo`),
        onClick: resetReportPersistence,
      },
    });

    tracking.push('interaction', 'Interaction', {
      group: 'Report',
      name: 'Save',
      label: reportDestination,
    });
  };

  const restoreReportBookmark = async () => {
    if (gotPersistedData) {
      const bookmarkState = (user?.reportPersistence as ReportPersistence)[
        currentPrincipal.principalPri
      ][reportName];
      await embedClient?.bookmarksManager.applyState(bookmarkState as string);
      Notification({
        message: t(`components:powerBiReport:notification:restore`),
      });

      tracking.push('interaction', 'Interaction', {
        group: 'Report',
        name: 'Restore Saved',
        label: reportDestination,
      });
    }
  };

  useEffect(() => {
    if (user && ['loading', 'error'].includes(reportStatus)) {
      if (!auth.email) {
        logger('[349] auth.mail not set. returning...');
        return;
      }

      if (!role) {
        logger('[350] role not set. returning...');
        return;
      }

      if (!powerBIConfig) {
        return;
      }

      setReportStatus('authenticating');
      getToken(
        reportName,
        auth.email.toLowerCase(),
        role,
        powerBIConfig.reports[reportName].reportId,
        powerBIConfig.reports[reportName].groupId,
      )
        .then((token: string) => {
          setReportStatus('loadingEmbedInfo');
          setReportStatus('rendering');
          setEmbedToken(token);
        })
        .catch((err) => {
          logger(err);
          setReportStatus('error');
        });
    }
  }, [reportStatus, role, powerBIConfig, user, auth.email, reportName]);

  useEffect(() => {
    if (reportStatus === 'done') {
      onReportLoadingStatusChange?.(true);
    }
  }, [reportStatus, onReportLoadingStatusChange]);

  const getReportSize = useCallback(async (): Promise<ReportSize> => {
    const pages = await embedClient
      ?.getPages()
      .then((pages) => pages.filter((page) => page.isActive));

    if (!pages || pages.length === 0) return { width: 0, height: 0 };
    if (pages.length > 1)
      throw Error(
        'PowerBI Embeds with more than one page are currently not supported.',
      );
    const report = pages[0];
    const { width = 0, height = 0 } = report.defaultSize;
    return { width, height };
  }, [embedClient]);

  const calculateContainerHeight = useCallback(async () => {
    const containerWidth = containerRef.current?.clientWidth;

    const { width: contentWidth, height: contentHeight } =
      await getReportSize();

    if (!(containerWidth && contentHeight && contentWidth))
      return IFRAME_HEIGHT_DEFAULT;

    const scale = reportDestination === 'Dashboard' ? 1.15 : 1;

    const resizedHeightWithAspectRatio = Math.ceil(
      (containerWidth * contentHeight) / contentWidth,
    );

    const scaledAndResizedHeight = resizedHeightWithAspectRatio * scale;
    setContainerHeight(scaledAndResizedHeight);
  }, [getReportSize, reportDestination]);

  const reportEmbedConfig = useCallback(async () => {
    if (!powerBIConfig) return;

    const response = await generatePowerBIEmbedUrl({
      principalPri: currentPrincipal.id,
      report: powerBIConfig.reports[reportName],
      reportName,
      section: tabTitle,
    });

    const config: models.IReportEmbedConfiguration = {
      type: 'report',
      id: powerBIConfig.reports[reportName].reportId,
      embedUrl: response.data,
      accessToken: embedToken,
      tokenType: models.TokenType.Embed,
      pageName:
        reportPage ||
        powerBIConfig.reports[reportName].reportSectionId ||
        undefined,
      settings: {
        layoutType: models.LayoutType.Custom,
        customLayout: {
          displayOption: models.DisplayOption.FitToWidth,
        },
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
        visualRenderedEvents: true,
        background: models.BackgroundType.Transparent,
      },
    };

    setEmbedConfig(config);
  }, [
    currentPrincipal.id,
    embedToken,
    powerBIConfig,
    reportName,
    reportPage,
    tabTitle,
  ]);

  /**
   * Create report config
   */
  useEffect(() => {
    if (!embedToken) {
      logger('[432] oops! Embed token missing. Returning....');
      return;
    }

    if (!powerBIConfig) {
      return;
    }

    reportEmbedConfig();
  }, [embedToken, powerBIConfig, reportEmbedConfig]);

  useEffect(() => {
    const generateFilters = async () => {
      const response = await generatePowerBIFilters({
        filters: filters ?? [],
        principalPri: currentPrincipal.id,
        reportName,
        section: tabTitle,
      });

      setPowerBIFilters(response.data);
    };

    generateFilters();
  }, [currentPrincipal.id, filters, reportName, tabTitle]);

  useEffect(() => {
    setEmbedToken(undefined);
    setEmbedConfig(undefined);
    setReportStatus('loading');
    setEmbedClient(undefined);
    setReportRendered(false);
    setReportReadyToView(false);
  }, [role]);

  useEffect(() => {
    setIsSmallViewport(windowWidth < 800);
  }, [windowWidth]);

  useEffect(() => {
    if (!(reportReadyToView && reportRendered))
      setContainerHeight(IFRAME_HEIGHT_DEFAULT);
    calculateContainerHeight();
  }, [calculateContainerHeight, reportReadyToView, reportRendered]);

  const setEmbedClientHandler = useCallback(
    (embeddedReport: Embed) => {
      setEmbedClient(embeddedReport as Report);
      containerRef.current?.addEventListener('rendered', () => {
        setReportRendered(true);
        if (gotPersistedData) {
          setReportStatus('settingState');
        } else {
          setReportStatus('done');
          setReportReadyToView(true);
        }
      });
      containerRef.current?.addEventListener('error', (event) => {
        setReportStatus('error');
        logger(event);
      });
    },
    [gotPersistedData],
  );

  return (
    <>
      <div className="relative">
        {!reportReadyToView && (
          <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex justify-center bg-white">
            <LoadingBounce
              text={t(`components:powerBiReport:status:${reportStatus}`)}
            />
          </div>
        )}
        {isSmallViewport && (
          <div className="absolute bottom-0 left-0 right-0 top-0 z-20 mx-auto w-full items-center bg-white text-center">
            <div className="border-grey-300 mx-auto mb-4 mt-7 h-8 w-8 rounded-full border-2 border-dashed">
              <Icon name="ContentError" />
            </div>
            <span className="text-grey-600 font-light">
              {t('components:powerBiReport:warning:smallViewport')}
            </span>
          </div>
        )}
        {reportReadyToView && (
          <div className="flex justify-between">
            <div>{headline && <Heading text={headline} level="h2" />}</div>
            {!hideFilters && (
              <div className="flex justify-end">
                <div>
                  <span
                    className="block h-4 w-4 cursor-pointer"
                    onClick={setReportPersistence}
                  >
                    <Icon
                      name="Save"
                      color="grey-700"
                      tooltip={t(`components:powerBiReport:save`)}
                    />
                  </span>
                </div>
                <div className="pl-4">
                  <span
                    className={`block h-4 w-4 ${
                      gotPersistedData ? 'cursor-pointer' : ''
                    }`}
                    onClick={
                      gotPersistedData ? restoreReportBookmark : undefined
                    }
                  >
                    <Icon
                      name="Restore"
                      color={gotPersistedData ? 'grey-700' : 'grey-500'}
                      tooltip={t(`components:powerBiReport:restore`)}
                    />
                  </span>
                </div>
                <div className="pl-4">
                  <span
                    className="block h-4 w-4 cursor-pointer"
                    onClick={resetReportPersistence}
                  >
                    <Icon
                      name="Reset"
                      color="grey-700"
                      tooltip={t(`components:powerBiReport:reset`)}
                    />
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        <div ref={containerRef} style={{ height: `${containerHeight}px` }}>
          {user && principals && embedConfig && powerBiFilters && (
            <PowerBIEmbed
              embedConfig={{
                ...embedConfig,
                filters: powerBiFilters,
              }}
              cssClassName="h-full"
              getEmbeddedComponent={setEmbedClientHandler}
            />
          )}
        </div>
      </div>
    </>
  );
};
