import React from 'react';
import { Heading } from '../../components/Heading';
import { Link } from '../../components/Link';
import { Paragraph } from '../../components/Paragraph';
import { Table } from '../../components/Table/Table';
import { TableBody } from '../../components/Table/TableBody';
import { TableCell } from '../../components/Table/TableCell';
import { TableHeader } from '../../components/Table/TableHeader';
import { TableRow } from '../../components/Table/TableRow';
import './CookieStatement.css';
import { CookiesTableData } from './CookiesTableData';
import { List } from './List';

const CookieStatement: React.FC = () => (
  <div className="max-w-xl-content cookie-statement mx-auto mb-7 w-full flex-row space-y-4 px-4 pt-6 md:mb-9 md:px-6 md:pt-7">
    <Heading level="h1" text="AZELIS PARTNER PORTAL COOKIE STATEMENT" />
    <Paragraph>
      This cookie statement (hereinafter &ldquo;Cookie Statement&ldquo;) governs
      the use of cookies on our partner dedicated portal{' '}
      <Link
        href="https://partner.azelis.com/"
        label="https://partner.azelis.com/"
        target="_blank"
        inline
      />{' '}
      (hereinafter: &ldquo;Azelis Partner Portal&ldquo;).
    </Paragraph>
    <Paragraph>
      Cookies are small text files which are stored on a device&apos;s hard
      drive and which contain certain information and sometimes personal data.
    </Paragraph>
    <Paragraph>
      For the use of your information by third-party cookie providers please
      consult the policy of the providers.
    </Paragraph>
    <Paragraph>
      For the use of your personal information collected by Microsoft cookies,
      please refer to:{' '}
      <Link
        href="https://privacy.microsoft.com/en-us/privacystatement"
        label="https://privacy.microsoft.com/en-us/privacystatement"
        target="_blank"
        inline
      />
    </Paragraph>
    <Paragraph>
      For the use of your personal information collected by Mixpanel cookies,
      please refer to:{' '}
      <Link
        href="https://mixpanel.com/legal/privacy-hub/"
        label="https://mixpanel.com/legal/privacy-hub/"
        target="_blank"
        inline
      />
      .
    </Paragraph>
    <Paragraph>
      For the use of your personal information collected by Google cookies,
      please refer to:{' '}
      <Link
        href="https://policies.google.com/technologies/cookies?hl=en-US"
        label="https://policies.google.com/technologies/cookies?hl=en-US"
        target="_blank"
        inline
      />
      . If you want information on how we process personal data on our other
      websites or in the context of our communications with you, you are kindly
      referred to our general Privacy Statement:
      <Link
        href="https://www.azelis.com/en/privacy-statement"
        label="https://www.azelis.com/en/privacy-statement"
        target="_blank"
        inline
      />
      .
    </Paragraph>
    <Paragraph>
      <b>
        By continuing to use the Azelis Partner Portal after having been
        informed of the use of cookies, you acknowledge that you have read and
        that you accept this Cookie Statement.
      </b>
    </Paragraph>
    <List>
      <li className="mt-7">
        <h2 className="text-grey-700 mb-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
          In general
        </h2>
        <List>
          <li>
            The data controller of the processing of your personal data via
            cookies and social media plugins on the Azelis Partner Portal is
            Azelis Corporate Services NV/SA, Posthofbrug 12 bus 6, 2600 Antwerp,
            Belgium, with company number 0828.239.349, RPR/RPM Antwerp division
            Antwerp (hereinafter: &ldquo;Azelis&rdquo;,&ldquo;we&rdquo;,
            &ldquo;us&rdquo;, &ldquo;our&rdquo;). You can contact us via e-mail
            at{' '}
            <Link
              href="privacy@azelis.com"
              label="privacy@azelis.com"
              target="_blank"
              inline
            />
            .
          </li>
          <li>
            A reference in this Cookie Statement to certain laws or regulations
            also includes any change, replacement or annulment of these laws or
            regulations, including any related executive decisions.
          </li>
          <li>
            Azelis reserves the right to, from time to time, modify, change or
            amend this Cookie Statement at its own discretion. You can always
            consult the latest version of this Cookie Statement on our Azelis
            Portal.
          </li>
        </List>
      </li>
      <li className="mt-7">
        <h2 className="text-grey-700 mb-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
          Cookies
        </h2>
        <List>
          <li>
            You can avoid cookies being placed by configuring your browser as
            such. Guidelines about how to do this can be found here:
            <ul className="ml-3 list-disc">
              <li>
                Internet Explorer:{' '}
                <Link
                  href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                  label="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                  target="_blank"
                  inline
                />
              </li>
              <li>
                Chrome:{' '}
                <Link
                  href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
                  label="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
                  target="_blank"
                  inline
                />
              </li>
              <li>
                Safari:{' '}
                <Link
                  href="https://support.apple.com/kb/ph21411?locale=en_US"
                  label="https://support.apple.com/kb/ph21411?locale=en_US"
                  target="_blank"
                  inline
                />
              </li>
              <li>
                Firefox:{' '}
                <Link
                  href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  label="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                  target="_blank"
                  inline
                />
              </li>
            </ul>
          </li>
          <li>
            When you visit the Azelis Partner Portal, Azelis places a number of
            cookies. Most data pertaining to these cookies, their placement and
            use allow to identify you, and Azelis uses them to gain insight in
            how you and other visitors use the Azelis Portal by gathering
            statistical and usage data, enabling Azelis to provide a better user
            experience on the Azelis Partner Portal.
          </li>
          <li>
            Azelis knows about the following cookies being used on the Azelis
            Portal:
            <Table>
              <TableHeader>
                <TableRow darkBorder>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Via Domain | Purpose</TableCell>
                  <TableCell>Storage Periode</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {CookiesTableData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.domain}</TableCell>
                    <TableCell>{row.storage_period}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </li>
          <li>
            You may at all times withdraw your consent with Azelis&apos; use of
            cookies. To exercise this right, it suffices to delete the cookies,
            which have been placed on your device. To do so, you are kindly
            asked to refer to the appropriate instructions of your browser
            manufacturer, as listed above.
          </li>
        </List>
      </li>
      <li className="mt-7">
        <h2 className="text-grey-700 mb-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
          Purposes for which Azelis uses your personal data
        </h2>
        <List>
          <li>
            Azelis processes your personal data through placement and collection
            of cookies in order to provide you access to your profile and to
            enjoy our Azelis Partner Portal services.
          </li>
          <li>
            Azelis processes your personal data to perform statistical analyses
            so that we may improve our Azelis Partner Portal, advertisement,
            products and services or to develop new products and services.
          </li>
          <li>
            We process your personal data for marketing purposes by us or any
            other entity within the Azelis Group.
          </li>
          <li>
            Azelis may also process your personal data for the preservation of
            the legitimate interests of Azelis, its affiliates and partners or a
            third party if your registration with or use of the Azelis Partner
            Portal can be considered (a) a violation of this Cookie Statement or
            the intellectual property rights or any other right of a third
            party, (b) a threat to the security or integrity of any of the
            Azelis Partner Portal or to Azelis or to any of its affiliates&apos;
            or its subcontractors&apos; underlying systems due to viruses,
            Trojan horses, spyware, malware or any other form of malicious code,
            or (c) in any way hateful, obscene, discriminating, racist
            slanderous, spiteful, hurtful or in some other way inappropriate or
            illegal.
          </li>
          <li>
            Your personal data may be transferred to the police or the judicial
            authorities as evidence or if there are justified suspicions of an
            unlawful act or crime committed by you through your use of the
            Azelis Partner Portal.
          </li>
        </List>
      </li>
      <li className="mt-7">
        <h2 className="text-grey-700 mb-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
          Legal basis for processing your personal data
        </h2>
        <List>
          <li>
            The processing of your personal data for the purposes outlined above
            is based on your consent or a legal obligation that Azelis must
            comply with.
          </li>
          <li>
            In some cases, the processing of your personal data may be necessary
            for purposes of our legitimate interests, such as:
            <ul>
              <li>
                Keeping our Azelis Portal, social media channels, products and
                services safe from misuse and illegal activity.
              </li>
            </ul>
            Note that we ask your consent for the collection of your personal
            data by other cookies than the functional and necessary ones.
          </li>
        </List>
      </li>
      <li className="mt-7">
        <h2 className="text-grey-700 mb-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
          To whom Azelis sends your data
        </h2>
        <List>
          <li>
            Azelis relies on third-party processors who process your personal
            data on our behalf in order to provide you access to our Azelis
            Partner Portal. These third-party processors are only allowed to
            process your personal data on behalf of Azelis and upon explicit
            written instruction of Azelis.
            <br />
            Azelis warrants that all third-party processors are selected with
            due care and are obliged to observe the safety and integrity of your
            personal data.
          </li>
          <li>
            Azelis may share your personal data with other entities within the
            Azelis Group. However, we ensure that all Azelis entities will take
            due care that all processing of your personal data is in line with
            what is set out in this Cookie Statement.
          </li>
          <li>
            Azelis does not send your personal data in an identifiable manner to
            any third party without your explicit permission to do so other
            than:
            <ul>
              <li>to Azelis&apos; third-party processors;</li>
              <li>to other entities within the Azelis Group</li>
            </ul>
          </li>
        </List>
      </li>
      <li className="mt-7">
        <h2 className="text-grey-700 mb-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
          Location and transfer
        </h2>
        <List>
          <li>
            Azelis processes your personal data within the European Economic
            Area (EEA). However, in order to process your personal data for the
            purposes outlined above, we may also transfer your personal data to
            other entities within the Azelis Group or to third parties who
            process on our behalf outside the EEA. Each entity outside the EEA
            that processes your personal data will be bound to observe adequate
            safeguards with regard to the processing of your personal data. Such
            safeguards will be the consequence of:
            <ul>
              <li>
                The recipient country having legislation in place which may be
                considered equivalent to the protection offered within the EEA;
                or
              </li>
              <li>
                A contractual arrangement between Azelis and that entity. All
                Azelis entities are parties to a contractual agreement based on
                the European Commission&apos;s Standard Contractual Clauses
                (controller-to-controller) (Commission Decision C(2004)5721)
                which will be replaced when the European Commission adopts new
                contractual standard clauses in compliance with Article 46, 2,
                c, GDPR.
              </li>
            </ul>
          </li>
          <li>
            Azelis may transfer anonymised and/or aggregated data to
            organisations outside the EEA. Should such transfer take place,
            Azelis will ensure that there are safeguards in place to ensure the
            safety and integrity of your data and all rights with respect to
            your personal data you might enjoy under applicable mandatory law.
          </li>
        </List>
      </li>
      <li className="mt-7">
        <h2 className="text-grey-700 mb-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
          Quality assurances
        </h2>
        <List>
          <li>
            Azelis does its utmost best to process only those personal data
            which are necessary to achieve the purposes mentioned above.
          </li>
          <li>
            Your personal data is only processed for as long as needed to
            achieve the purposes mentioned above or up until such time where you
            withdraw your consent for processing them. Your withdrawal of
            consent may imply that you can no longer use the whole or part of
            our Azelis Partner Portal. Azelis will de-identify your personal
            data when they are no longer necessary for the purposes outlined
            above, unless there is:
            <ul>
              <li>
                An overriding interest of Azelis, or any other third party, in
                keeping our personal data identifiable;
              </li>
              <li>
                A legal or regulatory obligation or a judicial or administrative
                order that prevents Azelis from de-identifying them.
              </li>
            </ul>
          </li>
          <li>
            Azelis will take appropriate technical and organisational measures
            to keep your personal data safe from unauthorised access or theft as
            well as accidental loss, tampering or destruction. Access by
            personnel of Azelis or its third party processors will only be on a
            need-to-know basis and be subject to strict confidentiality
            obligations. You understand, however, that safety and security are
            best-efforts obligations which can never be guaranteed.
          </li>
        </List>
      </li>
      <li className="mt-7">
        <h2 className="text-grey-700 mb-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
          Your rights
        </h2>
        <List>
          <li>
            You have the right to request access to all personal data processed
            by Azelis pertaining to you. Azelis reserves the right to charge an
            administrative fee for multiple subsequent requests for access that
            are clearly submitted for causing nuisance or harm to Azelis. Each
            request will specify for which processing activity you wish to
            exercise your right to access and will specify to which data
            categories you wish to gain access to.
          </li>
          <li>
            You have the right to ask that any personal data pertaining to you
            that is inaccurate, is corrected free of charge. If you submit a
            request for correction, your request needs to be accompanied of
            proof of the flawed nature of the data for which correction is
            asked.
          </li>
          <li>
            You have the right to withdraw your earlier given consent for
            processing of your personal data.
          </li>
          <li>
            You have the right to request that personal data pertaining to you
            is deleted if this data is no longer required in the light of the
            purposes outlined above or if you withdraw your consent for
            processing them. However, you need to keep in mind that a request
            for deletion will be evaluated by Azelis against:
            <ul>
              <li>Overriding interests of Azelis or any other third party;</li>
              <li>
                Legal or regulatory obligations or administrative or judicial
                orders which may contradict such deletion.
              </li>
            </ul>
            Instead of deletion you can also ask that Azelis limits the
            processing of your personal data if (a) you contest the accuracy of
            the data, (b) the processing is illegitimate, or (c) the data is no
            longer needed for the purposes listed above.
          </li>
          <li>
            You have the right to oppose the processing of personal data if you
            are able to prove that there are serious and justified reasons
            connected with these particular circumstances that warrant such
            opposition. However, if the intended processing qualifies as direct
            marketing, you have the right to oppose such processing free of
            charge and without justification.
          </li>
          <li>
            You have the right to receive from us in a structured, commonly-used
            and machine-readable format all personal data you have provided to
            us.
          </li>
          <li>
            If you wish to submit a request to exercise one or more of the
            rights listed above, you can send an e-mail to the Azelis Group’s
            general privacy e-mail address: privacy@azelis.com. A request to
            exercise a right will not be construed as consent with the
            processing of your personal data beyond what is required for
            handling your request. Such request should clearly state which right
            you wish to exercise and the reasons for it if such is required. It
            should also be dated and signed, and accompanied by a digitally
            scanned copy of your valid identity card proving your identity. If
            you use the contact form, Azelis may ask you for your signed
            confirmation and proof of identity. <br />
            Azelis will promptly inform you of having received this request. If
            the request proves valid, Azelis shall honour it as soon as
            reasonably possible and at the latest thirty (30) days after having
            received the request.
          </li>
          <li>
            For questions or complaints regarding the processing of your
            personal data by Azelis, please feel free to contact Azelis via the
            Azelis Group&apos;s general privacy e-mail address:
            privacy@azelis.com. You equally have the right to file a complaint
            with the competent data protection authority.
          </li>
        </List>
      </li>
    </List>
  </div>
);

export default CookieStatement;
