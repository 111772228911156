import { DropdownMultiSelect } from 'components/Dropdown/DropdownVariants/DropdownMultiSelect';
import { DropdownSingleSelect } from 'components/Dropdown/DropdownVariants/DropdownSingleSelect';
import { DropdownOptionProps } from 'components/Dropdown/helper/DropdownTypes';
import { allCountries } from 'country-region-data';
import React from 'react';

export type CountryDropdownProps = {
  initialSelection?: DropdownOptionProps | DropdownOptionProps[];
  multiselect?: boolean;
  placeholder?: string;
  disabled?: boolean;
  typeAhead?: boolean;
  error?: boolean;
  allowUnset?: boolean;
  onChange?: (option: DropdownOptionProps | DropdownOptionProps[]) => void;
};

export const getCountries = (): DropdownOptionProps[] => {
  return allCountries.map(([countryName, countryShortCode]) => {
    return { name: countryName, value: countryShortCode };
  });
};

export const CountryDropdown: React.FC<CountryDropdownProps> = ({
  initialSelection,
  multiselect,
  placeholder,
  disabled,
  typeAhead,
  error,
  allowUnset,
  onChange,
}: CountryDropdownProps) => {
  return multiselect ? (
    <DropdownMultiSelect
      error={error}
      options={getCountries()}
      initialSelection={initialSelection as DropdownOptionProps[]}
      typeAhead={typeAhead}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      widthVariant="full-width"
    />
  ) : (
    <DropdownSingleSelect
      error={error}
      options={getCountries()}
      initialSelection={initialSelection as DropdownOptionProps}
      typeAhead={typeAhead}
      placeholder={placeholder}
      disabled={disabled}
      allowUnset={allowUnset}
      onChange={onChange}
      widthVariant="full-width"
    />
  );
};
