import classNames from 'classnames';
import React, { ReactNode } from 'react';

export type TableRowProps = {
  darkBorder?: boolean;
  noBorder?: boolean;
  children: ReactNode;
  hoverable?: boolean;
  selected?: boolean;
  onClick?: () => void;
};
export const TableRow: React.FC<TableRowProps> = ({
  darkBorder,
  noBorder,
  children,
  hoverable,
  selected,
  onClick,
}: TableRowProps) => {
  return (
    <tr
      data-testid="tableRow"
      className={classNames({
        'border-grey-300 border-b': !noBorder,
        'border-grey-700': darkBorder,
        'hover:bg-grey-100': hoverable,
        'cursor-pointer': !!onClick,
        'bg-grey-100': selected,
      })}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};
