import { User } from 'firebase/auth';
import { RootState } from 'store/root-reducer';
import { GlobalUser, UserData } from 'utils/firebase/collection';

export const selectAuth = (state: RootState): User | null => state.user.auth;

export const selectUserData = (state: RootState): UserData | undefined =>
  state.user.data;

export const selectGlobalUser = (
  state: RootState,
): GlobalUser | undefined | null => state.user.global;

export const isUserLoaded = (state: RootState): boolean => {
  const { auth, data, global } = state.user;
  return !!auth && !!data && global !== undefined;
};
