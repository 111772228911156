import classNames from 'classnames';
import { Alert } from 'components/Alert';
import { Link } from 'components/Link';
import { PowerBiReport } from 'components/PowerBiReport';
import { routes } from 'controllers/ContentController/Routes';
import { useAuth, useCurrentPrincipal, useCurrentUser } from 'hooks/queries';
import { useNewSharedDocumentsCount } from 'hooks/useNewSharedDocumentsCount';
import { usePermission } from 'hooks/usePermission';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Permission } from 'shared/types/authorization';
import { LOCALSTORAGE_PROMO_BANNER_PERFORMANCE } from 'utils/localStorage.utils';
import { Heading } from '../../components/Heading';
import { Icon } from '../../components/Icon';
import { PermissionDenied } from '../../components/PermissionDenied/PermissionDenied';
import { DashboardMap } from './Map/DashboardMap';

export const Dashboard: React.FC = () => {
  const [isPerformancePromoVisible, setIsPerformancePromoVisible] =
    useState(true);
  const { user, update } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(user.id);
  const navigate = useNavigate();
  const { operationalPerformanceRoute } = routes(currentPrincipal.slug);
  const { t } = useTranslation();
  const { userHasPermission } = usePermission();

  const auth = useAuth();
  const newSharedDocumentsCount = useNewSharedDocumentsCount();

  useEffect(() => {
    if (!user || user?.signedIn) return;
    update({ signedIn: true });
  }, [user, update]);

  useEffect(() => {
    try {
      const hidePromoBanner: boolean = JSON.parse(
        localStorage.getItem(LOCALSTORAGE_PROMO_BANNER_PERFORMANCE) ?? '',
      );
      setIsPerformancePromoVisible(hidePromoBanner);
    } catch (error) {
      setIsPerformancePromoVisible(true);
    }
  }, []);

  return (
    <div className="w-full">
      {userHasPermission(Permission.DASHBOARD_VIEW) ? (
        <>
          <div className="bg-grey-100 w-full px-4 md:px-6">
            <div className="max-w-xl-content mx-auto">
              <div className="mb-4 pt-6 md:pt-7" data-test="page-header">
                <div className="flex flex-col items-baseline justify-between md:flex-row md:space-x-2">
                  <Heading
                    text={t('features:dashboard:heading')}
                    margin={0}
                    level="h1"
                  />
                </div>
                {isPerformancePromoVisible && (
                  <div className="my-4 w-full">
                    <Alert
                      flavour="promo"
                      headline={t('components:promoBannerPerformance:heading')}
                      icon="Performance"
                      iconSize="small"
                      message={t(
                        'components:promoBannerPerformance:description',
                      )}
                      buttonLabel={t(
                        'components:promoBannerPerformance:buttonLabel',
                      )}
                      buttonSize="small"
                      onClick={() => navigate(operationalPerformanceRoute.url)}
                      onCancel={() => {
                        //TODO: This needs to be fixed as part of AZEPP-1169.
                        localStorage.removeItem('pp_promo_banner');
                        localStorage.setItem(
                          LOCALSTORAGE_PROMO_BANNER_PERFORMANCE,
                          JSON.stringify(false),
                        );
                        setIsPerformancePromoVisible(false);
                      }}
                    />
                  </div>
                )}
                <div className="text-grey-700 mt-1">
                  {auth.displayName
                    ? t('features:dashboard:welcome', {
                        name: user?.name,
                      })
                    : t('features:dashboard:welcome_no_name')}
                  {!!newSharedDocumentsCount &&
                    t('features:dashboard:updated_files_intro')}
                </div>
                {!!newSharedDocumentsCount && (
                  <div className="mt-1 flex leading-5">
                    <div className="mr-2 h-4 w-4">
                      <Icon name="File" />
                    </div>
                    <div className="text-grey-700 mr-3">
                      {t('features:dashboard:updated_files_count', {
                        count: newSharedDocumentsCount,
                      })}
                    </div>
                    <Link
                      variant="compact"
                      label={t('features:dashboard:linkLabel').toUpperCase()}
                      icon="ArrowRight"
                      iconPosition="right"
                    />
                  </div>
                )}
              </div>
              <div>
                {currentPrincipal && (
                  <div className="flex flex-col">
                    <PowerBiReport
                      headline={t('features:reports:key_sales_numbers')}
                      reportName="dashboard"
                      role={currentPrincipal.manufacturerName}
                      hideFilters={true}
                    />
                    <div className="self-end pb-4 pr-3">
                      <Link
                        type="router"
                        icon="ArrowRight"
                        iconPosition="right"
                        inline={true}
                        variant="compact"
                        href={operationalPerformanceRoute.url}
                        label={t(
                          'features:dashboard:linkLabelReport',
                        ).toUpperCase()}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full bg-white px-4 md:px-6">
            <div
              className={classNames('max-w-xl-content mx-auto', {
                'pt-8': !isPerformancePromoVisible,
                'pt-5': isPerformancePromoVisible,
              })}
            >
              <DashboardMap selectedPrincipal={currentPrincipal} />
            </div>
          </div>
        </>
      ) : (
        <PermissionDenied />
      )}
    </div>
  );
};
