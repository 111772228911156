import { toast } from 'react-hot-toast';

type NotificationProps = {
  message: string;
  button?: {
    text: string;
    onClick: () => void;
  };
};

export const Notification = ({ button, message }: NotificationProps): string =>
  toast.custom(
    (currentToast) => (
      <div className="w-300px bg-grey-700 pointer-events-auto max-w-sm overflow-hidden rounded-lg text-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-2">
          <div className="flex items-center">
            <div className="flex w-0 flex-1 justify-between">
              <p className="w-0 flex-1 text-sm font-medium">{message}</p>
              {button ? (
                <button
                  className="ml-3 flex-shrink-0 rounded-md bg-transparent text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    if (button.onClick) {
                      button.onClick();
                    }
                    toast.remove(currentToast.id);
                  }}
                >
                  {button.text}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    ),
    { duration: 4000, position: 'bottom-center' },
  );
