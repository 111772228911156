import { AccountInfo } from '@azure/msal-browser';
import {
  NextOrObserver,
  onAuthStateChanged,
  signInWithCustomToken,
  Unsubscribe,
  updateProfile,
  User,
} from 'firebase/auth';
import { setUserDisplayName } from './cloud-functions';
import { auth } from './firebase-config';

export const onAuthStateChangedListener = (
  callback: NextOrObserver<User>,
): Unsubscribe => onAuthStateChanged(auth, callback);

export const signInWithToken = async (firebaseToken: string): Promise<void> => {
  signInWithCustomToken(auth, firebaseToken).catch(console.error);
};

export const setDisplayName = async (
  account: AccountInfo | null,
  currentPrincipal?: string,
): Promise<void> => {
  if (account) await setUserDisplayName(currentPrincipal || '');

  if (!account?.name) return;

  const hasDisplayName = auth.currentUser?.displayName;

  if (!hasDisplayName && auth.currentUser) {
    await updateProfile(auth.currentUser, { displayName: account?.name });
  }

  return;
};

export const signOut = async (): Promise<void> => {
  await auth.signOut();
};
