import { UpdateData } from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Role } from 'shared/types/authorization';
import {
  isUserLoaded,
  selectGlobalUser,
  selectUserData,
} from 'store/user/user.selector';
import { extractRoleFromUser } from 'utils/authorization.utils';
import { UserData } from 'utils/firebase/collection';
import { docRef, updateDocument } from 'utils/firebase/firebase-utils';
import { useAuth } from './useAuth';
import { useCurrentPrincipal } from './useCurrentPrincipal';
import { useRoleDefinitions } from './useRoleDefinitions';
import { PortalUser, UseUserResult } from './useUser';

export type UseCurrentUserResult = UseUserResult & { isLoaded: boolean };

export const useCurrentUser = (): UseCurrentUserResult => {
  const auth = useAuth();
  const roleDefinitions = useRoleDefinitions();
  const userData = useSelector(selectUserData);
  const global = useSelector(selectGlobalUser);
  const isLoaded = useSelector(isUserLoaded);

  const user = useMemo(
    () =>
      ({
        ...userData,
        id: auth?.uid,
        global,
      } as PortalUser),
    [auth, global, userData],
  );
  const { currentPrincipal } = useCurrentPrincipal(user.id);

  const update = useCallback(
    async (user: Partial<PortalUser>) => {
      const promises = [];

      promises.push(
        updateDocument(
          docRef('users', auth?.uid),
          user as UpdateData<UserData>,
        ),
      );

      await Promise.all(promises);
    },
    [auth],
  );

  return useMemo(() => {
    const role = extractRoleFromUser(currentPrincipal.id, user) ?? Role.NONE;
    const userType = roleDefinitions[role]?.user_type;
    const isGlobalUser = !!global;

    const currentUser = { user, role, isGlobalUser, update, userType };
    return { ...currentUser, isLoaded };
  }, [currentPrincipal.id, user, roleDefinitions, global, update, isLoaded]);
};
