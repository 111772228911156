import { DocumentReference, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import {
  CollectionType,
  FirebaseCollections,
} from '../collection/firebase-collection';
import { docRef } from '../firebase-utils';

export type Document<T> = {
  data: T | undefined;
  exists: boolean;
  ref: DocumentReference<T>;
};

/**
 * @param collection name of the firebase collection
 * @param uid unique id of a document in the collection
 * @returns a real time snapshot of the document
 */
export const useDocument = <
  T extends FirebaseCollections,
  CT = CollectionType<T>,
>(
  collection: T,
  uid: string,
): Document<CT> => {
  const [document, setDocument] = useState<Document<CT>>({} as Document<CT>);

  useEffect(() => {
    const ref: DocumentReference<CT> = docRef(collection, uid);
    const unsub = onSnapshot(ref, (snap) => {
      const data = snap.data();
      const exists = snap.exists();
      const ref = snap.ref;
      setDocument({ data, exists, ref });
    });
    return () => unsub();
  }, [collection, uid]);

  return document;
};
