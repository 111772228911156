import classNames from 'classnames';
import { Button, ButtonType } from 'components/Button';
import { Heading } from 'components/Heading';
import { Icon, IconName } from 'components/Icon';
import { FC } from 'react';

export type TileButtonWithIconProps = {
  description: string;
  iconName: IconName;
  title: string;
  iconSubmit?: IconName;
  onClick?: () => void;
  teaserMessage?: string;
};

export const TileButtonWithIcon: FC<TileButtonWithIconProps> = ({
  description,
  iconName,
  iconSubmit,
  title,
  onClick,
  teaserMessage,
}) => {
  return (
    <div
      role="button"
      data-testid="tile-click"
      onClick={onClick}
      className={classNames(
        'border-grey-300 height:371px max-w-376px relative mx-1 mt-5 box-border flex flex-1 flex-col items-center justify-center border px-2',
        { 'cursor-default opacity-50': teaserMessage },
        { 'hover:shadow-4dp cursor-pointer': !teaserMessage },
      )}
    >
      <div className="flex justify-center">
        <div className="-mb-1 -mt-5 bg-white px-5">
          <Icon name={iconName} />
        </div>
      </div>

      <div className="mt-5">
        <Heading text={title} margin={0} level="h3" align="center" breakWords />
      </div>

      <div className="mt-2 flex grow flex-col items-center">
        <div className="text-grey-600 grow flex-wrap px-3 text-center">
          <p>{description}</p>
        </div>

        <div className="my-3 justify-end">
          {teaserMessage ? (
            <div className=" flex h-7 items-center justify-center text-xs font-medium uppercase tracking-widest text-violet-400">
              {teaserMessage}
            </div>
          ) : (
            <Button buttonType={ButtonType.ICON} allowHover={false}>
              <div className="h-4 w-4">
                <Icon name={iconSubmit || 'ArrowRight'} />
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
