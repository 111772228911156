import {
  CollectionReference,
  DocumentReference,
  onSnapshot,
  query,
  QueryConstraint,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import {
  CollectionType,
  FirebaseCollections,
} from '../collection/firebase-collection';
import { colRef } from '../firebase-utils';

export type Collection<T> = CollectionItem<T>[];

export type CollectionItem<T> = {
  id: string;
  data: T;
  ref: DocumentReference<T>;
};

export const useCollection = <
  T extends FirebaseCollections,
  CT = CollectionType<T>,
>(
  collectionName: T,
  queryConstraints?: QueryConstraint[],
): Collection<CT> | undefined => {
  const [coll, setColl] = useState<Collection<CT> | undefined>();

  useEffect(() => {
    const ref: CollectionReference<CT> = colRef(collectionName);
    const q = query(ref, ...(queryConstraints ?? []));
    const unsub = onSnapshot(q, (snap) => {
      const resultArray: Collection<CT> = [];
      snap.forEach((doc) =>
        resultArray.push({ id: doc.id, data: doc.data(), ref: doc.ref }),
      );
      setColl(resultArray);
    });
    return () => unsub();
  }, [collectionName, queryConstraints]);

  return coll;
};
