import { Document, useDocument } from 'utils/firebase/hooks';

type ReportConfig = {
  datasetId: string;
  groupId: string;
  reportId: string;
  reportSectionId: string;
};

export type ConfigPowerBI = {
  reports: Reports;
};

type Reports = {
  [key in ReportKey]: ReportConfig;
};

export type ReportKey =
  | 'dashboard'
  | 'operational_performance'
  | 'customer_insights';

export const usePowerBI = (): Document<ConfigPowerBI> => {
  return useDocument('configurations', 'powerbi');
};
