import classNames from 'classnames';
import { ChangeEvent } from 'react';
import './RadioButton.css';

export type RadioButtonProps = {
  label: string;
  value: string | number;
  groupName?: string;
  isFullWidth?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  hasBorder?: boolean;
};

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  value,
  groupName,
  isFullWidth,
  onChange,
  hasBorder,
}) => (
  <label
    className={classNames('radio-container', {
      'with-border': hasBorder,
      'flex-1': isFullWidth,
    })}
  >
    <input type="radio" value={value} name={groupName} onChange={onChange} />
    <span className="radio-checkmark"></span>
    {label}
  </label>
);
