import { Document, useDocument } from 'utils/firebase/hooks';

export type PerformanceTabConfigKeys =
  | 'Sales'
  | 'Opportunities'
  | 'Supplychain';

export type OpPerfTabConfigs = {
  [tabKey in PerformanceTabConfigKeys]: {
    title: string;
    reportPage: string;
    disableMarketsegments: boolean;
  };
};

export const useOpPerfTabConfigs = (): Document<OpPerfTabConfigs> => {
  return useDocument('configurations', 'operational_performance_tabs');
};
