import { User } from 'firebase/auth';
import { useSelector } from 'react-redux';
import { selectAuth } from 'store/user/user.selector';

export const useAuth = (): User => {
  const auth = useSelector(selectAuth);
  if (auth == null)
    throw Error(
      'Error: Trying to get auth object but no user is authenticated!',
    );
  return auth;
};
