import classNames from 'classnames';
import React, { MouseEvent, ReactNode } from 'react';
import { Link } from '../Link';

export type TileProps = {
  children: ReactNode;
  headline: string;
  fullHeight?: boolean;
  link?: {
    label: string;
    href: string;
  };
  onClick?(e: MouseEvent<HTMLDivElement>): void;
  fullWidth?: boolean;
};

export const Tile: React.FC<TileProps> = ({
  fullHeight,
  children,
  headline,
  link,
  onClick,
}: TileProps) => (
  <div className={classNames('w-full md:px-2', { 'h-full': fullHeight })}>
    <div
      className={classNames('rounded-4px border-grey-300 h-full border', {
        'hover:shadow-2dp cursor-pointer': onClick || link,
      })}
      onClick={onClick}
      data-testid="tile-outer"
    >
      <div
        className={classNames(
          'rounded-4px flex flex-col justify-between bg-white p-4',
          { 'h-full': fullHeight },
        )}
      >
        <div>
          <div className="mb-3">
            <span className="text-2xs font-sans uppercase tracking-widest">
              {headline}
            </span>
          </div>
          {children}
        </div>
        <div className="flex justify-end pt-1">
          {link && (
            <Link
              icon="ArrowRight"
              iconPosition="right"
              variant="compact"
              label={link.label.toUpperCase()}
              href={link.href}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);
