import React, { ReactNode } from 'react';

export type ListProps = {
  children: ReactNode;
  unordered?: boolean;
};
export const List: React.FC<ListProps> = ({
  children,
  unordered,
}: ListProps) => {
  if (unordered) return <ul className="ml-3 list-disc">{children}</ul>;
  return <ol className="list-inside">{children}</ol>;
};
