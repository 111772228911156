export const LOCALSTORAGE_CONFIG_KEY = 'pp_';
export const LOCALSTORAGE_PROMO_BANNER_PERFORMANCE =
  'pp_promo_banner_performance';

/**
 * Generate hash for strings
 * @param text input string
 * @returns hash value of {text}-string
 */
const generateHash = (text: string): number => {
  let hash = 0,
    i,
    chr;
  if (text.length === 0) return hash;
  for (i = 0; i < text.length; i++) {
    chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Gets principalId from local storage
 * @param userId userId of current user, so the stored prinicpalId is assignable to a specific user if more than one user is working with the portal on the same machine.
 * @returns principalId | null (depending on whether a principal has been saved already)
 */
export const getPrincipalSlugFromLocalStorage = (
  userId: string,
): string | undefined => {
  try {
    const principalSlug = localStorage.getItem(
      LOCALSTORAGE_CONFIG_KEY + generateHash(userId),
    );

    if (typeof principalSlug === 'string' && JSON.parse(principalSlug)) {
      return JSON.parse(principalSlug)?.currentPrincipal;
    }
  } catch (e) {
    console.warn(
      'LocalStorage is not available. The last selected principal cannot be read from LocalStorage.',
    );
  }
  return undefined;
};

/**
 * Stores principalId to local storage
 * @param userIduserId of current user, so the stored prinicpalId is assignable to a specific user if more than one user is working with the portal on the same machine.
 * @param principalId of current principal
 */
export const storePrincipalIdSlugLocalStorage = (
  userId: string,
  principalSlug: string,
): void => {
  try {
    localStorage.setItem(
      LOCALSTORAGE_CONFIG_KEY + generateHash(userId),
      JSON.stringify({ currentPrincipal: principalSlug }),
    );
  } catch (e) {
    console.warn(
      'LocalStorage is not available. The last selected principal cannot be stored in the LocalStorage.',
    );
  }
};
