import React from 'react';
import { Heading } from '../../components/Heading';

const TermsOfUse: React.FC = () => (
  <div className="max-w-xl-content mx-auto mb-7 w-full flex-row px-4 pt-6 md:mb-9 md:px-6 md:pt-7">
    <Heading level="h1" text="PRINCIPAL PORTAL TERMS OF USE" />
    <p className="mb-4">
      Please read and agree to the Terms of Use below before accessing the
      Principal Portal.
    </p>

    <h2 className="text-grey-700 mb-4 mt-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
      1. Acknowledgement of ability/authority to agree
    </h2>
    <p className="mb-4">
      By accessing and/or using any part of the Principal Portal(s), you are
      entering into a legally binding agreement/contract with Azelis Group NV,
      individually and on behalf of all other persons and entities for
      whom/which you act or purport to act, including, without limitation, your
      employer(s), which agreement/contract consists of all terms and conditions
      set forth in these Terms of Use, and that you understand and agree that
      you and all persons and entities for whom/which you act or purport to act
      must comply with them and are legally bound thereby.
    </p>
    <p className="mb-4">
      If/to the extent that you access and/or use, or purport to access and/or
      use, any part of the Principal Portal(s) on behalf of one or more other
      persons or entities, including, without limitation, your employer(s),
      then, by accessing and/or using any part of the Principal Portal(s), you
      affirm that you are authorized to do so and otherwise to act on behalf of
      such other person(s) and entity(ies), to the effect that such other
      person(s) and entity(ies) are entering into a legal agreement/contract
      with Azelis Group NV consisting of all terms and conditions set forth in
      these Terms of Use (including, again and without limitation, those
      incorporated by reference herein). As a result, for example, if you access
      and/or use any part of the Principal Portal(s) as part of your employment,
      the person(s) and entity(ies) by which you are employed also shall be
      responsible for your and their compliance with these Terms of Use, and
      such person(s) and entity(ies) by which you are employed also shall assume
      and undertake the performance of all obligations set forth in these Terms
      of Use; and, in turn, Azelis Group NV also will pursue claims based on
      your and their non-compliance with these Terms of Use, and otherwise
      enforce its rights hereunder, against such person(s) and entity(ies) by
      which you are employed. Any and all references to “you” hereinbelow shall
      refer collectively and interchangeably to you, individually/personally,
      and to each such other person and entity.
    </p>
    <h2 className="text-grey-700 mb-4 mt-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
      2. Right to access/use the principal portal(s)
    </h2>
    <p className="mb-4">
      Subject to the entirety of these Terms of Use, Azelis Group NV grants to
      you a limited, non-exclusive, non- transferable, non-sublicensable,
      revocable license to access/use certain parts of the Principal Portal(s).
      You shall not attempt any access to/use of any part of the Principal
      Portal(s) beyond that/those clearly and unequivocally authorized and
      intended by Azelis Group NV. You shall not permit or otherwise enable any
      other person or entity to use your username or password in order to
      access/use any part of the Principal Portal(s), or for any other purpose.
    </p>

    <h2 className="text-grey-700 mb-4 mt-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
      3. Confidentiality
    </h2>
    <p className="mb-4">
      All Confidential Information of Azelis Group NV or its affiliated
      companies shall remain the exclusive property of Azelis Group NV or its
      affiliated companies, and no right, title or interest in or to any of the
      Confidential Information or any material developed therefrom is
      transferred to you or your employer.
    </p>
    <p className="mb-4">
      For all purposes of these Terms of Use, the term “Confidential
      Information” shall collectively refer to all non-public information or
      material disclosed or provided by Azelis Group NV or its affiliated
      companies to you through your access to the Principal Portal(s), including
      without limitation, any information or material pertaining to products,
      formulae, specifications, designs, processes, plans, policies, procedures,
      employees, work conditions, legal and regulatory affairs, assets,
      inventory, discoveries, trademarks, patents, manufacturing, packaging,
      distribution, sales, marketing, expenses, financial statements and data,
      customer and supplier lists, raw materials, costs of goods and
      relationships with third parties.
    </p>

    <h2 className="text-grey-700 mb-4 mt-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
      4. Ownership rights
    </h2>
    <p className="mb-4">
      Subject only to the very limited rights expressly granted to you above,
      Azelis Group NV, and, to the extent applicable, its third-party licensors
      (which include, without limitation, Azelis Group NV’s affiliated
      companies), reserve(s) all ownership, intellectual property and other
      rights, title and interest in and to the Principal Portal(s). In addition,
      unless expressly stated otherwise in a written agreement executed by
      Azelis Group NV or any of its affiliated companies, Azelis Group NV shall
      own all rights to any data available within or generated by your access to
      or use of the Principal Portal(s). Without limiting the foregoing, Azelis
      Group NV shall have a royalty-free, worldwide, transferable,
      sublicensable, irrevocable, perpetual license to use or incorporate into
      the Principal Portal(s) any suggestions, enhancement requests,
      recommendations or other feedback provided by you relating to the
      Principal Portal(s).
    </p>
    <p className="mb-4">
      You may download material displayed on the Principal Portal(s) only for
      commercial and professional use regarding the commercial relationship your
      employer has with Azelis Group NV or any of its affiliated companies and
      provided you respect and keep intact all copyright, trademark and other
      proprietary notices. You may not copy, reproduce, republish, upload, post,
      transmit or distribute in any way the contents of the Principal Portal(s)
      including the text, images, audio and video for either public or
      commercial use or communication, without prior written permission from
      Azelis Group NV.
    </p>
    <p className="mb-4">
      Nothing contained on the Principal Portal(s) may be construed as granting,
      by implication, or otherwise, any license or right to use any of the
      intellectual property displayed on the Principal Portal(s) without the
      prior written permission of Azelis Group NV.
    </p>

    <h2 className="text-grey-700 mb-4 mt-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
      5. Access/Use as is and without warranty; Limitation of liability
    </h2>
    <p className="mb-4">
      Any and all rights to access or use of the Principal Portal(s) and the
      data available herein that Azelis Group NV grants to you hereunder are “as
      is”. Neither Azelis Group NV, nor, to the extent applicable, its
      affiliated companies or third-party licensors, make any representation or
      warranty whatsoever, express or implied, regarding the Principal Portal(s)
      (or your access thereto or use thereof), the data available herein, or
      otherwise, including, without limitation, any warranty of design, fitness
      for purpose, merchantability, noninfringement, title, or any warranty or
      statutory rights that may, or may be alleged to, arise from a course of
      dealing, course of performance or industry/trade usage, and, further,
      Azelis Group NV expressly disclaims any and all such warranties or
      statutory rights to the extent permissible under applicable law.
    </p>
    <p className="mb-4">
      Except to the extent prohibited under applicable law, neither Azelis Group
      NV nor its affiliated companies shall be liable or otherwise responsible
      to you or any third party for any damage/loss resulting directly or
      indirectly from or otherwise related to the Principal Portal(s)
      (including, without limitation, your access or use thereof or inability to
      access or use same, or the performance or non-performance thereof),
      including, for example, but without limiting the foregoing, any special,
      indirect, incidental, consequential, punitive or exemplary damages based
      on claims related to the Principal Portal(s), regardless of the theory of
      liability, even if Azelis Group NV has been expressly advised of the
      possibility of such damages, or for any loss of revenues, loss of profits,
      loss of business, loss of data or infringement arising from or relating to
      your access to or use of, or inability to access or use, the Principal
      Portal(s), or the performance or nonperformance thereof. In no event shall
      Azelis Group NV’s and/or its affiliates companies’ aggregate/total
      liability to you in relation to the Principal Portal(s) (including,
      without limitation, your access or use thereof or inability to access or
      use same, or the performance or non-performance thereof) exceed EUR 500.00
      or an equivalent amount in another currency.
    </p>
    <p className="mb-4">
      You agree to indemnify, defend and hold harmless Azelis Group NV and all
      its affiliated companies for/from any and all losses arising from or
      related to your granting access to/use of the Principal Portal(s) to third
      parties, intentionally or unintentionally, including through the
      unauthorized use of your log-in credentials or arising from or related to
      your accessing/using the Principal Portal(s) contrary to or beyond your
      rights hereunder, or otherwise arising from or relating to your use/access
      of/to the Principal Portal(s).
    </p>

    <h2 className="text-grey-700 mb-4 mt-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
      6. Modification of Terms of Use and termination
    </h2>
    <p className="mb-4">
      Azelis Group NV reserves the right to, at any time, (i) modify these Terms
      of Use without advance notice (except that advance notice will be provided
      where required by applicable law), (ii) discontinue or otherwise modify
      any part of the Principal Portal(s), including your access thereto/use
      thereof and/or (iii) terminate these Terms of Use and your access to and
      use of the Principal Portal(s) without advance notice (except that advance
      notice will be provided where required by applicable law), with or without
      cause and regardless of whether or not based on a specific reservation of
      the right to terminate stated elsewhere herein, and Azelis Group NV shall
      not be liable to you or any third party for any of the foregoing.
    </p>
    <p className="mb-4">
      Modification of these Terms of Use by Azelis Group NV will be effectuated
      by updating them and, where required by applicable law, providing advance
      notice of such modification. Such modification will be effective
      immediately upon update, and your continued use of any part of the Azelis
      Group NV thereafter shall constitute your acceptance of those
      modifications. Thus, you should access and review these Terms of Use
      regularly.
    </p>
    <p className="mb-4">
      Neither expiration nor termination of these Terms of Use shall terminate
      your obligations or Azelis Group NV’s rights under these Terms of Use
      which, by their nature, are intended to survive such expiration or
      termination.
    </p>

    <h2 className="text-grey-700 mb-4 mt-4 inline-block text-left font-sans text-3xl font-semibold leading-7 md:text-5xl md:leading-9">
      7. Governing law and dispute resolution
    </h2>
    <p className="mb-4">
      These Terms of Use, any and all other terms and conditions governing your
      access to or use of the Principal Portal(s) and your access to and use of
      the Principal Portal(s) in general shall be construed under/governed by
      the laws of Belgium, without regard to the conflict of laws rules of that
      jurisdiction or any other jurisdiction.
    </p>
    <p className="mb-7">
      Any dispute or controversy relating to these Terms of Use, any and all
      other terms and conditions governing your access to or use of the
      Principal Portal(s) or your access to and use of the Principal Portal(s)
      in general, that cannot be resolved amicably shall be resolved by the
      competent courts of Antwerp (Belgium).
    </p>
    <p>
      <b>Version: </b> 1.0
    </p>
    <p>
      <b>Date: </b> April 2024
    </p>
  </div>
);

export default TermsOfUse;
