import { QuerySnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPrincipalsData } from 'store/principals/principals.selector';
import { Principal } from '../../utils/firebase/collection/firebase-collection-types';

export type Principals = {
  [id: string]: Principal;
};
export const usePrincipals = (): { principals: Principals } => {
  const principals = useSelector(selectPrincipalsData);
  return useMemo(() => ({ principals: { ...principals } }), [principals]);
};

export const convertQuerySnapshotToPrincipals = (
  principalsData: QuerySnapshot<Principal>,
): Principals => {
  return principalsData.docs.reduce((acc: Principals, principalDoc) => {
    acc[principalDoc.id] = {
      ...principalDoc.data(),
      id: principalDoc.id,
    };
    return acc;
  }, {} as Principals);
};
