import { User } from 'firebase/auth';
import { GlobalUser, UserData } from 'utils/firebase/collection';
import { ActionWithPayload, createAction } from 'utils/reducer.utils';
import { UserActionType } from './user.types';

export type UserAction =
  | SetAuthAction
  | SetUserDataAction
  | SetGlobalUserAction;

type SetAuthAction = ActionWithPayload<UserActionType.SET_AUTH, User | null>;

type SetUserDataAction = ActionWithPayload<
  UserActionType.SET_USER_DATA,
  UserData | undefined
>;

type SetGlobalUserAction = ActionWithPayload<
  UserActionType.SET_GLOBAL_USER,
  GlobalUser | undefined
>;

export const setAuth = (payload: User | null): SetAuthAction =>
  createAction(UserActionType.SET_AUTH, payload);

export const setUserData = (payload: UserData | undefined): SetUserDataAction =>
  createAction(UserActionType.SET_USER_DATA, payload);

export const setGlobalUser = (
  payload: GlobalUser | undefined,
): SetGlobalUserAction => createAction(UserActionType.SET_GLOBAL_USER, payload);
