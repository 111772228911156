import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  Query,
  QuerySnapshot,
  setDoc,
  UpdateData,
  updateDoc,
} from 'firebase/firestore';
import {
  CollectionType,
  FirebaseCollections,
} from './collection/firebase-collection';
import { db } from './firebase-config';

export const docRef = <T extends FirebaseCollections, CT = CollectionType<T>>(
  collection: T,
  uid: string,
): DocumentReference<CT> => {
  return doc(db, collection, uid) as DocumentReference<CT>;
};

export const colRef = <T extends FirebaseCollections, CT = CollectionType<T>>(
  collectionName: T,
): CollectionReference<CT> => {
  return collection(db, collectionName) as CollectionReference<CT>;
};

export const getDocument = async <T>(
  docRef: DocumentReference<T>,
): Promise<DocumentSnapshot<T>> => {
  return await getDoc(docRef);
};

export const updateDocument = async <T>(
  docRef: DocumentReference<T>,
  partial: UpdateData<T>,
): Promise<void> => {
  return await updateDoc(docRef, partial);
};

export const setDocument = async <T>(
  docRef: DocumentReference<T>,
  data: T,
): Promise<void> => {
  return await setDoc(docRef, data);
};

export const deleteDocument = async <T>(
  docRef: DocumentReference<T>,
): Promise<void> => {
  return await deleteDoc(docRef);
};

export const getCollection = async <T>(
  query: Query<T>,
): Promise<QuerySnapshot<T>> => {
  return await getDocs(query);
};

export const addToCollection = async <T>(
  colRef: CollectionReference<T>,
  objectToAdd: T,
): Promise<DocumentReference<T>> => {
  return await addDoc<T>(colRef, objectToAdd);
};

/**
 * Splits a large array into multiple smaller arrays
 * @param values Valid string or number array
 * @returns values split into batches of 10 elements
 */
export const batchArray = <T>(values: T[]): T[][] => {
  const result = [];
  let batch = [];
  for (let i = 0; i < values.length; i++) {
    batch.push(values[i]);
    if ((i + 1) % 3 === 0 || i === values.length - 1) {
      result.push(batch);
      batch = [];
    }
  }

  return result;
};
