import { cloneElement, ReactElement } from 'react';
import { DropdownOptionProps } from './DropdownTypes';

export const displayAsIntermediate = (
  option: DropdownOptionProps,
  state: DropdownOptionProps[],
): boolean => {
  return (
    isParentActive(option, state) &&
    !option?.childOptions?.every((optionToCheck) =>
      state?.some(
        (currentlySelectedOption) =>
          currentlySelectedOption.value === optionToCheck.value,
      ),
    )
  );
};

export const removeSelection = (
  option: DropdownOptionProps | DropdownOptionProps[],
  oldState: DropdownOptionProps[],
): DropdownOptionProps[] => {
  let newArray;
  if (Array.isArray(option)) {
    newArray = oldState.filter(
      (element) => !option.find((opt) => opt.value === element.value),
    );
  } else {
    newArray = oldState.filter((element) => element.value !== option.value);
  }
  return newArray;
};

export const getAllChildren = (
  children: DropdownOptionProps[],
): DropdownOptionProps[] => {
  return children.flatMap((child) =>
    child.childOptions ? child.childOptions : child,
  );
};

export const isParentActive = (
  option: DropdownOptionProps,
  state: DropdownOptionProps[],
): boolean => {
  // why does this work?
  const children = option.childOptions && getAllChildren(option.childOptions);
  return !!children?.some((optionToCheck) =>
    state.find(
      (selectedOption) => selectedOption.value === optionToCheck.value,
    ),
  );
};

export const isOptionActive = (
  value: string | number,
  state: DropdownOptionProps[],
): boolean => {
  return !!state?.find((option) => option.value === value);
};

type CreateNestedOptionMarkupProps = {
  childProps: DropdownOptionProps;
  children: ReactElement;
};

export const CreateNestedOptionMarkup = ({
  childProps,
  children,
}: CreateNestedOptionMarkupProps): ReactElement<CreateNestedOptionMarkupProps> => {
  return cloneElement(children, childProps);
};

export const sortASC = (
  a: DropdownOptionProps,
  b: DropdownOptionProps,
): number => {
  return a.name.localeCompare(b.name);
};
