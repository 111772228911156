export type ActionWithPayload<T, P = void> = { type: T; payload: P };

export type Action<T> = { type: T; payload: void };

export function createAction<T extends string, P>(
  type: T,
  payload: P,
): ActionWithPayload<T, P>;

export function createAction<T extends string>(
  type: T,
  payload: void,
): Action<T>;

export function createAction<T extends string, P>(
  type: T,
  payload: P,
): ActionWithPayload<T, P> | Action<T> {
  return { type, payload };
}
