import { useEffect, useState } from 'react';
import { Role, UserType } from 'shared/types/authorization';
import { extractRoleFromUser } from 'utils/authorization.utils';
import { editUser, viewUsers } from 'utils/firebase/cloud-functions';
import {
  GlobalUser,
  UserData,
} from '../../utils/firebase/collection/firebase-collection-types';
import { useCurrentPrincipal } from './useCurrentPrincipal';
import { useCurrentUser } from './useCurrentUser';
import { useRoleDefinitions } from './useRoleDefinitions';

// This is a helper object that encapsulates wraps the firebase [user] document and encapsulates the [global_user] document
export type PortalUser = UserData & {
  global?: GlobalUser;
  userType?: UserType;
};

export type BaseUseUserResult = {
  user: PortalUser;
  role: Role;
  userType: UserType;
  isGlobalUser: boolean;
};

export type UseUserResult = BaseUseUserResult & {
  update: (partial: Partial<PortalUser>) => Promise<void>;
};

// Gets currently logged in user by default
// Provide alternative userId to fetch that user
/**
 * Custom hook to fetch user of provided {userId} and update the user via {update}-method
 * @param userId
 * @returns {BaseUseUserResult} with fetched user object, role of the user in the current principal and if user is a global user
 */
export const useUser = (userId: string): UseUserResult | undefined => {
  const { user: currentUser } = useCurrentUser();
  const { currentPrincipal } = useCurrentPrincipal(currentUser.id);
  const roleDefinitions = useRoleDefinitions();

  const [targetedUser, setTargetedUser] = useState<PortalUser>();
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  useEffect(() => {
    setIsUpdated(false);
    viewUsers({
      currentPrincipal: currentPrincipal.id,
      userIdToView: userId,
    })
      .then(({ data }) => data)
      .then(
        (
          data:
            | { success: boolean; result: UserData[] | UserData }
            | { code: string },
        ) => {
          if ('code' in data || ('success' in data && !data.success)) {
            console.error('Failed fetching users: ', data);
            return;
          }

          if (!Array.isArray(data.result)) {
            setTargetedUser(data.result);
          }
        },
      );
  }, [currentPrincipal, userId, isUpdated]);

  if (!targetedUser) return;

  const isGlobalUser = !!targetedUser.global_user;
  const role =
    extractRoleFromUser(currentPrincipal.id, targetedUser) ?? Role.NONE;
  const userType = roleDefinitions[role]?.user_type;

  const update = async (userChanges: Partial<PortalUser>): Promise<void> => {
    const promises = [];

    promises.push(
      editUser({
        currentPrincipal: currentPrincipal.id,
        userIdToEdit: userId,
        editedUserData: userChanges,
      }),
    );

    await Promise.all(promises);
    setIsUpdated(true);
  };

  return {
    isGlobalUser,
    role,
    update,
    user: targetedUser,
    userType,
  };
};
