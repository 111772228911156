import { useEffect, useState } from 'react';
import { getCRMStatesData } from 'utils/firebase/cloud-functions';

export type CRMStateData = {
  id: string;
  label: string;
};

export const useCRMStatesData = (): CRMStateData[] => {
  const [data, setData] = useState<CRMStateData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCRMStatesData();

        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return data;
};
