import { CountryTile } from 'components/CountryTile';
import { ExpansionOpportunity } from 'components/ExpansionOpportunity';
import { Heading } from 'components/Heading';
import { ExpansionOpportunity as ExpansionOpportunityType } from 'hooks/queries/useExpansionOpportunities';
import React from 'react';
import { useTranslation } from 'react-i18next';

type CountryModalExpansionProps = {
  expansionOpportunities: ExpansionOpportunityType[];
  hasMandates?: boolean;
};

export const Expansion: React.FC<CountryModalExpansionProps> = ({
  expansionOpportunities,
  hasMandates,
}: CountryModalExpansionProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Heading
        level="h3"
        text={t('modals:countryModal:potentialForCollaboration')}
      />
      {expansionOpportunities.map((expOpp, index) =>
        expOpp.aze_portfolio?.aze_name ? (
          <CountryTile
            key={index}
            headline={t(
              `modals:countryModal:${
                hasMandates
                  ? 'consolidationOpportunity'
                  : 'newCountryOpportunity'
              }`,
            )}
          >
            <ExpansionOpportunity
              principalPortfolio={expOpp.aze_portfolio?.aze_name}
              portfolioFit={+(expOpp.portfoliofit ?? 0)}
              salesTeamStrength={+(expOpp.salesteamstrength ?? 0)}
              labSupportResources={+(expOpp.labsupportresources ?? 0)}
              marketSegmentName={expOpp.aze_marketsegmentid?.aze_name}
              border={false}
              noPadding={true}
              maxRationaleLinesToDisplay={4}
            />
          </CountryTile>
        ) : null,
      )}
    </>
  );
};
