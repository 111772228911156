import classNames from 'classnames';
import { Icon } from 'components/Icon';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../Dropdown';
import { DropdownButton } from '../atoms/Button';
import { DropdownItem } from '../atoms/Item';
import { Items } from '../atoms/Items';
import { DropdownOptionProps } from '../helper/DropdownTypes';
import { getAllChildren } from '../helper/helper';

export type DropdownComponentProps = {
  disabled?: boolean;
  placeholder?: string;
  options: DropdownOptionProps[];
  onChange?: (option: DropdownOptionProps) => void;
  allowUnset?: boolean;
  label?: string;
  typeAhead?: boolean;
  error?: boolean;
  errorText?: string;
  initialSelection?: DropdownOptionProps;
  height?: string;
  widthVariant?: 'full-width' | 'content';
};

export const DropdownSingleSelect: React.FC<DropdownComponentProps> = ({
  placeholder,
  disabled,
  label,
  options,
  onChange,
  allowUnset,
  typeAhead,
  error,
  errorText,
  initialSelection,
  height,
  widthVariant = 'full-width',
}) => {
  let activeOption: DropdownOptionProps | undefined;
  if (options && options.length === 1) {
    activeOption = options[0];
  } else if (options) {
    activeOption =
      options.find((option) => option.active === true) || initialSelection;
  }

  const [activeOtionState, setActiveOptionState] = useState<
    string | number | undefined
  >(activeOption?.value);

  const { t } = useTranslation();

  useEffect(() => {
    if (initialSelection) {
      setActiveOptionState(initialSelection?.value);
    }
    if (activeOption) {
      setActiveOptionState(activeOption.value);
      onChange && onChange(activeOption);
    }
    if (!activeOption && !initialSelection) {
      setActiveOptionState(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelection, activeOption]);

  const onClickHandler = useCallback(
    (option: DropdownOptionProps) => {
      if (activeOtionState !== option.value) {
        setActiveOptionState(option.value);
      } else if (allowUnset) {
        setActiveOptionState(undefined);
      } else return;
      onChange && onChange(option);
    },
    [activeOtionState, allowUnset, onChange],
  );

  const ItemMarkup = useCallback(
    (option: DropdownOptionProps) => {
      return (
        <DropdownItem
          value={option.value}
          disabled={option.disabled}
          key={option.value}
          active={option.value === activeOtionState}
          onClick={
            !option.childOptions ? () => onClickHandler(option) : undefined
          }
          name={option.name}
          isParent={!!option.childOptions}
        >
          <span
            className={classNames({
              'pointer-events-none': !!option.childOptions,
            })}
          >
            {option.name || option.value}
          </span>
          {option.value === activeOtionState && (
            <span className="ml-1 h-4 w-4 shrink-0">
              <Icon name="Checkmark" />
            </span>
          )}
          {option.childOptions && (
            <ul>
              {option.childOptions?.map((childOption) => (
                <ItemMarkup {...childOption} key={childOption.value} />
              ))}
            </ul>
          )}
        </DropdownItem>
      );
    },
    [activeOtionState, onClickHandler],
  );

  const activeOptionFinder = getAllChildren(options).find(
    (op) => op.value === activeOtionState,
  );

  return (
    <Dropdown>
      <DropdownButton
        placeholder={placeholder || t('placeholders:dropdownDefault')}
        error={!!error}
        errorText={errorText}
        disabled={disabled}
        label={label}
      >
        <span
          className={classNames('truncate ', {
            'text-grey-700': !error && !disabled && !!activeOptionFinder?.name,
          })}
        >
          {activeOptionFinder?.name ||
            placeholder ||
            t('placeholders:dropdownDefault')}
        </span>
      </DropdownButton>
      <Items typeAhead={typeAhead} height={height} widthVariant={widthVariant}>
        {options.map((option) => (
          <ItemMarkup {...option} key={option.value} />
        ))}
      </Items>
    </Dropdown>
  );
};
