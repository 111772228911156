export enum Role {
  AZELIS_VISITOR = 'azelis_visitor',
  DISTRIBUTION_MANAGER = 'distribution_manager',
  GLOBAL_USER_ADMIN = 'global_user_admin',
  GLOBAL_USER_EDIT = 'global_user_edit',
  GLOBAL_USER_VIEW = 'global_user_view',
  NONE = 'no-role',
  PDM = 'pdm',
  PRINCIPAL_ADMIN = 'principal_admin',
  PRINCIPAL_DEVELOPER = 'principal_developer',
}
