export const countryList = [
  { abb: 'ae', name: 'United Arab Emirates', region: 'EMEA' },
  { abb: 'al', name: 'Albania', region: 'EMEA' },
  { abb: 'am', name: 'Armenia', region: 'EMEA' },
  { abb: 'ao', name: 'Angola', region: 'EMEA' },
  { abb: 'at', name: 'Austria', region: 'EMEA' },
  { abb: 'au', name: 'Australia', region: 'APAC' },
  { abb: 'az', name: 'Azerbaijan', region: 'EMEA' },
  { abb: 'ba', name: 'Bosnia and Herzegovina', region: 'EMEA' },
  { abb: 'be', name: 'Belgium', region: 'EMEA' },
  { abb: 'bf', name: 'Burkina Faso', region: 'EMEA' },
  { abb: 'bg', name: 'Bulgaria', region: 'EMEA' },
  { abb: 'bh', name: 'Bahrain', region: 'EMEA' },
  { abb: 'bi', name: 'Burundi', region: 'EMEA' },
  { abb: 'bj', name: 'Benin', region: 'EMEA' },
  { abb: 'bw', name: 'Botswana', region: 'EMEA' },
  { abb: 'by', name: 'Belarus', region: 'EMEA' },
  { abb: 'ca', name: 'Canada', region: 'AMERICAS' },
  { abb: 'cf', name: 'Central African Republic', region: 'EMEA' },
  { abb: 'cg', name: 'Congo (DRC)', region: 'EMEA' },
  { abb: 'cg', name: 'Congo', region: 'EMEA' },
  { abb: 'ch', name: 'Switzerland', region: 'EMEA' },
  { abb: 'ci', name: 'Ivory Coast', region: 'EMEA' },
  { abb: 'cm', name: 'Cameroon', region: 'EMEA' },
  { abb: 'cn', name: 'China', region: 'APAC' },
  { abb: 'co', name: 'Colombia', region: 'AMERICAS' },
  { abb: 'cv', name: 'Cape Verde', region: 'EMEA' },
  { abb: 'cy', name: 'Cyprus', region: 'EMEA' },
  { abb: 'cz', name: 'Czech Republic', region: 'EMEA' },
  { abb: 'de', name: 'Germany', region: 'EMEA' },
  { abb: 'dj', name: 'Djibouti', region: 'EMEA' },
  { abb: 'dk', name: 'Denmark', region: 'EMEA' },
  { abb: 'dz', name: 'Algeria', region: 'EMEA' },
  { abb: 'ee', name: 'Estonia', region: 'EMEA' },
  { abb: 'eg', name: 'Egypt', region: 'EMEA' },
  { abb: 'er', name: 'Eritrea', region: 'EMEA' },
  { abb: 'es', name: 'Spain', region: 'EMEA' },
  { abb: 'et', name: 'Ethiopia', region: 'EMEA' },
  { abb: 'fi', name: 'Finland', region: 'EMEA' },
  { abb: 'fj', name: 'Fiji', region: 'APAC' },
  { abb: 'fr', name: 'France', region: 'EMEA' },
  { abb: 'ga', name: 'Gabon', region: 'EMEA' },
  { abb: 'gb', name: 'United Kingdom', region: 'EMEA' },
  { abb: 'ge', name: 'Georgia', region: 'EMEA' },
  { abb: 'gh', name: 'Ghana', region: 'EMEA' },
  { abb: 'gm', name: 'Gambia', region: 'EMEA' },
  { abb: 'gn', name: 'Guinea', region: 'EMEA' },
  { abb: 'gq', name: 'Equatorial Guinea', region: 'EMEA' },
  { abb: 'gr', name: 'Greece', region: 'EMEA' },
  { abb: 'gw', name: 'Guinea-Bissau', region: 'EMEA' },
  { abb: 'hk', name: 'Hong Kong', region: 'APAC' },
  { abb: 'hr', name: 'Croatia', region: 'EMEA' },
  { abb: 'hu', name: 'Hungary', region: 'EMEA' },
  { abb: 'id', name: 'Indonesia', region: 'APAC' },
  { abb: 'ie', name: 'Ireland', region: 'EMEA' },
  { abb: 'il', name: 'Israel', region: 'EMEA' },
  { abb: 'in', name: 'India', region: 'APAC' },
  { abb: 'iq', name: 'Iraq', region: 'EMEA' },
  { abb: 'is', name: 'Iceland', region: 'EMEA' },
  { abb: 'it', name: 'Italy', region: 'EMEA' },
  { abb: 'jo', name: 'Jordan', region: 'EMEA' },
  { abb: 'jp', name: 'Japan', region: 'APAC' },
  { abb: 'ke', name: 'Kenya', region: 'EMEA' },
  { abb: 'kg', name: 'Kyrgyzstan', region: 'EMEA' },
  { abb: 'kh', name: 'Cambodia', region: 'APAC' },
  { abb: 'km', name: 'Comoros', region: 'EMEA' },
  { abb: 'kr', name: 'South Korea', region: 'APAC' },
  { abb: 'kw', name: 'Kuwait', region: 'EMEA' },
  { abb: 'kz', name: 'Kazakhstan', region: 'EMEA' },
  { abb: 'la', name: 'Laos', region: 'APAC' },
  { abb: 'lb', name: 'Lebanon', region: 'EMEA' },
  { abb: 'li', name: 'Liechtenstein', region: 'EMEA' },
  { abb: 'lr', name: 'Liberia', region: 'EMEA' },
  { abb: 'ls', name: 'Lesotho', region: 'EMEA' },
  { abb: 'lt', name: 'Lithuania', region: 'EMEA' },
  { abb: 'lu', name: 'Luxembourg', region: 'EMEA' },
  { abb: 'lv', name: 'Latvia', region: 'EMEA' },
  { abb: 'ly', name: 'Libya', region: 'EMEA' },
  { abb: 'ma', name: 'Morocco', region: 'EMEA' },
  { abb: 'md', name: 'Moldova', region: 'EMEA' },
  { abb: 'me', name: 'Montenegro', region: 'EMEA' },
  { abb: 'mg', name: 'Madagascar', region: 'EMEA' },
  { abb: 'mk', name: 'Macedonia', region: 'EMEA' },
  { abb: 'ml', name: 'Mali', region: 'EMEA' },
  { abb: 'mr', name: 'Mauritania', region: 'EMEA' },
  { abb: 'mt', name: 'Malta', region: 'EMEA' },
  { abb: 'mu', name: 'Mauritius', region: 'EMEA' },
  { abb: 'mw', name: 'Malawi', region: 'EMEA' },
  { abb: 'mx', name: 'Mexico', region: 'LATAM' },
  { abb: 'my', name: 'Malaysia', region: 'APAC' },
  { abb: 'mz', name: 'Mozambique', region: 'EMEA' },
  { abb: 'na', name: 'Namibia', region: 'EMEA' },
  { abb: 'ne', name: 'Niger', region: 'EMEA' },
  { abb: 'ng', name: 'Nigeria', region: 'EMEA' },
  { abb: 'nl', name: 'Netherlands', region: 'EMEA' },
  { abb: 'no', name: 'Norway', region: 'EMEA' },
  { abb: 'nz', name: 'New Zealand', region: 'APAC' },
  { abb: 'om', name: 'Oman', region: 'EMEA' },
  { abb: 'pg', name: 'Papua New Guinea', region: 'APAC' },
  { abb: 'ph', name: 'Philippines', region: 'APAC' },
  { abb: 'pk', name: 'Pakistan', region: 'APAC' },
  { abb: 'pl', name: 'Poland', region: 'EMEA' },
  { abb: 'ps', name: 'Palestinian Authority', region: 'EMEA' },
  { abb: 'pt', name: 'Portugal', region: 'EMEA' },
  { abb: 'qa', name: 'Qatar', region: 'EMEA' },
  { abb: 're', name: 'Reunion', region: 'EMEA' },
  { abb: 'ro', name: 'Romania', region: 'EMEA' },
  { abb: 'rs', name: 'Serbia', region: 'EMEA' },
  { abb: 'ru', name: 'Russia', region: 'EMEA' },
  { abb: 'rw', name: 'Rwanda', region: 'EMEA' },
  { abb: 'sa', name: 'Saudi Arabia', region: 'EMEA' },
  { abb: 'se', name: 'Sweden', region: 'EMEA' },
  { abb: 'sg', name: 'Singapore', region: 'APAC' },
  { abb: 'si', name: 'Slovenia', region: 'EMEA' },
  { abb: 'sk', name: 'Slovakia', region: 'EMEA' },
  { abb: 'sl', name: 'Sierra Leone', region: 'EMEA' },
  { abb: 'sn', name: 'Senegal', region: 'EMEA' },
  { abb: 'so', name: 'Somalia', region: 'EMEA' },
  { abb: 'sz', name: 'Swaziland', region: 'EMEA' },
  { abb: 'td', name: 'Chad', region: 'EMEA' },
  { abb: 'tg', name: 'Togo', region: 'EMEA' },
  { abb: 'th', name: 'Thailand', region: 'APAC' },
  { abb: 'tj', name: 'Tajikistan', region: 'EMEA' },
  { abb: 'tm', name: 'Turkmenistan', region: 'EMEA' },
  { abb: 'tn', name: 'Tunisia', region: 'EMEA' },
  { abb: 'tr', name: 'Turkey', region: 'EMEA' },
  { abb: 'tz', name: 'Tanzania', region: 'EMEA' },
  { abb: 'ua', name: 'Ukraine', region: 'EMEA' },
  { abb: 'ug', name: 'Uganda', region: 'EMEA' },
  { abb: 'us', name: 'United States', region: 'AMERICAS' },
  { abb: 'uz', name: 'Uzbekistan', region: 'EMEA' },
  { abb: 'vn', name: 'Vietnam', region: 'APAC' },
  { abb: 'ye', name: 'Yemen', region: 'EMEA' },
  { abb: 'za', name: 'South Africa', region: 'EMEA' },
  { abb: 'zm', name: 'Zambia', region: 'EMEA' },
  { abb: 'zw', name: 'Zimbabwe', region: 'EMEA' },
];
