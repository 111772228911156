import React from 'react';

import { useTranslation } from 'react-i18next';
import { Heading } from '../Heading';

export const Error404: React.FC = () => {
  const [t] = useTranslation();
  return (
    <div className="mx-5 flex h-full flex-col justify-center text-center">
      <Heading text={t('components:error404:heading')} align="center" />
      <p>{t('components:error404:body')}</p>
    </div>
  );
};
