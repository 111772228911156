import { Reducer } from '@reduxjs/toolkit';
import { SharepointActionType, SharepointState } from './sharepoint.types';
import { SharepointAction } from './sharepoints.action';

const INITIAL_STATE: SharepointState = {
  updateResult: {
    updates: 0,
    sharePointFolder: '',
    updatedFiles: [],
    filesInAllSubTrees: 0,
  },
};

export const sharepointReducer: Reducer<SharepointState, SharepointAction> = (
  state = INITIAL_STATE,
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SharepointActionType.RESET_ALL:
      return { ...INITIAL_STATE };
    case SharepointActionType.SET_SHAREPOINT_STATE_ACTION:
      return { ...state, lastUpdated: Date.now(), updateResult: payload };
    default:
      return state;
  }
};
