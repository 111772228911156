declare global {
  interface Window {
    dataLayer: Array<unknown>;
  }
}

type TrackingSchema =
  | 'content'
  | 'file'
  | 'interaction'
  | 'insight'
  | 'site'
  | 'user';

type SchemaContent = 'category' | 'type' | 'content';
type SchemaInteraction = 'group' | 'name' | 'label' | 'context';
type SchemaInsight = 'link' | 'category' | 'marketSegment';
type SchemaUser = 'loginStatus' | 'type' | 'principalID';
type SchemaSite = 'environment' | 'viewport' | 'language';

type TrackingData =
  | Partial<Record<SchemaContent, unknown>>
  | Partial<Record<SchemaInteraction, unknown>>
  | Partial<Record<SchemaInsight, unknown>>
  | Partial<Record<SchemaUser, unknown>>
  | Partial<Record<SchemaSite, unknown>>;

class Tracking {
  constructor() {
    window.dataLayer = window.dataLayer || [];
  }
  push(schema: TrackingSchema, event: string, data: TrackingData) {
    window.dataLayer.push({ [schema]: null });
    window.dataLayer.push({
      event,
      schema,
      [schema]: { ...data },
    });
  }
}

export const tracking = new Tracking();
