import { SharePointUpdateResult } from 'lib/sharepoint';

export type SharepointState = {
  updateResult: SharePointUpdateResult;
  lastUpdated?: number;
};

export enum SharepointActionType {
  RESET_ALL = 'sharepoint/RESET_ALL',
  SET_SHAREPOINT_STATE_ACTION = 'sharepoint/SET_SHAREPOINT_STATE_ACTION',
}
