import React, { ReactNode } from 'react';

type TableHeaderProps = {
  children: ReactNode;
};

export const TableHeader: React.FC<TableHeaderProps> = ({
  children,
}: TableHeaderProps) => {
  return <thead className="font-semibold">{children}</thead>;
};
