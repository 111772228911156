import { Alert } from 'components/Alert';
import { Modal } from 'components/Modal';
import { routes } from 'controllers/ContentController/Routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { deleteUsers } from 'utils/firebase/cloud-functions';
import { Principal } from 'utils/firebase/collection/firebase-collection-types';
import { Notification } from '../../lib/notifications/notifications';

type UserDeletionResults = {
  deleted: string[];
  notDelete: string[];
};

type DeleteUsersModalProps = {
  onCancel: () => void;
  usersToDelete: { id: string; name?: string }[];
  selectedPrincipal: Principal;
  onComplete?: () => void;
};

export const DeleteUsersModal: React.FC<DeleteUsersModalProps> = ({
  onCancel,
  usersToDelete,
  selectedPrincipal,
  onComplete,
}) => {
  const { t } = useTranslation();
  const [deletedUserNames, setDeletedUserNames] = useState<string[]>([]);
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  const isMulti = usersToDelete.length > 1;
  const userNameOrNumber = isMulti
    ? usersToDelete.length
    : usersToDelete[0].name;
  const multiOrSingle = isMulti ? 'multi' : 'single';

  const deleteDisplayedUser = () => {
    if (!usersToDelete.length || !selectedPrincipal) return;

    setDisableConfirmButton(true);

    deleteUsers({
      userIdsToDelete: usersToDelete.map(({ id }) => id),
      currentPrincipalId: selectedPrincipal.id,
    })
      .then(({ data }) => data)
      .then(
        (
          data:
            | { success: boolean; result: UserDeletionResults }
            | { code: string },
        ) => {
          setDisableConfirmButton(false);
          if ('code' in data || ('success' in data && !data.success)) {
            Notification({
              message: t('modals:deleteUsersModal:error:system'),
            });
            return;
          }

          if (data.result.notDelete.length > 0) {
            Notification({
              message: t('modals:deleteUsersModal:error:notOnThisPrincipal', {
                undeletedUser: data.result.notDelete[0],
              }),
            });
          }

          setDeletedUserNames(data.result.deleted);
          if (onComplete) {
            onComplete();
          }
        },
      );
  };

  return (
    <>
      {!!deletedUserNames.length && (
        <Navigate
          to={routes(selectedPrincipal.slug).portalMembersRoute.url}
          state={{ deletedUserNames }}
        />
      )}
      <Modal
        heading={t(`modals:deleteUsersModal:title:${multiOrSingle}`)}
        confirmText={t(`modals:deleteUsersModal:confirm:${multiOrSingle}`)}
        onConfirm={deleteDisplayedUser}
        onCancel={onCancel}
        isConfirmButtonDisabled={disableConfirmButton}
        headingAlign="left"
        isBig
      >
        <div className="pt-1 text-left">
          <p className="mb-4">
            {t(`modals:deleteUsersModal:description:${multiOrSingle}`, {
              userNameOrNumber,
              selectedPrincipalName: selectedPrincipal.name,
            })}
          </p>
          <Alert
            message={t('modals:deleteUsersModal:info')}
            flavour="warning"
            icon="InfoCircle"
          />
          {isMulti && (
            <>
              <p className="mb-2 mt-4">
                {t('modals:deleteUsersModal:userCount', {
                  userNameOrNumber,
                })}
              </p>
              <ul className="list-disc pl-4">
                {usersToDelete.map(({ name, id }) => (
                  <li key={id}>{name}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};
