import React from 'react';
import './LoadingDots.css';

export const LoadingDots: React.FC = () => {
  return (
    <div className="flex flex-col items-baseline justify-end">
      <div className="dot-flashing" data-testid="dot-flashing" />
    </div>
  );
};
