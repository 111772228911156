import { useOnClickOutside } from 'hooks/onClickOutside';
import React, { createContext, useContext, useRef, useState } from 'react';
import { logger } from 'shared/Logger';
import { DropdownProps } from './helper/DropdownTypes';

type ContextProps = {
  open: boolean;
  toggleOpen: () => void;
  typeAheadValue: string;
  setOpen: (a: boolean) => void;
  setTypeAheadValue: (a: string) => void;
};

export function useDropdownContext(): ContextProps {
  const context = useContext(DropdownContextOpen);
  if (!context) {
    throw new Error(
      'All Dropdown components have to be used inside the <Dropdown>. Please do not use them outside the Dropdown',
    );
  }
  return context;
}

const DropdownContextOpen = createContext({
  open: false,
  toggleOpen: () => {
    // Empty function
  },
  typeAheadValue: '',
  setOpen: (a: boolean) => logger(a),
  setTypeAheadValue: (a: string) => logger(a),
});

export const Dropdown: React.FC<DropdownProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, () => setOpen(false));
  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const [typeAheadValue, setTypeAheadValue] = useState('');

  return (
    <DropdownContextOpen.Provider
      value={{ open, toggleOpen, typeAheadValue, setTypeAheadValue, setOpen }}
    >
      <div ref={menuRef} className="relative">
        {children}
      </div>
    </DropdownContextOpen.Provider>
  );
};
