import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  CRMMandatesPayload,
  getCRMMandates,
  MappedMandates,
} from 'utils/firebase/cloud-functions';
import { MarketData } from '.';
import { Mandate as CRMMandate } from '../../../../functions/src/types/crm';

export type Mandate = CRMMandate & MarketData;

export const useCRMMandates = ({
  enabled = true,
  filter,
  principalPri,
}: CRMMandatesPayload & { enabled?: boolean }): UseQueryResult<
  MappedMandates,
  Error
> => {
  return useQuery({
    queryFn: async () => await getCRMMandates({ filter, principalPri }),
    queryKey: [
      'mandates',
      principalPri,
      {
        hasAllMandates: filter?.hasAllMandates,
        iso: filter?.iso,
        marketSegment: filter?.marketSegment,
        portfolio: filter?.portfolio,
      },
    ],
    select: ({ data }) => data,
    enabled,
    refetchOnWindowFocus: false,
  });
};
