import { Principals } from 'hooks/queries';
import { ActionWithPayload, createAction } from 'utils/reducer.utils';
import { PrincipalsActionType } from './principals.types';

export type PrincipalsAction = SetPrincipalsDataAction;

type SetPrincipalsDataAction = ActionWithPayload<
  PrincipalsActionType.SET_PRINCIPALS_DATA,
  Principals | undefined
>;

export const setPrincipalsData = (
  payload: Principals | undefined,
): SetPrincipalsDataAction =>
  createAction(PrincipalsActionType.SET_PRINCIPALS_DATA, payload);
