import classNames from 'classnames';
import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from '../../controllers/ContentController/Routes';
import { tracking } from '../../lib/tracking';

export type SiteFooterProps = {
  leftAligned?: boolean;
};

export const SiteFooter: React.FC<SiteFooterProps> = ({ leftAligned }) => {
  const { t } = useTranslation();

  const { cookieStatementRoute, termsOfUseRoute } = routes();

  const linkToPrivacyStatement = (event: MouseEvent) => {
    event.preventDefault();

    tracking.push('content', 'Content Click', {
      category: 'Legal',
      type: 'Info',
      content: 'Privacy Statement',
    });

    window.open(
      'https://www.azelis.com/en/privacy-statement',
      '_blank',
      'noopener  noreferrer',
    );
  };

  return (
    <footer
      className={
        'text-grey-700 text-2xs sticky flex w-full shrink-0 flex-col pr-0 xl:flex-row ' +
        (!leftAligned ? 'p-4 md:pl-7' : 'p-4 pl-0')
      }
    >
      <div className="flex flex-col lg:flex-row">
        <p
          className={classNames(
            'border-grey-500 my-2 md:my-3 lg:mr-3 lg:border-r lg:pr-3 xl:my-0 ',
            leftAligned ? 'xl:mr-5 xl:pr-5' : 'xl:mx-5 xl:px-5',
          )}
        >
          {t('components:footer:copyright', { year: new Date().getFullYear() })}
        </p>
        <nav className="flex flex-col items-center max-[1280px]:flex-wrap md:flex-row">
          <span className="my-2 flex shrink-0 grow-0 pr-3 md:my-0 lg:my-2 xl:my-0">
            <a
              href={cookieStatementRoute.url}
              className="font-medium hover:text-blue-100 focus:text-blue-100"
              rel="noopener noreferrer"
            >
              {t('components:footer:links:cookie')}
            </a>
          </span>
          <span className="my-2 flex shrink-0 grow-0 pr-3 md:my-0 lg:my-2 xl:my-0">
            <button
              type="button"
              onClick={linkToPrivacyStatement}
              className="font-medium hover:text-blue-100 focus:text-blue-100"
            >
              {t('components:footer:links:privacy')}
            </button>
          </span>
          <span className="my-2 flex shrink-0 grow-0 pr-3 md:my-0 lg:my-3 xl:my-0">
            <a
              href={termsOfUseRoute.url}
              className="font-medium hover:text-blue-100 focus:text-blue-100"
              rel="noopener noreferrer"
            >
              {t('components:footer:links:terms')}
            </a>
          </span>
        </nav>
      </div>
    </footer>
  );
};
