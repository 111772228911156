import classNames from 'classnames';
import { Input } from 'components/Input';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropdownContext } from '../Dropdown';
import styles from '../helper/dropdown.module.css';

export type DropdownItemsProps = {
  typeAhead?: boolean;
  height?: string;
  children?: React.ReactNode;
  widthVariant?: 'full-width' | 'content';
};
export const Items: React.FC<DropdownItemsProps> = ({
  children,
  typeAhead,
  height,
  widthVariant = 'content',
}) => {
  const { open, setTypeAheadValue } = useDropdownContext();
  const { t } = useTranslation();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isLeftAligned, setLeftAligned] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      const parentElement = dropdownRef.current?.parentElement;

      if (parentElement) {
        const { x } = parentElement.getBoundingClientRect();
        //if position of dropdown is on the left side, overflow to the right and vice versa
        if (x < window.innerWidth / 2) {
          setLeftAligned(true);
        } else {
          setLeftAligned(false);
        }
      }
    }
  }, [open]);

  return (
    <div
      ref={dropdownRef}
      data-testid="dropdown-items"
      className={classNames(
        { 'w-max min-w-[288px]': widthVariant === 'content' },
        { 'w-full': widthVariant !== 'content' },
        styles.items,
        {
          hidden: !open,
        },
        height ? `max-h-${height}` : 'max-h-50vh',
        { 'left-0': isLeftAligned },
        { 'right-0': !isLeftAligned },
        'z-100 absolute top-[3.15rem] overflow-y-auto rounded-b border border-blue-100 bg-white',
      )}
    >
      <div className="overflow-y-auto">
        {typeAhead && (
          <div data-testid="typeahead-input" className="p-2 pt-4">
            <Input
              placeholder={t('placeholders:typeToFilter')}
              autofocus={true}
              type="text"
              iconPosition="right"
              icon="Search"
              onChange={(val) => setTypeAheadValue(val.target.value)}
            />
          </div>
        )}
        <ul>{children}</ul>
      </div>
    </div>
  );
};
