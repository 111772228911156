import classNames from 'classnames';
import React from 'react';

export type CountProps = {
  number: number;
  color?: string;
};

export const Count: React.FC<CountProps> = ({ number, color }) => {
  return (
    <span
      className={classNames(
        'text-grey-700 bg-grey-200 inline-block h-full w-full rounded-full text-center text-xs font-semibold leading-4',
        color,
      )}
    >
      {number}
    </span>
  );
};
export default Count;
