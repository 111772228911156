import React from 'react';

export type StepsProps = {
  total: number;
  selected: string | number;
};

export const Steps: React.FC<StepsProps> = ({
  total,
  selected,
}: StepsProps) => {
  const selectedNumber: number = +selected;
  return (
    <nav className="flex items-start justify-start" aria-label="Progress">
      <ol className="flex items-center space-x-1">
        {[...Array(total)].map((step, index) => (
          <li key={index}>
            {index + 1 <= selectedNumber ? (
              <span className="block h-1 w-1 rounded-full bg-green-300 hover:bg-green-900" />
            ) : (
              <span className="bg-grey-300 hover:bg-grey-400 block h-1 w-1 rounded-full" />
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
