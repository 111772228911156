import { IconName } from 'components/Icon';
import { DataConnection, EDIInformation } from 'features/DataConnection';
import { DigitalActivities } from 'features/DigitalActivities/DigitalActivities';
import { GlobalUsers } from 'features/GlobalUsers';
import { OperationalPerformance } from 'features/OperationalPerformance';
import { MemberDetails } from 'features/PortalMembers/MemberDetails';
import { PortalMembers } from 'features/PortalMembers/PortalMembers';
import TermsOfUse from 'features/TermsOfUse/TermsOfUse';
import { compact } from 'lodash';
import React from 'react';
import { Permission } from 'shared/types/authorization';
import { Account } from '../../features/Account';
import CookieStatement from '../../features/CookieStatement/CookieStatement';
import { Dashboard } from '../../features/Dashboard';
import { MarketIntelligence } from '../../features/MarketIntelligence';
import { SharedDocuments } from '../../features/SharedDocuments';

export type MainMenuConfig = {
  section: 'primary' | 'secondary';
  order: number;
  title: string;
  icon: IconName;
};

export type InternalRoute = {
  url: string;
  name: string;
  Component: React.FC;
  authRequired: boolean;
  permissions?: string[];
  mainMenuConfig?: MainMenuConfig;
  additionalInformation: {
    trackingCategory: 'Partner Area' | 'User Area' | 'Legal';
    trackingType:
      | 'Overview'
      | 'Report'
      | 'List'
      | 'Account Info'
      | 'Info'
      | 'Cookie Statement'
      | 'Portal Members'
      | 'Global Users'
      | 'Operational Performance'
      | 'Customer portal'
      | 'Data Connections'
      | 'Terms Of Use'
      | 'EDI Information';
  };
};

// This determines whether a route item should be in the main menu
// It also guarrantees to Typescript that these items WILL have mainMenuConfigs and permissions
export const hasMainMenuConfigAndPermissions = (
  i: InternalRoute,
): i is InternalRoute & {
  mainMenuConfig: MainMenuConfig;
  permissions: Permission[];
} => !!i.mainMenuConfig && !!i.permissions;

export const encodePrincipalIdToUrl = (principalId?: string): string =>
  encodeURI(principalId?.toLowerCase() || '');

//selectedPrincipal can be undefined e.g. for cookie-statement route
export const routes = (
  selectedPrincipal?: string,
): { [key: string]: InternalRoute } => {
  const selectedPrincipalUrl = selectedPrincipal ? '/' + selectedPrincipal : '';

  return {
    dashboardRoute: {
      url: `${selectedPrincipalUrl}/`,
      name: 'Dashboard',
      Component: Dashboard,
      authRequired: true,
      permissions: [Permission.DASHBOARD_VIEW],
      mainMenuConfig: {
        section: 'primary',
        order: 1,
        title: 'dashboard',
        icon: 'Home',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Overview',
      },
    },
    marketIntelligenceRoute: {
      url: `${selectedPrincipalUrl}/market-intelligence`,
      name: 'Market Intelligence',
      Component: MarketIntelligence,
      authRequired: true,
      permissions: [
        Permission.MARKET_INSIGHTS_VIEW,
        Permission.EXPANSION_OPPORTUNITIES_VIEW,
      ],
      mainMenuConfig: {
        section: 'primary',
        order: 5,
        title: 'market-intelligence',
        icon: 'Market',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Report',
      },
    },
    marketIntelligenceTabRoute: {
      url: `${selectedPrincipalUrl}/market-intelligence/:tab`,
      name: 'Market Intelligence',
      Component: MarketIntelligence,
      authRequired: true,
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Report',
      },
    },
    sharedDocumentsRoute: {
      url: `${selectedPrincipalUrl}/shared-documents`,
      name: 'Shared Documents',
      Component: SharedDocuments,
      authRequired: true,
      permissions: [Permission.SHARED_DOCUMENTS_VIEW],
      mainMenuConfig: {
        section: 'primary',
        order: 6,
        title: 'shared-documents',
        icon: 'File',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'List',
      },
    },
    accountRoute: {
      url: `${selectedPrincipalUrl}/account`,
      name: 'User Overview',
      Component: Account,
      authRequired: true,
      permissions: [],
      mainMenuConfig: {
        section: 'secondary',
        order: 3,
        title: 'account',
        icon: 'Account',
      },
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Account Info',
      },
    },
    cookieStatementRoute: {
      url: `${selectedPrincipalUrl}/cookie-statement`,
      name: 'Cookie Statement',
      Component: CookieStatement,
      authRequired: false,
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Cookie Statement',
      },
    },
    termsOfUseRoute: {
      url: `${selectedPrincipalUrl}/terms-of-use`,
      name: 'Terms Of Use',
      Component: TermsOfUse,
      authRequired: false,
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Terms Of Use',
      },
    },
    globalUsersRoute: {
      url: `/global-users`,
      name: 'Global Users',
      Component: GlobalUsers,
      authRequired: true,
      permissions: [Permission.EDIT_GLOBAL_USERS],
      mainMenuConfig: {
        section: 'secondary',
        order: 1,
        title: 'global-users',
        icon: 'GlobalUser',
      },
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Portal Members',
      },
    },
    portalMembersRoute: {
      url: `${selectedPrincipalUrl}/portal-members`,
      name: 'Portal Members',
      Component: PortalMembers,
      authRequired: true,
      permissions: [
        Permission.USER_ROLE_MGMT_EDIT,
        Permission.USER_ROLE_MGMT_VIEW,
      ],
      mainMenuConfig: {
        section: 'secondary',
        order: 5,
        title: 'portal-members',
        icon: 'Settings',
      },
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Portal Members',
      },
    },
    operationalPerformanceRoute: {
      url: `${selectedPrincipalUrl}/performance`,
      name: 'Performance',
      Component: OperationalPerformance,
      authRequired: true,
      permissions: [Permission.OPERATIONAL_PERFORMANCE_VIEW],
      mainMenuConfig: {
        section: 'primary',
        order: 2,
        title: 'performance',
        icon: 'Performance',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Operational Performance',
      },
    },
    operationalPerformanceRouteTabRoute: {
      url: `${selectedPrincipalUrl}/performance/:tab`,
      name: 'Performance',
      Component: OperationalPerformance,
      authRequired: true,
      permissions: [Permission.OPERATIONAL_PERFORMANCE_VIEW],
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Operational Performance',
      },
    },
    memberDetailsRoute: {
      url: `${selectedPrincipalUrl}/portal-members/member/:uid`,
      name: 'Member details',
      Component: MemberDetails,
      authRequired: true,
      permissions: [
        Permission.USER_ROLE_MGMT_EDIT,
        Permission.USER_ROLE_MGMT_VIEW,
      ],
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Portal Members',
      },
    },
    digitalActivitiesRoute: {
      url: `${selectedPrincipalUrl}/digital-activities`,
      name: 'Digital Activities',
      Component: DigitalActivities,
      authRequired: true,
      permissions: [
        Permission.CUSTOMER_PORTAL_INSIGHTS_VIEW,
        Permission.SUPPLIER_PAGES_VIEW,
      ],
      mainMenuConfig: {
        section: 'primary',
        order: 4,
        title: 'digital-activities',
        icon: 'Promotion',
      },
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Report',
      },
    },
    digitalActivitiesTabRoute: {
      url: `${selectedPrincipalUrl}/digital-activities/:tab`,
      name: 'Digital Activities',
      Component: DigitalActivities,
      authRequired: true,
      permissions: [Permission.SHARED_DOCUMENTS_VIEW],
      additionalInformation: {
        trackingCategory: 'Partner Area',
        trackingType: 'Report',
      },
    },
    dataConnectionRoute: {
      url: `${selectedPrincipalUrl}/data-connections`,
      name: 'Data Connections',
      Component: DataConnection,
      authRequired: true,
      permissions: [Permission.DASHBOARD_VIEW],
      mainMenuConfig: {
        section: 'primary',
        order: 3,
        title: 'data-connections',
        icon: 'DataConnection',
      },
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'Data Connections',
      },
    },
    ediInformationRoute: {
      url: `${selectedPrincipalUrl}/data-connections/edi-information`,
      name: 'EDI Information',
      Component: EDIInformation,
      authRequired: true,
      permissions: [Permission.DASHBOARD_VIEW],
      additionalInformation: {
        trackingCategory: 'User Area',
        trackingType: 'EDI Information',
      },
    },
  };
};

//All routes which should work without the url principal prefix
const testPattern = '_t1e2s3t4_';
export const ROUTES_WITHOUT_PRINCIPAL_PREFIX = compact(
  Object.values(routes(testPattern)).map(
    (route) => !route.url.includes(testPattern) && route.url,
  ),
);

export const findRouteDefintionByPathname = (
  pathname: string,
  selectedPrincipal?: string,
): InternalRoute => {
  const routesValues = Object.values(routes(selectedPrincipal));

  for (const route of routesValues) {
    if (route.url === pathname) {
      return route;
    }
  }
  // return Dashboard as default Route
  return routes(selectedPrincipal).dashboardRoute;
};
