import { LoadingBounce } from 'components/Loading';
import { isSafari } from 'lib/utils';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageError } from '../../components/PageError';
import styles from './SupplierPagesTab.module.css';

type SupplierPagesTabProps = {
  link?: string;
};

export const SupplierPagesTab: React.FC<SupplierPagesTabProps> = ({
  link,
}: SupplierPagesTabProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const handleIframe = () => setIsLoading(false);
  const { t } = useTranslation();
  if (isSafari())
    return (
      <PageError
        intro={t('features:digital-activities:supplierPages:insight')}
        iconName="ErrorPage"
        subheading={t('features:digital-activities:supplierPages:subheading')}
        text={t('features:digital-activities:supplierPages:paragraph')}
      />
    );

  return (
    <div className="relative overflow-hidden">
      <p className="mb-4">
        {t('features:digital-activities:supplierPages:insight')}
      </p>
      {isLoading ? (
        <div className="grid h-[60vh] place-items-center">
          <LoadingBounce />
        </div>
      ) : null}
      {link !== undefined ? (
        <iframe
          title="Customer Supplier page"
          width="100%"
          className={styles.iframe}
          allowFullScreen
          src={link ?? link}
          onLoad={handleIframe}
        />
      ) : (
        <div className="grid h-[60vh] place-items-center">
          <LoadingBounce />
        </div>
      )}
    </div>
  );
};
