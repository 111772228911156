import { Icon, IconName } from 'components/Icon';
import React from 'react';

export type PageErrorProps = {
  intro: string;
  iconName: IconName;
  subheading: string;
  text: string;
};
export const PageError: React.FC<PageErrorProps> = ({
  intro,
  iconName,
  subheading,
  text,
}) => {
  return (
    <>
      <p className="mb-4">{intro}</p>
      <div className="border-grey-300 border p-6 text-center">
        <div className="lg:h-130px h-8">
          <Icon name={iconName} />
        </div>
        <p className="text-grey-700 xs:text-sm mt-3 font-semibold lg:text-base">
          {subheading}
        </p>
        <p className="text-grey-600 xs:text-sm mx-auto my-3 lg:w-1/2 lg:text-base">
          {text}
        </p>
      </div>
    </>
  );
};
