import React from 'react';

import { CountryList } from 'components/CountryList';
import { CountryListItem } from 'components/CountryList/CountryList';
import { CountryLocation } from 'components/CountryLocation';
import { CountryTile } from 'components/CountryTile';
import { Heading } from 'components/Heading';
import { MarketSegmentDefinitions } from 'hooks/queries/useMarketSegmentDefinitions';
import { useTranslation } from 'react-i18next';
import { MarketSegmentIdLab } from './types';

type CountryModalMarketingProps = {
  countryName: string | undefined;
  staffCount?: number;
  offices: string[];
  marketSegments: MarketSegmentIdLab[];
  marketSegmentDefinitions: MarketSegmentDefinitions;
  marketSegmentsWithLabs?: MarketSegmentIdLab[];
};

const convertMarketSegmentsToCountryListItems = (
  marketSegments: MarketSegmentIdLab[],
  marketSegmentDefinitions: MarketSegmentDefinitions,
): CountryListItem[] =>
  marketSegments
    .map(({ code, name }) => {
      const def = marketSegmentDefinitions[code];
      if (def) {
        return {
          displayText: name,
          icon: def.icon,
        };
      }
      return false;
    })
    .filter(Boolean) as CountryListItem[];

export const Marketing: React.FC<CountryModalMarketingProps> = ({
  marketSegmentDefinitions,
  countryName,
  staffCount,
  offices,
  marketSegments,
  marketSegmentsWithLabs,
}: CountryModalMarketingProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        level="h3"
        text={t('modals:countryModal:localCapabilitiesInCountry', {
          countryName,
        })}
      />
      {!!staffCount && (
        <CountryTile
          number={staffCount}
          headline={t('modals:countryModal:numberOfStaff')}
        />
      )}
      {!!offices.length && (
        <CountryTile
          headline={t('modals:countryModal:numberOfOffices')}
          number={offices.length}
        >
          <CountryLocation items={offices} initialDisplayLimit={3} />
        </CountryTile>
      )}
      {!!marketSegmentsWithLabs?.length && (
        <CountryTile
          headline={t('modals:countryModal:numberOfInnovationLabs')}
          number={marketSegmentsWithLabs.length}
        >
          <CountryList
            initialDisplayLimit={4}
            items={convertMarketSegmentsToCountryListItems(
              marketSegmentsWithLabs,
              marketSegmentDefinitions,
            )}
          />
        </CountryTile>
      )}
      {!!marketSegments.length && (
        <CountryTile
          headline={t('modals:countryModal:availableMarketSegments', {
            countryName,
          })}
        >
          <CountryList
            initialDisplayLimit={4}
            items={convertMarketSegmentsToCountryListItems(
              marketSegments,
              marketSegmentDefinitions,
            )}
          />
        </CountryTile>
      )}
    </>
  );
};
